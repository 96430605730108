export default [
    { naziv: 'Vrtec Zelena jama, Enota Zmajček', naslov: 'Ulica Vide Pregarčeve 26', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Škratek Svit Vodice, enota Skaručna', naslov: 'Skaručna 6', posta: '1217', kraj: 'Vodice' },
    { naziv: 'II. gimnazija Maribor', naslov: 'Trg Miloša Zidanška 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Nova Gorica', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola Glazija', naslov: 'Oblakova ulica 15', posta: '3000', kraj: 'Celje' },
    { naziv: 'Glasbena šola Celje', naslov: 'Slomškov trg 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Dobrna', naslov: 'Dobrna 1', posta: '3204', kraj: 'Dobrna' },
    { naziv: 'Osnovna šola Vojnik', naslov: 'Prušnikova ulica 14', posta: '3212', kraj: 'Vojnik' },
    { naziv: 'Osnovna šola Antona Bezenška Frankolovo', naslov: 'Frankolovo 11', posta: '3213', kraj: 'Frankolovo' },
    { naziv: 'Osnovna šola Štore', naslov: 'Ulica Cvetke Jerin 5', posta: '3220', kraj: 'Štore' },
    { naziv: 'Osnovna šola Notranjski odred Cerknica', naslov: 'Cesta 4. maja 92', posta: '1380', kraj: 'Cerknica' },
    { naziv: 'Osnovna šola Jožeta Krajca Rakek', naslov: 'Partizanska cesta 28', posta: '1381', kraj: 'Rakek' },
    { naziv: 'Osnovna šola Toneta Šraja Aljoše', naslov: 'Nova vas 4 B', posta: '1385', kraj: 'Nova Vas' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu', naslov: 'Cesta Notranjskega odreda 32', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Osnovna šola Loka Črnomelj', naslov: 'Kidričeva ulica 18', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Osnovna šola Milke Šobar - Nataše Črnomelj', naslov: 'Kurirska steza 8', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Glasbena šola Črnomelj', naslov: 'Ulica Mirana Jarca 2', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Osnovna šola Stari trg ob Kolpi', naslov: 'Stari trg ob Kolpi 7', posta: '8342', kraj: 'Stari trg ob Kolpi' },
    { naziv: 'Osnovna šola Komandanta Staneta Dragatuš', naslov: 'Dragatuš 48', posta: '8343', kraj: 'Dragatuš' },
    { naziv: 'Vrtec Semedela, enota Markovec', naslov: 'Pot v gaj 1', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Semedela, enota Prisoje', naslov: 'Oljčna pot 63', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Srednja šola za gostinstvo in turizem Maribor', naslov: 'Mladinska ulica 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja zdravstvena šola Izola', naslov: 'Polje 41', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Gimnazija Antonio Sema Piran', naslov: 'Med vrtovi 8', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Šolski center Postojna', naslov: 'Cesta v Staro vas 2', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Šolski center Krško - Sevnica, Srednja šola Sevnica', naslov: 'Savska cesta 2', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Šolski center Škofja Loka, Srednja šola za lesarstvo', naslov: 'Kidričeva cesta 59', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Gimnazija Škofja Loka', naslov: 'Podlubnik 1 B', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Šolski center Škofja Loka', naslov: 'Podlubnik 1 B', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Šolski center Rogaška Slatina', naslov: 'Steklarska ulica 1', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Šolski center Celje, Srednja šola za storitvene dejavnosti in logistiko', naslov: 'Ljubljanska cesta 17', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja šola Štore', naslov: 'Kovinarska ulica 1', posta: '3220', kraj: 'Štore' },
    { naziv: 'Srednja gostinska in turistična šola Izola', naslov: 'Ulica Prekomorskih brigad 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Srednja ekonomsko - poslovna šola Koper', naslov: 'Martinčev trg 3', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Biotehniški center Naklo', naslov: 'Strahinj 99', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Konservatorij za glasbo in balet Ljubljana, Srednja glasbena in baletna šola', naslov: 'Ižanska cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja zdravstvena in kozmetična šola Maribor', naslov: 'Trg Miloša Zidanška 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Lesarska šola Maribor', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Semedela, enota Slavnik', naslov: 'Nova ulica 2 B', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Ravne na Koroškem, ENOTA SOLZICE', naslov: 'Čečovje 7 A', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Vrtec Nova Gorica, Enota Najdihojca', naslov: 'Gregorčičeva ulica 17', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Jarše, enota Rožle', naslov: 'Rožičeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Waldorfska šola Ljubljana (zasebni vrtec), OE GORENJSKA, oddelek HRUŠICA', naslov: 'Hrušica 5', posta: '4276', kraj: 'Hrušica' },
    { naziv: 'Srednja lesarska šola Nova Gorica', naslov: 'Erjavčeva ulica 4 A', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Ekonomska šola Novo mesto', naslov: 'Ulica talcev 3 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Gimnazija Novo mesto', naslov: 'Seidlova cesta 9', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Srednja šola za gostinstvo in turizem', naslov: 'Ulica talcev 3', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Pomorski in tehniški izobraževalni center Portorož', naslov: 'Pot pomorščakov 4', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Šolski center Slovenj Gradec', naslov: 'Koroška ulica 11', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Biotehniška šola Rakičan', naslov: 'Lendavska ulica 3', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Srednja gozdarska in lesarska šola Postojna', naslov: 'Tržaška cesta 36', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Srednja šola za strojništvo Ljubljana', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Strokovni izobraževalni center Ljubljana', naslov: 'Ptujska ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja gradbena šola in gimnazija Maribor', naslov: 'Smetanova ulica 35', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja tekstilna, obutvena in gumarska šola Kranj', naslov: 'Cesta Staneta Žagarja 33', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Gimnazija in ekonomska srednja šola Trbovlje', naslov: 'Gimnazijska cesta 10', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Konservatorij za glasbo in balet Maribor', naslov: 'Mladinska ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Slovenj Gradec, Srednja šola Muta', naslov: 'Koroška cesta 53', posta: '2366', kraj: 'Muta' },
    { naziv: 'Gimnazija in srednja šola Rudolfa Maistra Kamnik', naslov: 'Novi trg 41 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Srednja elektro-računalniška šola Maribor', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja strojna šola Maribor', naslov: 'Smetanova ulica 18', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Studenci Maribor, OE Iztokova', naslov: 'Žabotova ulica 10', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Najdihojca, Enota Biba', naslov: 'Ljubeljska ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Najdihojca, Enota Biba, dislocirani oddelki LEK', naslov: 'Gorazdova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Elektrotehniško-računalniška strokovna šola in gimnazija Ljubljana', naslov: 'Vegova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja ekonomska šola Ljubljana', naslov: 'Roška cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Ledina', naslov: 'Resljeva cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Vič', naslov: 'Tržaška cesta 72', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniški izobraževalni center Ljubljana', naslov: 'Ižanska cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Brežice', naslov: 'Trg izgnancev 14', posta: '8250', kraj: 'Brežice' },
    { naziv: 'I. gimnazija v Celju', naslov: 'Kajuhova ulica 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja šola Domžale', naslov: 'Cesta talcev 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Srednja šola za gostinstvo in turizem Radenci', naslov: 'Mladinska ulica 5', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Ekonomsko-storitveni izobraževalni center Kranj', naslov: 'Kidričeva cesta 65', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Srednja šola za gastronomijo in turizem Ljubljana', naslov: 'Preglov trg 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja vzgojiteljska šola, gimnazija in umetniška gimnazija Ljubljana', naslov: 'Kardeljeva ploščad 28 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola za farmacijo, kozmetiko in zdravstvo', naslov: 'Zdravstvena pot 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Šentvid', naslov: 'Prušnikova ulica 98', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Srednja šola Veno Pilon Ajdovščina', naslov: 'Cesta 5. maja 12', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Tehniški šolski center Kranj', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Srednja kemijska šola in gimnazija Ljubljana', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana, enota Mavrica', naslov: 'Savska cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VRTEC VIŠKI GAJ, ENOTA KOZARJE', naslov: 'Hacetova ulica 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VRTEC VIŠKI GAJ, ENOTA BONIFACIJA', naslov: 'Ulica Malči Beličeve 20', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Izobraževalni center Geoss d.o.o.', naslov: 'Cesta komandanta Staneta 2', posta: '1270', kraj: 'Litija' },
    { naziv: 'Šolski center Srečka Kosovela Sežana', naslov: 'Stjenkova ulica 3', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Tekstilna šola Metlika', naslov: 'Trg izgnancev 14', posta: '8250', kraj: 'Brežice' },
    { naziv: 'III. gimnazija Maribor', naslov: 'Gosposvetska cesta 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gimnazija Celje - Center', naslov: 'Kosovelova ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Celje', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja ekonomska šola Celje', naslov: 'Vodnikova ulica 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja tehniška šola Koper', naslov: 'Šmarska cesta 4 E', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Srednja trgovska šola Kranj', naslov: 'Župančičeva ulica 22', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Center za poslovno usposabljanje, Višja strokovna šola Ljubljana', naslov: 'Kardeljeva ploščad 27 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Bežigrad', naslov: 'Peričeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja zdravstvena šola Celje', naslov: 'Ipavčeva ulica 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'Gimnazija Koper', naslov: 'Cankarjeva ulica 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Gimnazija Gian Rinaldo Carli Koper', naslov: 'Gimnazijski trg 7', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Srednja šola Pietro Coppo Izola', naslov: 'Zustovičeva ulica 6', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Ekonomsko-storitveni izobraževalni center Kranj, Gradbeno-storitvena šola', naslov: 'Cesta Staneta Žagarja 33', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Gimnazija Kranj', naslov: 'Koroška cesta 13', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Krško - Sevnica', naslov: 'Cesta krških žrtev 131', posta: '8270', kraj: 'Krško' },
    { naziv: 'Dvojezična srednja šola Lendava', naslov: 'Kolodvorska ulica 2 E', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA MISLINJA oddelek DOLIČ', naslov: 'Srednji Dolič 4', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'VVZ Kekec Grosuplje, enota Pastirček', naslov: 'Kersnikova cesta 2', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota LABOD', naslov: 'Seidlova cesta 33', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota NAJDIHOJCA', naslov: 'Mali Slatnik 7', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Srednja gradbena, geodetska in okoljevarstvena šola Ljubljana', naslov: 'Dunajska cesta 102', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola kmetijske mehanizacije Maribor', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Izobraževalni center Piramida Maribor', naslov: 'Park mladih 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Biotehniška šola Maribor', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Prva gimnazija Maribor', naslov: 'Trg generala Maistra 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja zdravstvena šola Murska Sobota', naslov: 'Ulica dr. Vrbnjaka 2', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Srednja šola za gostinstvo in turizem Celje', naslov: 'Kosovelova ulica 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'Poslovno-komercialna šola Celje', naslov: 'Kosovelova ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja šola Črnomelj', naslov: 'Kidričeva ulica 18 A', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Gimnazija Jurija Vege Idrija', naslov: 'Študentovska ulica 16', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Srednja upravno administrativna šola Ljubljana', naslov: 'Zdravstvena pot 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola za oblikovanje in fotografijo Ljubljana', naslov: 'Gosposka ulica 18', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja lesarska šola Ljubljana', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Nova Gorica, Srednja ekonomska in trgovska šola', naslov: 'Erjavčeva ulica 8', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Srednja trgovska šola Ljubljana', naslov: 'Poljanska cesta 28 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja medijska in grafična šola Ljubljana', naslov: 'Pokopališka ulica 33', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola za oblikovanje Maribor', naslov: 'Park mladih 8', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Kočevje, Enota Čebelica', naslov: 'Ljubljanska cesta 22', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'VRTEC JELKA, ENOTA SNEGULJČICA', naslov: 'Vodovodna cesta 3 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'OŠ BISTRICA OB SOTLI-ENOTA VRTEC PIKAPOLONICA', naslov: 'Bistrica ob Sotli 63 A', posta: '3256', kraj: 'Bistrica ob Sotli' },
    { naziv: 'Vrtec Anice Černejeve Celje, Enota Hribček', naslov: 'Hrašovčeva ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'OTROŠKI VRTEC METLIKA, oddelek', naslov: 'Dolnji Suhor pri Metliki 2', posta: '8331', kraj: 'Suhor' },
    { naziv: 'Osnovna šola Vinica', naslov: 'Vinica 50', posta: '8344', kraj: 'Vinica' },
    { naziv: 'Osnovna šola Janka Kersnika Brdo', naslov: 'Brdo pri Lukovici 5', posta: '1225', kraj: 'Lukovica' },
    { naziv: 'Osnovna šola Domžale', naslov: 'Bistriška cesta 19', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Podbočje', naslov: 'Podbočje 82', posta: '8312', kraj: 'Podbočje' },
    { naziv: 'Osnovna šola Jožeta Hudalesa Jurovski dol', naslov: 'Jurovski Dol 13', posta: '2223', kraj: 'Jurovski Dol' },
    { naziv: 'Osnovna šola Lenart', naslov: 'Ptujska cesta 25', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'Osnovna šola Sveta Ana', naslov: 'Sv. Ana v Slov. goricah 14', posta: '2233', kraj: 'Sv. Ana v Slov. goricah' },
    { naziv: 'Osnovna šola Benedikt', naslov: 'Šolska ulica 2', posta: '2234', kraj: 'Benedikt' },
    { naziv: 'Osnovna šola in vrtec Sveta Trojica', naslov: 'Meznaričeva ulica 1', posta: '2235', kraj: 'Sv. Trojica v Slov. goricah' },
    { naziv: 'Osnovna šola Cerkvenjak - Vitomarci', naslov: 'Cerkvenjak 24', posta: '2236', kraj: 'Cerkvenjak' },
    { naziv: 'Osnovna šola Miška Kranjca Velika Polana', naslov: 'Velika Polana 215 B', posta: '9225', kraj: 'Velika Polana' },
    { naziv: 'Dvojezična osnovna šola I Lendava', naslov: 'Kranjčeva ulica 44', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Dvojezična osnovna šola II Lendava - II. Sz. Lendvai Kétnyelvü Általános Iskola', naslov: 'Ulica heroja Mohorja – Hös Mohor utca 1', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Osnovna šola Kobilje', naslov: 'Kobilje 33 A', posta: '9227', kraj: 'Kobilje' },
    { naziv: 'Dvojezična osnovna šola Dobrovnik', naslov: 'Dobrovnik 266 J', posta: '9223', kraj: 'Dobrovnik - Dobronak' },
    { naziv: 'Dvojezična osnovna šola Genterovci', naslov: 'Šolska ulica 2', posta: '9223', kraj: 'Dobrovnik - Dobronak' },
    { naziv: 'Osnovna šola Turnišče', naslov: 'Prešernova ulica 2', posta: '9224', kraj: 'Turnišče' },
    { naziv: 'Vrtec Koper, Enota Bertoki', naslov: 'Cesta borcev 8 F', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Koper, Enota Pobegi-Zajček', naslov: 'Cesta I. Istrske brigade 52', posta: '6276', kraj: 'Pobegi' },
    { naziv: 'Vrtec Jarše, enota Rožle - hiša Zelena jama', naslov: 'Pokopališka ulica 30', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Waldorfska šola Ljubljana (zasebni vrtec), enota', naslov: 'Hrušica 5', posta: '4276', kraj: 'Hrušica' },
    { naziv: 'Osnovna šola Kajetana Koviča Radenci', naslov: 'Radgonska cesta 10', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Osnovna šola Stogovci', naslov: 'Stogovci 33', posta: '9253', kraj: 'Apače' },
    { naziv: 'Osnovna šola in vrtec Apače', naslov: 'Apače 38', posta: '9253', kraj: 'Apače' },
    { naziv: 'Osnovna šola Ferda Vesela Šentvid pri Stični', naslov: 'Šentvid pri Stični 46', posta: '1296', kraj: 'Šentvid pri Stični' },
    { naziv: 'Osnovna šola Dobrepolje', naslov: 'Videm 80', posta: '1312', kraj: 'Videm - Dobrepolje' },
    { naziv: 'Osnovna šola 27. julij', naslov: 'Tomšičeva ulica 9', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Glasbena šola Kamnik', naslov: 'Kajuhova pot 11', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Stranje', naslov: 'Zgornje Stranje 22', posta: '1242', kraj: 'Stahovica' },
    { naziv: 'Osnovna šola Zbora odposlancev Kočevje', naslov: 'Trg zbora odposlancev 28', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Ljubo Šercer', naslov: 'Reška cesta 6', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Glasbena šola Kočevje', naslov: 'Reška cesta 16', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Fara', naslov: 'Fara 3', posta: '1336', kraj: 'Kostel' },
    { naziv: 'Osnovna šola Elvire Vatovec Prade - Koper', naslov: 'Pobeška cesta 52', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Janka Premrla - Vojka Koper', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Pinka Tomažiča Koper', naslov: 'Cesta Zore Perello-Godina 1', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Dušana Bordona Semedela - Koper', naslov: 'Rozmanova ulica 21 A', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Pier Paolo Vergerio il Vecchio Koper', naslov: 'Gimnazijski trg 7', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Glasbena šola Koper', naslov: 'Gallusova ulica 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Šolski center Nova Gorica, Medpodjetniški izobraževalni center', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Anice Černejeve Celje, Enota Mavrica', naslov: 'Čopova ulica 21', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Zarja Celje, enota vrtca Žiž žav', naslov: 'Zagajškova ulica 8', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Palček', naslov: 'Šmartno v Tuhinju 13 B', posta: '1219', kraj: 'Laze v Tuhinju' },
    { naziv: 'Osnovna šola Dekani', naslov: 'Dekani 32', posta: '6271', kraj: 'Dekani' },
    { naziv: 'Osnovna šola Istrskega odreda Gračišče', naslov: 'Gračišče 5', posta: '6272', kraj: 'Gračišče' },
    { naziv: 'Osnovna šola Ivana Babiča - Jagra Marezige', naslov: 'Marezige 33 A', posta: '6273', kraj: 'Marezige' },
    { naziv: 'Osnovna šola Šmarje pri Kopru', naslov: 'Šmarje 1', posta: '6274', kraj: 'Šmarje' },
    { naziv: 'Osnovna šola dr. Aleš Bebler - Primož Hrvatini', naslov: 'Hrvatini 137', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Franceta Prešerna Kranj', naslov: 'Kidričeva cesta 49', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Matije Čopa Kranj', naslov: 'Ulica Tuga Vidmarja 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Simona Jenka Kranj', naslov: 'Ulica XXXI. divizije 7 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Stražišče Kranj', naslov: 'Šolska ulica 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Staneta Žagarja Kranj', naslov: 'Cesta 1. maja 10 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Jakoba Aljaža Kranj', naslov: 'Ulica Tončka Dežmana 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Predoslje Kranj', naslov: 'Predoslje 17 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Helene Puhar Kranj', naslov: 'Kidričeva cesta 51', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Glasbena šola Kranj', naslov: 'Poštna ulica 3', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Davorina Jenka Cerklje na Gorenjskem', naslov: 'Krvavška cesta 4', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Osnovna šola Šenčur', naslov: 'Pipanova cesta 43', posta: '4208', kraj: 'Šenčur' },
    { naziv: 'Osnovna šola Jurija Dalmatina Krško', naslov: 'Šolska ulica 1', posta: '8270', kraj: 'Krško' },
    { naziv: 'Osnovna šola dr. Mihajla Rostoharja Krško', naslov: 'Cesta 4. julija 33', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Urša, enota Čebelica', naslov: 'Ljubljanska cesta 95', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Vrtec Urša, enota Bistra', naslov: 'Ulica Nikola Tesla 16', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Glasbena šola Krško', naslov: 'Kolodvorska ulica 2', posta: '8270', kraj: 'Krško' },
    { naziv: 'Osnovna šola Leskovec pri Krškem', naslov: 'Pionirska cesta 4', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Adama Bohoriča Brestanica', naslov: 'Šolska cesta 29', posta: '8280', kraj: 'Brestanica' },
    { naziv: 'Osnovna šola XIV. divizije Senovo', naslov: 'Trg XIV. divizije 3', posta: '8281', kraj: 'Senovo' },
    { naziv: 'Osnovna šola Koprivnica', naslov: 'Koprivnica 2', posta: '8282', kraj: 'Koprivnica' },
    { naziv: 'Osnovna šola Jožeta Gorjupa Kostanjevica na Krki', naslov: 'Gorjanska cesta 2', posta: '8311', kraj: 'Kostanjevica na Krki' },
    { naziv: 'Osnovna šola Otlica', naslov: 'Otlica 48', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola Danila Lokarja Ajdovščina', naslov: 'Cesta 5. maja 15', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola Draga Bajca Vipava', naslov: 'Vinarska cesta 4', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Osnovna šola Col', naslov: 'Col 35', posta: '5273', kraj: 'Col' },
    { naziv: 'Glasbena šola Brežice', naslov: 'Cesta prvih borcev 5', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Osnovna šola Artiče', naslov: 'Artiče 39', posta: '8253', kraj: 'Artiče' },
    { naziv: 'Osnovna šola Globoko', naslov: 'Globoko 9', posta: '8254', kraj: 'Globoko' },
    { naziv: 'Osnovna šola Maksa Pleteršnika Pišece', naslov: 'Pišece 34', posta: '8255', kraj: 'Pišece' },
    { naziv: 'Osnovna šola dr. Jožeta Toporišiča Dobova', naslov: 'Kapelska cesta 25', posta: '8257', kraj: 'Dobova' },
    { naziv: 'Osnovna šola Velika Dolina', naslov: 'Velika Dolina 30', posta: '8261', kraj: 'Jesenice na Dolenjskem' },
    { naziv: 'Osnovna šola Cerklje ob Krki', naslov: 'Cerklje ob Krki 3', posta: '8263', kraj: 'Cerklje ob Krki' },
    { naziv: 'Osnovna šoli Miklavž na Dravskem polju, Vrtec Ciciban', naslov: 'Kidričeva cesta 55', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'Vrtec Ciciban, Enota Pastirčki', naslov: 'Glinškova ploščad 11 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VIZ Vrtci Brezovica, Enota OŠ Notranje Gorice', naslov: 'Podpeška cesta 390', posta: '1357', kraj: 'Notranje Gorice' },
    { naziv: 'Osnovna šola Franceta Prešerna Črenšovci', naslov: 'Ulica Juša Kramarja 10', posta: '9232', kraj: 'Črenšovci' },
    { naziv: 'Osnovna šola Odranci', naslov: 'Prešernova ulica 1', posta: '9233', kraj: 'Odranci' },
    { naziv: 'Osnovna šola Gabrovka - Dole', naslov: 'Gabrovka 30', posta: '1274', kraj: 'Gabrovka' },
    { naziv: 'Osnovna šola Šmartno', naslov: 'Pungrt 9', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Glasbena šola Hrastnik', naslov: 'Cesta 1. maja 61', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Osnovna šola Črni Vrh', naslov: 'Črni Vrh 95', posta: '5274', kraj: 'Črni vrh nad Idrijo' },
    { naziv: 'Glasbena šola Idrija', naslov: 'Prelovčeva ulica 9', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Center za izobraževanje in usposabljanje Nikolaj Pirnat Idrija', naslov: 'Ulica IX. korpusa 17', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Osnovna šola Spodnja Idrija', naslov: 'Šolska ulica 9', posta: '5281', kraj: 'Spodnja Idrija' },
    { naziv: 'Osnovna šola Cerkno', naslov: 'Bevkova ulica 26', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Osnovna šola Rudija Mahniča - Brkinca Pregarje', naslov: 'Pregarje 18', posta: '6243', kraj: 'Obrov' },
    { naziv: 'Osnovna šola Rudolfa Ukoviča Podgrad', naslov: 'Podgrad 99 B', posta: '6244', kraj: 'Podgrad' },
    { naziv: 'Osnovna šola Podgora Kuteževo', naslov: 'Kuteževo 2 F', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Glasbena šola Ilirska Bistrica', naslov: 'Ulica IV. armije 5', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Osnovna šola Toneta Tomšiča Knežak', naslov: 'Knežak 147 A', posta: '6253', kraj: 'Knežak' },
    { naziv: 'Osnovna šola Jelšane', naslov: 'Jelšane 82', posta: '6254', kraj: 'Jelšane' },
    { naziv: 'Osnovna šola Dante Alighieri Izola', naslov: 'Ulica oktobrske revolucije 10', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Osnovna šola Vojke Šmuc Izola', naslov: 'Prešernova cesta 4', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'VIZ Vrtci Brezovica, Enota Podpeč', naslov: 'Jezero 113', posta: '1352', kraj: 'Preserje' },
    { naziv: 'VIZ Vrtci Brezovica, Enota Rakitna', naslov: 'Rakitna 54', posta: '1352', kraj: 'Preserje' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Gorenjska, Enota Vrtec', naslov: 'Hrušica 5', posta: '4276', kraj: 'Hrušica' },
    { naziv: 'Osnovna šola Komenda Moste', naslov: 'Glavarjeva cesta 37', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Osnovna šola Marije Vere', naslov: 'Ljubljanska cesta 16 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Frana Albrehta Kamnik', naslov: 'Šolska ulica 1', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Toma Brejca Kamnik', naslov: 'Šutna 39', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Ekonomska šola Murska Sobota', naslov: 'Noršinska ulica 13', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Gimnazija Nova Gorica', naslov: 'Delpinova ulica 9', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Srednja tehniška in poklicna šola Trbovlje', naslov: 'Šuštarjeva kolonija 7 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Srednja šola za elektrotehniko in gostinstvo Zagorje ob Savi', naslov: 'Cesta zmage 3', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Gimnazija Poljane', naslov: 'Strossmayerjeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Velenje', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Šolski center Ptuj', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Ljudska univerza Viktor Stražišar Jesenice', naslov: 'Cesta maršala Tita 86', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Gimnazija Murska Sobota', naslov: 'Šolsko naselje 12', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Srednja poklicna in tehniška šola Murska Sobota', naslov: 'Šolsko naselje 12', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Šolski center Ravne na Koroškem, Gimnazija', naslov: 'Na gradu 4', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Šolski center Ravne na Koroškem', naslov: 'Na gradu 4', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Center za izobraževanje in usposabljanje Nikolaj Pirnat Idrija', naslov: 'Ulica IX. korpusa 17', posta: '5280', kraj: 'Idrija' },
    { naziv: 'OŠ Ledina - šol.odd. v UKC', naslov: '', posta: '', kraj: '' },
    { naziv: 'VIZ Vrtci Brezovica, Enota Vnanje Gorice', naslov: 'Nova pot 9', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'OŠ Franja Goloba Prevalje, Vrtec Pod gonje Prevalje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zavod Sv. Jerneja, Vrtec Blaže in Nežica', naslov: 'Trg Alfonza Šarha 6', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Šenčur, Enota Vrtec, oddelek Visoko', naslov: 'Visoko 67', posta: '4212', kraj: 'Visoko' },
    { naziv: 'Osnovna šola Ledina', naslov: 'Komenskega ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE Pri OŠ Simon Jenko p.o. Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Slovenske Konjice - Zreče', naslov: 'Tattenbachova ulica 2 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnovna šola Dobravlje', naslov: 'Dobravlje 1', posta: '5263', kraj: 'Dobravlje' },
    { naziv: 'Gimnazija Kočevje', naslov: 'Ljubljanska cesta 12', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Roje', naslov: 'Kettejeva ulica 15', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Glasbena šola Domžale', naslov: 'Ljubljanska cesta 61', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Dob', naslov: 'Šolska ulica 7', posta: '1233', kraj: 'Dob' },
    { naziv: 'Osnovna šola Mengeš', naslov: 'Šolska ulica 11', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'Osnovna šola Trzin', naslov: 'Mengeška cesta 7 B', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Osnovna šola Preserje pri Radomljah', naslov: 'Pelechova cesta 83', posta: '1235', kraj: 'Radomlje' },
    { naziv: 'Osnovna šola Jurija Vege', naslov: 'Vegova ulica 38', posta: '1251', kraj: 'Moravče' },
    { naziv: 'Osnovna šola Neznanih talcev Dravograd', naslov: 'Trg 4. julija 64', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'Osnovna šola Sveti Jurij ob Ščavnici', naslov: 'Ulica Edvarda Kocbeka 4', posta: '9244', kraj: 'Sveti Jurij ob Ščavnici' },
    { naziv: 'Osnovna šola dr. Antona Trstenjaka Negova', naslov: 'Negova 20', posta: '9245', kraj: 'Spodnji Ivanjci' },
    { naziv: 'Osnovna šola dr. Janka Šlebingerja Gornja Radgona', naslov: 'Maistrov trg 2', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Glasbena šola Gornja Radgona', naslov: 'Partizanska cesta 25', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Osnovna šola Kapela', naslov: 'Kapelski Vrh 95', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Župnijski zavod sv. Martina, Baragov vrtec', naslov: 'Baragov trg 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Polhov Gradec, Enota Vrtec pri PŠ Šentjošt', naslov: 'Šentjošt nad Horjulom 54', posta: '1354', kraj: 'Horjul' },
    { naziv: 'Vrtec Hansa Christiana Andersena, enota Palčica', naslov: 'Vodnikova cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Podzemelj', naslov: 'Podzemelj 11 A', posta: '8332', kraj: 'Gradac' },
    { naziv: 'Osnovna šola Mozirje', naslov: 'Šolska ulica 23', posta: '3330', kraj: 'Mozirje' },
    { naziv: 'Osnovna šola Ljubno ob Savinji', naslov: 'Cesta v Rastke 10', posta: '3333', kraj: 'Ljubno ob Savinji' },
    { naziv: 'Osnovna šola Blaža Arniča Luče', naslov: 'Luče 77', posta: '3334', kraj: 'Luče' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji Grad', naslov: 'Kocbekova cesta 21', posta: '3342', kraj: 'Gornji Grad' },
    { naziv: 'Osnovna šola Bakovci', naslov: 'Poljska ulica 2', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Glasbena šola Murska Sobota', naslov: 'Cvetkova ulica 2 C', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola Puconci', naslov: 'Puconci 178', posta: '9201', kraj: 'Puconci' },
    { naziv: 'Osnovna šola Gornji Petrovci', naslov: 'Gornji Petrovci 2', posta: '9203', kraj: 'Petrovci' },
    { naziv: 'Osnovna šola Šalovci', naslov: 'Šalovci 172', posta: '9204', kraj: 'Šalovci' },
    { naziv: 'Osnovna šola Šentvid', naslov: 'Prušnikova ulica 98', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Osnovna šola Šmartno pod Šmarno goro', naslov: 'Cesta v Gameljne 7', posta: '1211', kraj: 'Ljubljana - Šmartno' },
    { naziv: 'Osnovna šola Preska', naslov: 'Preška cesta 22', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Simona Jenka', naslov: 'Smlednik 73', posta: '1216', kraj: 'Smlednik' },
    { naziv: 'Osnovna šola Vodice', naslov: 'Ob šoli 2', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Osnovna šola Trnovo', naslov: 'Karunova ulica 14 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Vrhovci', naslov: 'Cesta na Bokalce 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Oskarja Kovačiča', naslov: 'Ob dolenjski železnici 48', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Janka Glazerja Ruše - enota Vrtec Ruše', naslov: 'Lesjakova ulica 4', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Župnijski zavod Sv. Jurija Stara Loka, Vrtec Sončni žarek', naslov: 'Stara Loka 67', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Rogašovci, enota', naslov: 'Rogašovci 16 B', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Vrtec Rogašovci, enota A', naslov: 'Rogašovci 16 B', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Prva osnovna šola Slovenj Gradec', naslov: 'Šercerjeva ulica 7', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Tretja osnovna šola Slovenj Gradec', naslov: 'Šercerjeva ulica 11', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Glasbena šola Slovenj Gradec', naslov: 'Glavni trg 40', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Osnovna šola Podgorje pri Slovenj Gradcu', naslov: 'Podgorje 172', posta: '2381', kraj: 'Podgorje pri Slovenj Gradcu' },
    { naziv: 'Osnovna šola Mislinja', naslov: 'Šentilj pod Turjakom 1', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'Osnovna šola Pohorskega odreda Slovenska Bistrica', naslov: 'Kopališka ulica 1', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Minke Namestnik- Sonje Slovenska Bistrica', naslov: 'Partizanska ulica 20', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Šmartno na Pohorju', naslov: 'Šmartno na Pohorju 24 A', posta: '2315', kraj: 'Šmartno na Pohorju' },
    { naziv: 'Osnovna šola Pohorskega bataljona Oplotnica', naslov: 'Ulica Pohorskega bataljona 19', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Osnovna šola Gustava Šiliha Laporje', naslov: 'Laporje 31', posta: '2318', kraj: 'Laporje' },
    { naziv: 'Osnovna šola dr. Jožeta Pučnika Črešnjevec', naslov: 'Črešnjevec 47', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Angela Besednjaka Maribor', naslov: 'Celjska ulica 11', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Janka Padežnika Maribor', naslov: 'Iztokova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Toneta Čufarja Maribor', naslov: 'Zrkovska cesta 67', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Maksa Durjave Maribor', naslov: 'Ruška cesta 15', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Draga Kobala Maribor', naslov: 'Tolstojeva ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Ivana Cankarja Maribor', naslov: 'Cankarjeva ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Slave Klavore Maribor', naslov: 'Štrekljeva ulica 31', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA TABOR', naslov: 'Gorenjska cesta 1 A', posta: '1370', kraj: 'Logatec' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA ROVTE', naslov: 'Rovte 97', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Vrtec Sežana, enota Štanjel', naslov: 'Štanjel 75', posta: '6222', kraj: 'Štanjel' },
    { naziv: 'Osnovna šola Ludvika Pliberška Maribor', naslov: 'Lackova cesta 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Borisa Kidriča Maribor', naslov: 'Žolgarjeva ulica 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Dom Antona Skale', naslov: 'Majcigerjeva ulica 37', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja glasbena in baletna šola Maribor', naslov: 'Mladinska ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Starše', naslov: 'Starše 5', posta: '2205', kraj: 'Starše' },
    { naziv: 'Osnovna šola Duplek', naslov: 'Korenska cesta 31', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Osnovna šola Dušana Flisa Hoče', naslov: 'Šolska ulica 10', posta: '2311', kraj: 'Hoče' },
    { naziv: 'Osnovna šola Miklavž na Dravskem polju', naslov: 'Cesta v Dobrovce 21', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'Osnovna šola Franca Lešnika - Vuka Slivnica pri Mariboru', naslov: 'Mariborska cesta 4', posta: '2312', kraj: 'Orehova vas' },
    { naziv: 'Osnovna šola Fram', naslov: 'Turnerjeva ulica 120', posta: '2313', kraj: 'Fram' },
    { naziv: 'Osnovna šola Rače', naslov: 'Grajski trg 1', posta: '2327', kraj: 'Rače' },
    { naziv: 'Glasbena šola pri Osnovni šoli Šmarje pri Jelšah', naslov: '', posta: '', kraj: '' },
    { naziv: 'JVIZ I. Osnovna šola Rogaška Slatina', naslov: 'Kidričeva ulica 24', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'VIZ II. OŠ Rogaška Slatina', naslov: 'Ulica Kozjanskega odreda 4', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'JVIZ III. Osnovna šola Rogaška Slatina', naslov: 'Izletniška ulica 15', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'VIZ Osnovna šola Rogatec', naslov: 'Ptujska cesta 30', posta: '3252', kraj: 'Rogatec' },
    { naziv: 'Osnovna šola Podčetrtek', naslov: 'Trška cesta 66', posta: '3254', kraj: 'Podčetrtek' },
    { naziv: 'Osnovna šola Bistrica ob Sotli', naslov: 'Bistrica ob Sotli 63 A', posta: '3256', kraj: 'Bistrica ob Sotli' },
    { naziv: 'Osnovna šola Kozje', naslov: 'Kozje 131', posta: '3260', kraj: 'Kozje' },
    { naziv: 'Vrtec Litija, Enota Kresnička', naslov: 'Kresnice 26 A', posta: '1281', kraj: 'Kresnice' },
    { naziv: 'Vrtec Jožice Flander, OE RAZVANJE', naslov: 'Razvanjska cesta 64', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC JELKA, ENOTA VILA', naslov: 'Staničeva ulica 37 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Šalek Velenje', naslov: 'Šalek 87', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Livada Velenje', naslov: 'Efenkova cesta 60', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Antona Aškerca Velenje', naslov: 'Jenkova cesta 2', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Gustava Šiliha Velenje', naslov: 'Vodnikova cesta 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Mihe Pintarja - Toleda Velenje', naslov: 'Kidričeva cesta 21', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Center za vzgojo, izobraževanje in usposabljanje Velenje', naslov: 'Kidričeva cesta 19', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Glasbena šola Fran Korun Koželjski Velenje', naslov: 'Jenkova cesta 4', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Karla Destovnika - Kajuha Šoštanj', naslov: 'Koroška cesta 7', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Bibe Roecka Šoštanj', naslov: 'Kajuhova cesta 8', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola bratov Letonja Šmartno ob Paki', naslov: 'Šmartno ob Paki 117', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'Osnovna šola Log - Dragomer', naslov: 'Šolska ulica 1', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Osnovna šola dr. Ivana Korošca Borovnica', naslov: 'Paplerjeva ulica 15', posta: '1353', kraj: 'Borovnica' },
    { naziv: 'Osnovna šola Ivana Cankarja Vrhnika', naslov: 'Lošca 1', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Glasbena šola Vrhnika', naslov: 'Trg Karla Grabeljška 3', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Glasbena šola Zagorje', naslov: 'Cankarjev trg 4', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Osnovna šola Toneta Okrogarja', naslov: 'Šolska ulica 1', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Osnovna šola Ivana Kavčiča Izlake', naslov: 'Izlake 4', posta: '1411', kraj: 'Izlake' },
    { naziv: 'Osnovna šola dr. Slavka Gruma', naslov: 'Cankarjev trg 2', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec Trbovlje, Enota Mojca', naslov: 'Novi dom 51', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Vrtec Trbovlje, Enota Metuljček', naslov: 'Dobovec 50', posta: '1423', kraj: 'Dobovec' },
    { naziv: 'Vrtec Litija, Enota Jurček', naslov: 'Jevnica 58', posta: '1281', kraj: 'Kresnice' },
    { naziv: 'Osnovna šola Petrovče', naslov: 'Petrovče 32', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'Osnovna šola Griže', naslov: 'Griže 1 A', posta: '3302', kraj: 'Griže' },
    { naziv: 'Osnovna šola Vransko - Tabor', naslov: 'Vransko 23', posta: '3305', kraj: 'Vransko' },
    { naziv: 'I. osnovna šola Žalec', naslov: 'Šilihova ulica 1', posta: '3310', kraj: 'Žalec' },
    { naziv: 'II. Osnovna šola Žalec', naslov: 'Šilihova ulica 1', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola Šempeter v Savinjski dolini', naslov: 'Šolska ulica 2', posta: '3311', kraj: 'Šempeter v Savinjski dolini' },
    { naziv: 'Osnovna šola Prebold', naslov: 'Graščinska cesta 7', posta: '3312', kraj: 'Prebold' },
    { naziv: 'Osnovna šola Polzela', naslov: 'Šolska ulica 3', posta: '3313', kraj: 'Polzela' },
    { naziv: 'Osnovna šola Braslovče', naslov: 'Rakovlje 15 B', posta: '3314', kraj: 'Braslovče' },
    { naziv: 'Osnovna šola Prežihovega Voranca Maribor', naslov: 'Gosposvetska cesta 10', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Malečnik', naslov: 'Malečnik 61', posta: '2229', kraj: 'Malečnik' },
    { naziv: 'Osnovna šola Franceta Prešerna Maribor', naslov: 'Žolgarjeva ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Bojana Ilicha Maribor', naslov: 'Mladinska ulica 13', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola "Borcev za severno mejo" Maribor', naslov: 'Borcev za severno mejo 16', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola bratov Polančičev Maribor', naslov: 'Prešernova ulica 19', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Staneta Žagarja Lipnica', naslov: 'Lipnica 12', posta: '4245', kraj: 'Kropa' },
    { naziv: 'Osnovna šola F. S. Finžgarja Lesce', naslov: 'Begunjska cesta 7', posta: '4248', kraj: 'Lesce' },
    { naziv: 'Vrtec Šoštanj, enota Mojca', naslov: 'Gaberke 101', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Vrtec Šoštanj, enota Barbka', naslov: 'Tekavčeva cesta 15', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Vrtec Šoštanj, enota Urška', naslov: 'Topolšica 98', posta: '3326', kraj: 'Topolšica' },
    { naziv: 'Vrtec Šoštanj, enota Lučka', naslov: 'Koroška cesta 13', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Lesično', naslov: 'Lesično 5 B', posta: '3261', kraj: 'Lesično' },
    { naziv: 'Osnovna šola Franceta Bevka Tolmin', naslov: 'Dijaška ulica 12 B', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Osnovna šola Franceta Bevka - Enota Glasbena šola Tolmin', naslov: '', posta: '', kraj: '' },
    { naziv: 'Center za izobraževanje in usposabljanje Tolmin', naslov: 'Rutarjeva ulica 18', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Osnovna šola Simona Gregorčiča Kobarid', naslov: 'Gregorčičeva ulica 18 A', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Osnovna šola Simona Kosa Podbrdo', naslov: 'Podbrdo 32', posta: '5243', kraj: 'Podbrdo' },
    { naziv: 'Glasbena šola Trbovlje', naslov: 'Keršičeva cesta 50 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Trebnje', naslov: 'Kidričeva ulica 11', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Osnovna šola Veliki Gaber', naslov: 'Veliki Gaber 41', posta: '8213', kraj: 'Veliki Gaber' },
    { naziv: 'Osnovna šola Mokronog', naslov: 'Gubčeva cesta 4', posta: '8230', kraj: 'Mokronog' },
    { naziv: 'Osnovna šola dr. Pavla Lunačka Šentrupert', naslov: 'Šentrupert 57', posta: '8232', kraj: 'Šentrupert' },
    { naziv: 'Osnovna šola Mirna', naslov: 'Cesta na Fužine 1', posta: '8233', kraj: 'Mirna' },
    { naziv: 'Glasbena šola Karol Pahor Ptuj', naslov: 'Dravska ulica 11', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola dr. Franja Žgeča Dornava', naslov: 'Dornava 136 A', posta: '2252', kraj: 'Dornava' },
    { naziv: 'Osnovna šola Destrnik - Trnovska vas', naslov: 'Janežovski Vrh 45', posta: '2253', kraj: 'Destrnik' },
    { naziv: 'Osnovna šola Juršinci', naslov: 'Juršinci 19', posta: '2256', kraj: 'Juršinci' },
    { naziv: 'Osnovna šola Gorišnica', naslov: 'Gorišnica 83', posta: '2272', kraj: 'Gorišnica' },
    { naziv: 'Osnovna šola Markovci', naslov: 'Markovci 33 D', posta: '2281', kraj: 'Markovci' },
    { naziv: 'GEA COLLEGE PIC poslovnoizobraževalni center, d.o.o.', naslov: 'Dunajska cesta 156', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Medpodjetniški izobraževalni center', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Šoštanj, enota Brina', naslov: 'Kajuhova cesta 3', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'VVZ Vrtec Šoštanj, enota MAJA', naslov: 'Šmartno ob Paki 30', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'Osnovna šola Cirkulane - Zavrč', naslov: 'Cirkulane 57', posta: '2282', kraj: 'Cirkulane' },
    { naziv: 'Osnovna šola Videm', naslov: 'Videm pri Ptuju 47', posta: '2284', kraj: 'Videm pri Ptuju' },
    { naziv: 'Osnovna šola Podlehnik', naslov: 'Podlehnik 7 A', posta: '2286', kraj: 'Podlehnik' },
    { naziv: 'Osnovna šola Hajdina', naslov: 'Spodnja Hajdina 24', posta: '2288', kraj: 'Hajdina' },
    { naziv: 'Osnovna šola Majšperk', naslov: 'Majšperk 32 B', posta: '2322', kraj: 'Majšperk' },
    { naziv: 'Osnovna šola Kidričevo', naslov: 'Kajuhova ulica 10', posta: '2325', kraj: 'Kidričevo' },
    { naziv: 'Osnovna šola Cirkovce', naslov: 'Cirkovce 47', posta: '2326', kraj: 'Cirkovce' },
    { naziv: 'Glasbena šola pri Osnovni šoli Radlje ob Dravi', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Brezno - Podvelka', naslov: 'Brezno 78', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Ribnica na Pohorju', naslov: 'Ribnica na Pohorju 29', posta: '2364', kraj: 'Ribnica na Pohorju' },
    { naziv: 'Osnovna šola Muta', naslov: 'Šolska ulica 6', posta: '2366', kraj: 'Muta' },
    { naziv: 'Osnovna šola Vuzenica', naslov: 'Mladinska ulica 3', posta: '2367', kraj: 'Vuzenica' },
    { naziv: 'Glasbena šola Radovljica', naslov: 'Linhartov trg 1', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Kajetana Koviča Poljčane', naslov: 'Dravinjska cesta 26', posta: '2319', kraj: 'Poljčane' },
    { naziv: 'Osnovna šola Anice Černejeve Makole', naslov: 'Makole 24', posta: '2321', kraj: 'Makole' },
    { naziv: 'Osnovna šola Antona Ingoliča Spodnja Polskava', naslov: 'Spodnja Polskava 240', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'Osnovna šola Dramlje', naslov: 'Dramlje 7 A', posta: '3222', kraj: 'Dramlje' },
    { naziv: 'Osnovna šola Planina pri Sevnici', naslov: 'Planina pri Sevnici 69', posta: '3225', kraj: 'Planina pri Sevnici' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana, enota Mehurčki', naslov: 'Parmska cesta 41', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVZ Vrtec Šoštanj, enota KATJA', naslov: 'Šmartno ob Paki 117', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'Osnovna šola Log - Dragomer, Enota vrtec Log - Dragomer', naslov: 'Na Grivi 1', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Glasbena šola skladateljev Ipavcev Šentjur', naslov: 'Ulica skladateljev Ipavcev 30', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Slivnica pri Celju', naslov: 'Gorica pri Slivnici 61', posta: '3263', kraj: 'Gorica pri Slivnici' },
    { naziv: 'Osnovna šola Ivana Groharja', naslov: 'Podlubnik 1', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Škofja Loka - Mesto', naslov: 'Šolska ulica 1', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Cvetka Golarja Škofja Loka', naslov: 'Frankovo naselje 51', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Jela Janežiča', naslov: 'Podlubnik 1', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Glasbena šola Škofja Loka', naslov: 'Puštal 21', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas', naslov: 'Trata 40', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'Osnovna šola Žiri', naslov: 'Jobstova cesta 22', posta: '4226', kraj: 'Žiri' },
    { naziv: 'Osnovna šola Železniki', naslov: 'Otoki 13', posta: '4228', kraj: 'Železniki' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško', naslov: 'Trubarjeva ulica 20', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Marjana Nemca Radeče (sprememba št.ROS)', naslov: 'Šolska pot 5', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice (sprememba št.ROS)', naslov: 'Aškerčeva cesta 1', posta: '3272', kraj: 'Rimske Toplice' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor', naslov: 'Majcigerjeva ulica 31', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Center za izobraževanje in kulturo Glasbena šola', naslov: 'Kidričeva ulica 2', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Osnovna šola Križe', naslov: 'Cesta Kokrškega odreda 16', posta: '4294', kraj: 'Križe' },
    { naziv: 'Osnovna šola Bistrica', naslov: 'Begunjska cesta 2', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Tržič', naslov: 'Šolska ulica 7', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Zali rovt enota Glasbena šola Tržič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ravne na Koroškem, Višja strokovna šola', naslov: 'Koroška cesta 10', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Sp. Polskava', naslov: 'Spodnja Polskava 245', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Gorenjska, Vrtec Radovljica', naslov: 'Kranjska cesta 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Sostro', naslov: 'Cesta II. grupe odredov 47', posta: '1261', kraj: 'Ljubljana - Dobrunje' },
    { naziv: 'Osnovna šola Hinka Smrekarja Ljubljana', naslov: 'Gorazdova ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Vižmarje - Brod', naslov: 'Na gaju 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Koseze', naslov: 'Ledarska ulica 23', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Valentina Vodnika Ljubljana', naslov: 'Adamičeva ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Riharda Jakopiča Ljubljana', naslov: 'Derčeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Franca Rozmana - Staneta Ljubljana', naslov: 'Prušnikova ulica 85', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Osnovna šola Dravlje', naslov: 'Klopčičeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Franca Šturma', naslov: 'Celovška cesta 98', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Krmelj', naslov: 'Krmelj 104', posta: '8296', kraj: 'Krmelj' },
    { naziv: 'Osnovna šola Milan Majcen Šentjanž', naslov: 'Šentjanž 58 A', posta: '8297', kraj: 'Šentjanž' },
    { naziv: 'Glasbena šola Sežana', naslov: 'Kosovelova ulica 4 B', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Osnovna šola dr. Bogomirja Magajne Divača', naslov: 'Ulica dr. Bogomira Magajne 4', posta: '6215', kraj: 'Divača' },
    { naziv: 'Osnovna šola Dutovlje', naslov: 'Dutovlje 135', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Osnovna šola Antona Šibelja - Stjenka Komen', naslov: 'Komen 61 A', posta: '6223', kraj: 'Komen' },
    { naziv: 'Osnovna šola Dragomirja Benčiča - Brkina Hrpelje', naslov: 'Reška cesta 30', posta: '6240', kraj: 'Kozina' },
    { naziv: 'Osnovna šola Šmartno pri Slovenj Gradcu', naslov: 'Šmartno pri Slovenj Gradcu 69', posta: '2383', kraj: 'Šmartno pri Slovenj Gradcu' },
    { naziv: 'Vrtec Ciciban, Enota Ajda', naslov: 'Ulica prvoborcev 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Semedela, enota Rozmanova', naslov: 'Veluščkova ulica 6', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Šenčur, Enota Vrtec, oddelek Voklo', naslov: 'Voklo 7', posta: '4208', kraj: 'Šenčur' },
    { naziv: 'Osnovna šola Kolezija', naslov: 'Cesta v Mestni log 46', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Vič', naslov: 'Abramova ulica 26', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Ljubljana Vič - Rudnik', naslov: 'Gradaška ulica 24', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Škofljica', naslov: 'Klanec 5', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Osnovna šola Ig', naslov: 'Troštova ulica 24', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Primoža Trubarja Velike Lašče', naslov: 'Šolska ulica 11', posta: '1315', kraj: 'Velike Lašče' },
    { naziv: 'Osnovna šola Brezovica pri Ljubljani', naslov: 'Šolska ulica 15', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Osnovna šola Preserje', naslov: 'Preserje 60', posta: '1352', kraj: 'Preserje' },
    { naziv: 'Osnovna šola Polhov Gradec', naslov: 'Polhov Gradec 95', posta: '1355', kraj: 'Polhov Gradec' },
    { naziv: 'Osnovna šola Dornberk', naslov: 'Gregorčičeva ulica 30 A', posta: '5294', kraj: 'Dornberk' },
    { naziv: 'Osnovna šola Branik', naslov: 'Branik 31', posta: '5295', kraj: 'Branik' },
    { naziv: 'Osnovna šola Otočec', naslov: 'Šolska cesta 20', posta: '8222', kraj: 'Otočec' },
    { naziv: 'Osnovna šola Frana Metelka Škocjan', naslov: 'Škocjan 51', posta: '8275', kraj: 'Škocjan' },
    { naziv: 'Osnovna šola Šmihel', naslov: 'Šmihel 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Center Novo mesto', naslov: 'Seidlova cesta 7', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Bršljin', naslov: 'Kočevarjeva ulica 40', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Glasbena šola Marjana Kozine Novo mesto', naslov: 'Jenkova ulica 1', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Toneta Pavčka', naslov: 'Postaja 1', posta: '8216', kraj: 'Mirna Peč' },
    { naziv: 'Vrtec Ciciban, Enota Lenka', naslov: 'Baragova ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Sežana, enota Dutovlje', naslov: 'Dutovlje 136', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Waldorfska šola Ljubljana, enota Maribor', naslov: 'Cankarjeva ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Šmarjeta', naslov: 'Šmarjeta 1', posta: '8220', kraj: 'Šmarješke Toplice' },
    { naziv: 'Osnovna šola Šentjernej', naslov: 'Prvomajska cesta 9', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Osnovna šola Brusnice', naslov: 'Velike Brusnice 101', posta: '8321', kraj: 'Brusnice' },
    { naziv: 'Osnovna šola Stopiče', naslov: 'Stopiče 37', posta: '8322', kraj: 'Stopiče' },
    { naziv: 'Osnovna šola Dolenjske Toplice', naslov: 'Pionirska cesta 35', posta: '8350', kraj: 'Dolenjske Toplice' },
    { naziv: 'Osnovna šola Vavta vas', naslov: 'Vavta vas 1', posta: '8351', kraj: 'Straža pri Novem mestu' },
    { naziv: 'Osnovna šola Žužemberk', naslov: 'Baragova cesta 1', posta: '8360', kraj: 'Žužemberk' },
    { naziv: 'Osnovna šola Prevole', naslov: 'Prevole 32', posta: '8362', kraj: 'Hinje' },
    { naziv: 'Osnovna šola Sveti Tomaž', naslov: 'Sveti Tomaž 11', posta: '2258', kraj: 'Sv.Tomaž' },
    { naziv: 'Osnovna šola Ormož', naslov: 'Hardek 5', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Osnovna šola Fokovci', naslov: 'Fokovci 32', posta: '9208', kraj: 'Fokovci' },
    { naziv: 'Osnovna šola Bogojina', naslov: 'Bogojina 134', posta: '9222', kraj: 'Bogojina' },
    { naziv: 'Osnovna šola Tišina', naslov: 'Tišina 4 B', posta: '9251', kraj: 'Tišina' },
    { naziv: 'Osnovna šola Cankova', naslov: 'Cankova 27', posta: '9261', kraj: 'Cankova' },
    { naziv: 'Osnovna šola Sveti Jurij', naslov: 'Sveti Jurij 13', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Kuzma', naslov: 'Kuzma 20', posta: '9263', kraj: 'Kuzma' },
    { naziv: 'Osnovna šola Grad', naslov: 'Grad 172 E', posta: '9264', kraj: 'Grad' },
    { naziv: 'Osnovna šola Frana Erjavca Nova Gorica', naslov: 'Kidričeva ulica 36', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola Milojke Štrukelj Nova Gorica', naslov: 'Delpinova ulica 7', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Šolski center Ravne na Koroškem, Medpodjetniški izobraževalni center', naslov: 'Na gradu 4', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Vrtec Ciciban, Enota Čebelica', naslov: 'Šarhova ulica 29', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Solkan', naslov: 'Šolska ulica 25', posta: '5250', kraj: 'Solkan' },
    { naziv: 'Osnovna šola Kozara Nova Gorica', naslov: 'Kidričeva ulica 35', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola Deskle', naslov: 'Srebrničeva ulica 10', posta: '5210', kraj: 'Deskle' },
    { naziv: 'Osnovna šola Alojza Gradnika Dobrovo', naslov: 'Trg 25. maja 9', posta: '5212', kraj: 'Dobrovo v Brdih' },
    { naziv: 'Osnovna šola Kanal', naslov: 'Gradnikova ulica 25', posta: '5213', kraj: 'Kanal' },
    { naziv: 'Osnovna šola Čepovan', naslov: 'Čepovan 87', posta: '5253', kraj: 'Čepovan' },
    { naziv: 'Osnovna šola Šempas', naslov: 'Šempas 76 C', posta: '5261', kraj: 'Šempas' },
    { naziv: 'Glasbena šola Nova Gorica', naslov: 'Cankarjeva ulica 8', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola Miren', naslov: 'Miren 140', posta: '5291', kraj: 'Miren' },
    { naziv: 'Osnovna šola Lucijana Bratkoviča Bratuša Renče', naslov: 'Trg 31', posta: '5292', kraj: 'Renče' },
    { naziv: 'Osnovna šola Jožeta Moškriča', naslov: 'Jarška cesta 34', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Martina Krpana Ljubljana', naslov: 'Gašperšičeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Nove Jarše', naslov: 'Clevelandska ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Nove Fužine', naslov: 'Preglov trg 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Ketteja in Murna', naslov: 'Koširjeva ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Ljubljana Moste - Polje', naslov: 'Ob Ljubljanici 36', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Zadobrova', naslov: 'Zadobrovška cesta 35', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Osnovna šola Zalog', naslov: 'Cerutova ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Polje', naslov: 'Polje 358', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Črešnjevec', naslov: 'Črešnjevec 47', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Laporje', naslov: 'Laporje 31', posta: '2318', kraj: 'Laporje' },
    { naziv: 'Osnovna šola Hudinja', naslov: 'Mariborska cesta 125', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Lava Celje', naslov: 'Pucova ulica 7', posta: '3000', kraj: 'Celje' },
    { naziv: 'IV. osnovna šola Celje', naslov: 'Dečkova cesta 60', posta: '3000', kraj: 'Celje' },
    { naziv: 'I. osnovna šola Celje', naslov: 'Vrunčeva ulica 13', posta: '3000', kraj: 'Celje' },
    { naziv: 'II. osnovna šola Celje', naslov: 'Ljubljanska cesta 46', posta: '3000', kraj: 'Celje' },
    { naziv: 'III. osnovna šola Celje', naslov: 'Vodnikova ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Frana Roša Celje', naslov: 'Cesta na Dobrovo 114', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Stanka Vraza Ormož', naslov: 'Dobravska ulica 13 A', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Osnovna šola Velika Nedelja', naslov: 'Velika Nedelja 13', posta: '2274', kraj: 'Velika Nedelja' },
    { naziv: 'Osnovna šola Miklavž pri Ormožu', naslov: 'Miklavž pri Ormožu 29', posta: '2275', kraj: 'Miklavž pri Ormožu' },
    { naziv: 'Osnovna šola Središče ob Dravi', naslov: 'Slovenska cesta 22', posta: '2277', kraj: 'Središče ob Dravi' },
    { naziv: 'Osnovna šola Lucija', naslov: 'Fazan 1', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Center za usposabljanje Elvira Vatovec Strunjan', naslov: 'Strunjan 140', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Osnovna šola Vincenzo e Diego de Castro Piran', naslov: 'Vojkova ulica 1', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Osnovna šola Cirila Kosmača Piran', naslov: 'Oljčna pot 24', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Osnovna šola Sečovlje', naslov: 'Sečovlje 78', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Osnovna šola Miroslava Vilharja Postojna', naslov: 'Trg padlih borcev 1 A', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Dijaški in študentski dom', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Vrhnika, enota Barjanček', naslov: 'Stara Vrhnika 2', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'JVIZ Vrtec Idrija, Enota Spodnja Idrija', naslov: 'Šolska ulica 11', posta: '5281', kraj: 'Spodnja Idrija' },
    { naziv: 'VIZ Vrtci Brezovica, Enota Brezovica', naslov: 'V Radno 9', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Glasbena šola Postojna', naslov: 'Ljubljanska cesta 10', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Košana', naslov: 'Dolnja Košana 61', posta: '6256', kraj: 'Košana' },
    { naziv: 'Osnovna šola Pivka', naslov: 'Prečna ulica 3', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Osnovna šola Prestranek', naslov: 'Ulica 25. maja 14 A', posta: '6258', kraj: 'Prestranek' },
    { naziv: 'Osnovna šola Mladika Ptuj', naslov: 'Žnidaričevo nabrežje 1', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Breg', naslov: 'Rogaška cesta 6', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Ljudski vrt Ptuj', naslov: 'Župančičeva ulica 10', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Ljudevita Pivka Ptuj', naslov: 'Ulica 25. maja 2 A', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Zavod za izobraževanje in kulturo - enota Glasbena šola Litija', naslov: 'Trg na Stavbah 8 A', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Savsko naselje', naslov: 'Matjaževa ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Danile Kumar', naslov: 'Gogalova ulica 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Mirana Jarca', naslov: 'Ipavčeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola dr. Vita Kraigherja Ljubljana', naslov: 'Trg 9. maja 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Franceta Bevka Ljubljana', naslov: 'Ulica Pohorskega bataljona 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Bežigrad', naslov: 'Črtomirova ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Milana Šuštaršiča', naslov: 'Štembalova ulica 2 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola narodnega heroja Maksa Pečarja', naslov: 'Črnuška cesta 9', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Osnovna šola Janka Modra Dol pri Ljubljani', naslov: 'Videm 17', posta: '1262', kraj: 'Dol pri Ljubljani' },
    { naziv: 'Osnovna šola Prežihovega Voranca', naslov: 'Prežihova ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Sežana, enota Jasli', naslov: 'Regentova ulica 1', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Vrtec Hansa Christiana Andersena, enota Lastovica', naslov: 'Derčeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Majde Vrhovnik Ljubljana', naslov: 'Gregorčičeva ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Koper', naslov: 'Kettejeva ulica 13', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Vodmat', naslov: 'Potrčeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Poljane Ljubljana', naslov: 'Zemljemerska ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Prule', naslov: 'Prule 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za usposabljanje, vzgojo in izobraževanje Janeza Levca Ljubljana', naslov: 'Karlovška cesta 18', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja glasbena in baletna šola Ljubljana', naslov: 'Vegova ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Vide Pregarc Ljubljana', naslov: 'Bazoviška ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Dobrova', naslov: 'Cesta 7. maja 20', posta: '1356', kraj: 'Dobrova' },
    { naziv: 'Osnovna šola Janeza Kuharja Razkrižje', naslov: 'Šafarsko 24 C', posta: '9246', kraj: 'Razkrižje' },
    { naziv: 'Osnovna šola Stročja vas', naslov: 'Stročja vas 101', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Janka Ribiča Cezanjevci', naslov: 'Cezanjevci 39', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Glasbena šola Slavka Osterca Ljutomer', naslov: 'Prešernova ulica 8', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Križevci', naslov: 'Križevci pri Ljutomeru 16 B', posta: '9242', kraj: 'Križevci pri Ljutomeru' },
    { naziv: 'Osnovna šola 8 talcev Logatec', naslov: 'Notranjska cesta 3', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Osnovna šola Tabor Logatec', naslov: 'Tržaška cesta 150', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Osnovna šola Metlika', naslov: 'Šolska ulica 7', posta: '8330', kraj: 'Metlika' },
    { naziv: 'Glasbena šola Logatec', naslov: 'Notranjska cesta 4', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Osnovna šola Dragotina Ketteja Ilirska Bistrica', naslov: 'Župančičeva ulica 7', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Osnovna šola Bizeljsko', naslov: 'Bizeljska cesta 78', posta: '8259', kraj: 'Bizeljsko' },
    { naziv: 'Vrtec Vrhovci, enota Rožnik', naslov: 'Cesta na Brdo 30', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Kočevje, enota Ostržek', naslov: 'Cesta na stadion 3', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Izobraževalni zavod HERA, Višja strokovna šola Enota Kočevje', naslov: 'Trg zbora odposlancev 30', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Sladka gora', naslov: 'Sladka Gora 5', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Osnovna šola Vojnik Podružnica Šmartno v Rožni dolini', naslov: 'Šmartno v Rožni dolini 3', posta: '3201', kraj: 'Šmartno v Rožni dolini' },
    { naziv: 'Osnovna šola Jožeta Krajca Rakek Podružnica Rudolfa Maistra Unec', naslov: 'Unec 59', posta: '1381', kraj: 'Rakek' },
    { naziv: 'Osnovna šola Loka Črnomelj, Podružnična šola Griblje', naslov: 'Griblje 38', posta: '8332', kraj: 'Gradac' },
    { naziv: 'Osnovna šola Loka Črnomelj, Podružnična šola Adlešiči', naslov: 'Adlešiči 16', posta: '8341', kraj: 'Adlešiči' },
    { naziv: 'Osnovna šola Venclja Perka Podružnica Dragomelj', naslov: 'Dragomelj 116', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Brje', naslov: 'Brje 53', posta: '5263', kraj: 'Dobravlje' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Vipavski Križ', naslov: 'Vipavski Križ 10', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Črniče', naslov: 'Črniče 27', posta: '5262', kraj: 'Črniče' },
    { naziv: 'Osnovna šola Draga Bajca Vipava Podružnica Budanje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Draga Bajca Vipava Podružnica Goče', naslov: 'Goče 6', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Osnovna šola Draga Bajca Vipava Podružnica Podnanos', naslov: 'Podnanos 77', posta: '5272', kraj: 'Podnanos' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Kamnje', naslov: 'Kamnje 64', posta: '5263', kraj: 'Dobravlje' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Šmarje', naslov: 'Šmarje 48', posta: '5295', kraj: 'Branik' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Vrtovin', naslov: 'Vrtovin 74', posta: '5262', kraj: 'Črniče' },
    { naziv: 'Osnovna šola Draga Bajca Vipava Podružnica Vrhpolje', naslov: 'Vrhpolje 41', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Osnovna šola Dobravlje Podružnica Skrilje', naslov: 'Skrilje 39', posta: '5263', kraj: 'Dobravlje' },
    { naziv: 'Osnovna šola Notranjski odred Cerknica, Podružnična šola "Maksim Gaspari" Begunje pri Cerknici', naslov: 'Begunje pri Cerknici 26', posta: '1382', kraj: 'Begunje pri Cerknici' },
    { naziv: 'Osnovna šola Notranjski odred Cerknica, Podružnična šola "11.maj" Grahovo', naslov: 'Grahovo 120', posta: '1384', kraj: 'Grahovo' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič, Podružnična šola Štrekljevec', naslov: 'Štrekljevec 2', posta: '8333', kraj: 'Semič' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič Podružnica Črešnjevec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Janka Kersnika Brdo, Podružnična šola Blagovica', naslov: 'Blagovica 33', posta: '1223', kraj: 'Blagovica' },
    { naziv: 'VRTEC JELKA, ENOTA PALČKI', naslov: 'Lavričeva ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod Antona Martina Slomška, Hiša otrok - vrtec montessori', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Uršulinski zavod za vzgojo, izobraževanje in kulturo, Angelin vrtec', naslov: 'Ulica Josipine Turnograjske 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ljudska univerza Škofja Loka', naslov: 'Podlubnik 1 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Center za izobraževanje in kulturo Trebnje Ljudska univerza', naslov: 'Kidričeva ulica 2', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Andragoški zavod , Ljudska univerza Maribor', naslov: 'Maistrova ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ljudska univerza Ptuj', naslov: 'Mestni trg 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Ljudska univerza Radovljica', naslov: 'Kranjska cesta 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske toplice Podružnica Jurklošter (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Mestinje', naslov: 'Mestinje 43', posta: '3241', kraj: 'Podplat' },
    { naziv: 'Osnovna šola Simona Kosa Podružnica Grahovo ob Bači', naslov: 'Grahovo ob Bači 17', posta: '5242', kraj: 'Grahovo ob Bači' },
    { naziv: 'Osnovna šola Franja Goloba Prevalje, Podružnična šola Holmec', naslov: 'Dolga Brda 37 A', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Osnovna šola Franja Goloba Prevalje, Podružnična šola Šentanel', naslov: 'Šentanel 13', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Osnovna šola dr. Ivana Prijatelja Sodražica, Podružnična šola Sv. Gregor', naslov: 'Sv. Gregor 7', posta: '1316', kraj: 'Ortnek' },
    { naziv: 'Osnovna šola dr. Franceta Prešerna, Podružnica Dolenja vas', naslov: 'Šolska ulica 9', posta: '1331', kraj: 'Dolenja vas' },
    { naziv: 'Osnovna šola dr. Franceta Prešerna, Podružnica Sušje', naslov: 'Sušje 2', posta: '1310', kraj: 'Ribnica' },
    { naziv: 'Osnovna šola Savo Kladnik Podružnica Bučka (Je pri OŠ Škocjan !!!)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Sava Kladnika Podružnica Loka', naslov: 'Loka pri Zidanem Mostu 72', posta: '1434', kraj: 'Loka pri Zidanem Mostu' },
    { naziv: 'Osnovna šola Sava Kladnika Podružnica Studenec', naslov: 'Studenec 38', posta: '8293', kraj: 'Studenec' },
    { naziv: 'Osnovna šola Dragomirja Benčiča - Brkina Hrpelje Podružnica Materija', naslov: 'Materija 18', posta: '6242', kraj: 'Materija' },
    { naziv: 'Osnovna šola Dragomirja Benčiča - Brkina Hrpelje Podružnica Obrov', naslov: 'Obrov 63', posta: '6243', kraj: 'Obrov' },
    { naziv: 'Osnovna šola Dragomirja Benčiča - Brkina Hrpelje Podružnica Slivje', naslov: 'Slivje 11', posta: '6242', kraj: 'Materija' },
    { naziv: 'Osnovna šola dr. Bogomirja Magajne Podružnica Misliče', naslov: 'Misliče 27', posta: '6217', kraj: 'Vremski Britof' },
    { naziv: 'Osnovna šola dr. Bogomirja Magajne Divača, Podružnična šola Senožeče', naslov: 'Senožeče 103 A', posta: '6224', kraj: 'Senožeče' },
    { naziv: 'Osnovna šola dr. Bogomirja Magajne Divača, Podružnična šola Vreme', naslov: 'Vremski Britof 19', posta: '6217', kraj: 'Vremski Britof' },
    { naziv: 'Vrtec Studenci Maribor, OE Pekre', naslov: 'Bezjakova ulica 19', posta: '2341', kraj: 'Limbuš' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA ALJAŽEV HRIB', naslov: 'Celestinova ulica 1 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA LJUBEČNA', naslov: 'Kocbekova cesta 40 A', posta: '3202', kraj: 'Ljubečna' },
    { naziv: 'Vrtec Tončke Čečeve - Ljubečna', naslov: 'Kocbekova cesta 27', posta: '3202', kraj: 'Ljubečna' },
    { naziv: 'Osnovna šola Dutovlje Podružnica Pliskovica', naslov: 'Pliskovica 96', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Osnovna šola Dutovlje Podružnica Štanjel', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Dutovlje Podružnica Tomaj', naslov: 'Tomaj 63', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Osnovna šola Mislinja Podružnica Dolič', naslov: 'Srednji Dolič 4', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'Osnovna šola Mislinja Podružnica Paški Kozjak', naslov: 'Kozjak 93', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'Osnovna šola Miren, Podružnica Bilje', naslov: 'Bilje 185', posta: '5292', kraj: 'Renče' },
    { naziv: 'Osnovna šola Petrovče Podružnica Velika Pirešica', naslov: 'Velika Pirešica 1', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola Prebold Podružnica Orla vas-Trnava', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Vransko -Tabor,  Podružnična osnovna šola Tabor', naslov: 'Tabor 20', posta: '3304', kraj: 'Tabor' },
    { naziv: 'Osnovna šola prof. dr. Josipa Plemlja Bled, Podružnična šola Bohinjska Bela', naslov: 'Bohinjska Bela 56', posta: '4263', kraj: 'Bohinjska Bela' },
    { naziv: 'Osnovna šola prof. dr. Josipa Plemlja Bled, Podružnična šola Ribno', naslov: 'Šolska ulica 15', posta: '4260', kraj: 'Bled' },
    { naziv: 'Osnovna šola dr. Janeza Mencingerja Boh. Bistrica, Podružnična šola Srednja vas', naslov: 'Srednja vas v Bohinju 69', posta: '4267', kraj: 'Srednja vas v Bohinju' },
    { naziv: 'Osnovna šola Staneta Žagarja Lipnica Podružnica Ovsiše', naslov: 'Ovsiše 56', posta: '4244', kraj: 'Podnart' },
    { naziv: 'Osnovna šola Antona Tomaža Linharta Radovljica Podružnica Begunje', naslov: 'Begunje na Gorenjskem 27 B', posta: '4275', kraj: 'Begunje na Gorenjskem' },
    { naziv: 'Osnovna šola Antona Tomaža Linharta Radovljica Podružnica Mošnje', naslov: 'Mošnje 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Antona Tomaža Linharta Radovljica Podružnica Ljubno', naslov: 'Ljubno 82', posta: '4244', kraj: 'Podnart' },
    { naziv: 'Osnovna šola Idrija Podružnica Godovič', naslov: 'Godovič 35 B', posta: '5275', kraj: 'Godovič' },
    { naziv: 'Osnovna šola Idrija, Podružnična šola Zavratec', naslov: 'Zavratec 1', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Osnovna šola Idrija Podružnica Gore - Dole', naslov: 'Spodnja Kanomlja 20', posta: '5281', kraj: 'Spodnja Idrija' },
    { naziv: 'Osnovna šola Idrija Podružnica Vojsko', naslov: 'Vojsko 2', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Osnovna šola Cerkno, Podružnična šola Novaki', naslov: 'Dolenji Novaki 26', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Osnovna šola Cerkno, Podružnična šola Šebrelje', naslov: 'Šebrelje 38', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Osnovna šola Spodnja Idrija, Podružnična šola Ledine', naslov: 'Ledine 10', posta: '5281', kraj: 'Spodnja Idrija' },
    { naziv: 'Vrtec Beltinci, enota Dokležovje', naslov: 'Glavna ulica 15', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Vrtec Beltinci, enota Gančani', naslov: 'Gančani 1', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'OŠ Franja Goloba Prevalje, Vrtec Prevalje - Zgornji kraj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas Podružnica Leskovica', naslov: 'Leskovica 1', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, Podružnična šola Lučine', naslov: 'Lučine 11', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, Podružnična šola Sovodenj', naslov: 'Sovodenj 32', posta: '4225', kraj: 'Sovodenj' },
    { naziv: 'Osnovna šola Ivana Groharja, Podružnična šola Bukovica', naslov: 'Bukovica 21', posta: '4227', kraj: 'Selca' },
    { naziv: 'Osnovna šola Ivana Groharja, Podružnična šola Bukovščica', naslov: 'Bukovščica 4', posta: '4227', kraj: 'Selca' },
    { naziv: 'Osnovna šola Ivana Groharja, Podružnična šola Sv. Lenart', naslov: 'Sv. Lenart 24', posta: '4227', kraj: 'Selca' },
    { naziv: 'Osnovna šola Cvetka Golarja Škofja Loka Podružnica Reteče', naslov: 'Reteče 43', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Železniki, Podružnica Davča', naslov: 'Davča 22', posta: '4228', kraj: 'Železniki' },
    { naziv: 'Osnovna šola Tržič, Podružnična šola Lom', naslov: 'Lom pod Storžičem 12', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Tržič, Podružnična šola Podljubelj', naslov: 'Podljubelj 107', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Gustava Šiliha Velenje, Podružnična šola Šentilj', naslov: 'Arnače 2 A', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Mihe Pintarja - Toleda Velenje Podružnica Plešivec', naslov: 'Plešivec 32', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Antona Aškerca Velenje, Podružnična šola Pesje', naslov: 'Ulica Pohorskega bataljona 12', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Bibe Roecka Šoštanj Podružnica Ravne', naslov: 'Ravne 82 A', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Šoštanj Podružnica Bele vode', naslov: 'Bele Vode 18 A', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Karla Destovnika - Kajuha Šoštanj Podružnica Topolšica', naslov: 'Topolšica 98', posta: '3326', kraj: 'Topolšica' },
    { naziv: 'Osnovna šola Karla Destovnika - Kajuha Šoštanj Podružnica Zavodnje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Šalek Podružnica Paka', naslov: 'Paka pri Velenju 68', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Log - Dragomer, Podružnična šola Bevke', naslov: 'Bevke 13', posta: '1358', kraj: 'Log pri Brezovici' },
    { naziv: 'Osnovna šola Log - Dragomer Podružnica Drenov grič', naslov: 'Drenov Grič 4', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Osnovna šola Toneta Okrogarja, Podružnična šola Šentlambert', naslov: 'Šentlambert 11', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec Beltinci, enota Ižakovci', naslov: 'Ižakovci 72 A', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Zavod sv. Stanislava, Jegličev dijaški dom', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Zg. Polskava', naslov: 'Ingoličeva ulica 2', posta: '2314', kraj: 'Zgornja Polskava' },
    { naziv: 'Osnovna šola Ivana Kavčiča Izlake Podružnica Mlinše', naslov: 'Mlinše 14', posta: '1411', kraj: 'Izlake' },
    { naziv: 'Osnovna šola Ivana Skvarče, Podružnica Čemšenik', naslov: 'Čemšenik 2', posta: '1413', kraj: 'Čemšenik' },
    { naziv: 'Osnovna šola Ivana Skvarče, Podružnica Podkum', naslov: 'Podkum 13', posta: '1414', kraj: 'Podkum' },
    { naziv: 'Osnovna šola Griže, Podružnična osnovna šola Liboje', naslov: 'Liboje 121', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'I. osnovna šola Žalec Podružnica Gotovlje', naslov: 'Gotovlje 73', posta: '3310', kraj: 'Žalec' },
    { naziv: 'I. osnovna šola Žalec Podružnica Ponikva', naslov: 'Ponikva pri Žalcu 17', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola Braslovče, Podružnična osnovna šola Gomilsko', naslov: 'Gomilsko 34', posta: '3303', kraj: 'Gomilsko' },
    { naziv: 'Osnovna šola Braslovče, Podružnična osnovna šola Letuš', naslov: 'Letuš 31', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'Osnovna šola Polzela, Podružnična osnovna šola Andraž', naslov: 'Andraž nad Polzelo 64', posta: '3313', kraj: 'Polzela' },
    { naziv: 'Osnovna šola Pesnica, Podružnična osnovna šola Pernica', naslov: 'Pernica 2', posta: '2231', kraj: 'Pernica' },
    { naziv: 'Osnovna šola Rudolfa Maistra Šentilj, Podružnična šola Ceršak', naslov: 'Tovarniška cesta 50', posta: '2215', kraj: 'Ceršak' },
    { naziv: 'Osnovna šola Kungota, Podružnična šola Spodnja Kungota', naslov: 'Gradiška 219', posta: '2211', kraj: 'Pesnica pri Mariboru' },
    { naziv: 'Osnovna šola Kungota, Podružnična šola Svečina', naslov: 'Plač 2', posta: '2201', kraj: 'Zgornja Kungota' },
    { naziv: 'Osnovna šola Vojke Šmuc Izola, Podružnična šola Korte', naslov: 'Korte 14 C', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Osnovna šola Stranje Podružnica Gozd', naslov: 'Krivčevo 2', posta: '1242', kraj: 'Stahovica' },
    { naziv: 'Osnovna šola Toma Brejca Podružnica Sela', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toma Brejca Podružnica Loke', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toma Brejca Podružnica Šmartno', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toma Brejca Podružnica Zgornji Tuhinj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toma Brejca Podružnica Motnik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Frana Albrehta Kamnik Podružnica Mekinje', naslov: 'Jeranovo 11', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Frana Albrehta Kamnik Podružnica Nevlje', naslov: 'Nevlje 18', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Frana Albrehta Kamnik Podružnica Tunjice', naslov: 'Tunjice 22', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Šturje, Ajdovščina', naslov: 'Bevkova ulica 22', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA MAISTROVA', naslov: 'Maistrova ulica 2 A', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA MISLINJA', naslov: 'Šentlenart 31', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'Zasebni vrtec Mali grof d.o.o.', naslov: 'Grajska ulica 8 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnovna šola Spodnja Idrija Podružnica Otalež', naslov: 'Otalež 3', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Osnovna šola Spodnja Idrija Podružnica Kanomlja', naslov: 'Srednja Kanomlja 2', posta: '5281', kraj: 'Spodnja Idrija' },
    { naziv: 'Osnovna šola dr. Jožeta Toporišiča Dobova, Podružnična šola Kapele', naslov: 'Kapele 4 A', posta: '8258', kraj: 'Kapele' },
    { naziv: 'Osnovna šola Selnica ob Dravi, Podružnična šola Sveti Duh na Ostrem vrhu', naslov: 'Sv. Duh na Ostrem vrhu 53', posta: '2353', kraj: 'Sv. Duh na Ostrem Vrhu' },
    { naziv: 'Osnovna šola Selnica ob Dravi, Podružnična šola Gradišče na Kozjaku', naslov: 'Gradišče na Kozjaku 39', posta: '2361', kraj: 'Ožbalt' },
    { naziv: 'Osnovna šola Šmartno, Podružnica Primskovo', naslov: 'Gradišče pri Litiji 28', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Železniki, Podružnica Dražgoše', naslov: 'Dražgoše 35', posta: '4228', kraj: 'Železniki' },
    { naziv: 'Osnovna šola Železniki, Podružnica Selca', naslov: 'Selca 95', posta: '4227', kraj: 'Selca' },
    { naziv: 'Osnovna šola Železniki, Podružnica Sorica', naslov: 'Zgornja Sorica 3', posta: '4229', kraj: 'Sorica' },
    { naziv: 'Osnovna šola Franceta Bevka Tolmin Podružnica Kamno', naslov: 'Kamno 22', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Osnovna šola Franceta Bevka Tolmin Podružnica Anton Majnik Volče', naslov: 'Volče 97 C', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Osnovna šola Simona Gregorčiča Kobarid, Podružnica Breginj', naslov: 'Breginj 65', posta: '5223', kraj: 'Breginj' },
    { naziv: 'Osnovna šola Simona Gregorčiča Kobarid Podružnica Livek', naslov: 'Livek 11', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Osnovna šola Simona Gregorčiča Kobarid, Podružnica Drežnica', naslov: 'Drežnica 16 E', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Osnovna šola Simona Gregorčiča Kobarid, Podružnica Smast', naslov: 'Smast 13', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči, Podružnična šola Dolenja Trebuša', naslov: 'Dolenja Trebuša 68', posta: '5283', kraj: 'Slap ob Idrijci' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči Podružnica Kanalski lom', naslov: 'Kanalski Lom 19', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči, Podružnična šola Šentviška gora', naslov: 'Šentviška Gora 31', posta: '5283', kraj: 'Slap ob Idrijci' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči, Podružnična šola Podmelec', naslov: 'Kneža 1', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'Osnovna šola Bovec, Podružnična šola Soča', naslov: 'Soča 28', posta: '5232', kraj: 'Soča' },
    { naziv: 'Osnovna šola Bovec, Podružnična šola Žaga', naslov: 'Žaga 13', posta: '5224', kraj: 'Srpenica' },
    { naziv: 'Osnovna šola Mirna Podružnica s prilagojenim programom', naslov: 'Cesta na Fužine 1', posta: '8233', kraj: 'Mirna' },
    { naziv: 'Osnovna šola Otlica, Enota Vrtec', naslov: 'Otlica 48', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola Starše, Vrtec Pikapolonica Marjeta', naslov: 'Marjeta na Dravskem polju 3 A', posta: '2206', kraj: 'Marjeta na Dravskem polju' },
    { naziv: 'Osnovna šola Trebnje, Podružnična šola Dolenja Nemška vas', naslov: 'Dolenja Nemška vas 21', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Ljudska univerza Celje', naslov: 'Cankarjeva ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Trebnje, Podružnična šola Dobrnič', naslov: 'Dobrnič 2', posta: '8211', kraj: 'Dobrnič' },
    { naziv: 'Osnovna šola Trebnje, Podružnična šola Šentlovrenc', naslov: 'Šentlovrenc 17', posta: '8212', kraj: 'Velika Loka' },
    { naziv: 'Osnovna šola Mokronog Podružnica Trebelno', naslov: 'Trebelno 43', posta: '8231', kraj: 'Trebelno' },
    { naziv: 'Osnovna šola Bistrica, Podružnična šola Kovor', naslov: 'Kriška cesta 15', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Bistrica pri Tržiču Podružnica Leše', naslov: 'Leše 51', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Šmartno pri Litiji Podružnica Javorje', naslov: 'Javorje 14', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno Podružnica Mamol', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Šmartno, Podružnica Štangarske Poljane', naslov: 'Štangarske Poljane 15', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, Podružnica Kostrevnica', naslov: 'Velika Kostrevnica 27', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Miklavž pri Ormožu Podružnica Ivanjkovci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Preska, Podružnica Topol', naslov: 'Topol pri Medvodah 17', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Velika Nedelja, Podružnica Podgorci', naslov: 'Podgorci 81', posta: '2273', kraj: 'Podgorci' },
    { naziv: 'Osnovna šola Vincenzo e Diego de Castro Piran, Podružnična šola Lucija', naslov: 'Fazan 3 A', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Osnovna šola Vincenzo de Castro Piran Podružnica Strunjan', naslov: 'Strunjan 37', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Osnovna šola Vincenzo e Diego de Castro Piran, Podružnična šola Sečovlje', naslov: 'Sečovlje 79', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Osnovna šola Lucija, Podružnična šola Strunjan', naslov: 'Strunjan 32', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Osnovna šola Cirila Kosmača Piran, Podružnična šola Portorož', naslov: 'Sončna pot 20 B', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Osnovna šola Sečovlje, Podružnična šola in vrtec Sveti Peter', naslov: 'Sv. Peter 13', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Osnovna šola Pivka Podružnična šola Šmihel', naslov: 'Šmihel 3', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Osnovna šola Pivka Podružnična šola Zagorje', naslov: 'Zagorje 9', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Osnovna šola Miroslava Vilharja Postojna Podružnica Hruševje', naslov: 'Hruševje 90', posta: '6225', kraj: 'Hruševje' },
    { naziv: 'Osnovna šola Radlje ob Dravi, Enota vrtca Radlje', naslov: 'Koroška cesta 15', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Osnovna šola Antona Globočnika, Podružnična šola Bukovje', naslov: 'Bukovje 4', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Antona Globočnika, Podružnična šola Planina', naslov: 'Planina 152', posta: '6232', kraj: 'Planina' },
    { naziv: 'Osnovna šola Antona Globočnika, Podružnična šola Studeno', naslov: 'Studeno 68', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Ljudski vrt Ptuj Podružnica Grajena', naslov: 'Grajena 60', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Videm, Podružnična šola Sela', naslov: 'Sela 33', posta: '2324', kraj: 'Lovrenc na Dravskem polju' },
    { naziv: 'Osnovna šola dr. Franja Žgeča Dornava Podružnica Polenšak', naslov: 'Polenšak 6', posta: '2257', kraj: 'Polenšak' },
    { naziv: 'Osnovna šola Martina Koresa Podlehnik Podružnica Žetale', naslov: '', posta: '', kraj: '' },
    { naziv: 'Druga osnovna šola Slovenj Gradec Podružnična osnovna šola Pameče-Troblje', naslov: 'Pameče 134', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Prva osnovna šola Slovenj Gradec, Podružnica Sele-Vrhe', naslov: 'Sele 1', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Osnovna šola Podgorje pri Slovenj Gradcu, Podružnica Šmiklavž', naslov: 'Vodriž 8', posta: '2381', kraj: 'Podgorje pri Slovenj Gradcu' },
    { naziv: 'Osnovna šola Podgorje pri Slovenj Gradcu, Podružnica Razbor', naslov: 'Zgornji Razbor 8', posta: '2381', kraj: 'Podgorje pri Slovenj Gradcu' },
    { naziv: 'Osnovna šola Pohorskega bataljona Oplotnica, Podružnična šola Kebelj', naslov: 'Kebelj 17 B', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Osnovna šola Pohorskega bataljona Oplotnica, Podružnična šola Prihova', naslov: 'Prihova 1', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Osnovna šola Antona Ingoliča Spodnja Polskava, Podružnična šola Pragersko', naslov: 'Pionirska ulica 13', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'Osnovna šola Loče, Podružnična šola Jernej', naslov: 'Sveti Jernej 40', posta: '3215', kraj: 'Loče' },
    { naziv: 'Osnovna šola Loče, Podružnična šola Žiče', naslov: 'Žiče 14', posta: '3215', kraj: 'Loče' },
    { naziv: 'Osnovna šola Zreče, Podružnična šola Gorenje', naslov: 'Gorenje pri Zrečah 19', posta: '3214', kraj: 'Zreče' },
    { naziv: 'Osnovna šola Ob Dravinji Podružnica Špitalič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Franja Malgaja Šentjur, Podružnična osnovna šola Blagovna', naslov: 'Proseniško 22', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Slivnica pri Celju, Podružnična šola Loka pri Žusmu', naslov: 'Loka pri Žusmu 12', posta: '3223', kraj: 'Loka pri Žusmu' },
    { naziv: 'Osnovna šola Slivnica pri Celju, Podružnična šola Prevorje', naslov: 'Lopaca 3', posta: '3262', kraj: 'Prevorje' },
    { naziv: 'Vrtec Litija, Enota Medvedek', naslov: 'Bevkova ulica 1', posta: '1270', kraj: 'Litija' },
    { naziv: 'VRTEC KURIRČEK LOGATEC - ENOTA TABOR', naslov: 'Gorenjska cesta 1 A', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vrtec pri Osnovni šoli', naslov: 'Dolnja Košana 61', posta: '6256', kraj: 'Košana' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško Podružnica Reka', naslov: 'Reka 32', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Marjana Nemca Radeče Podružnica Svibno', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Marjana Nemca Radeče Podružnica Zidani most', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Mislinja Podružnica Završe', naslov: 'Završe 55', posta: '2382', kraj: 'Mislinja' },
    { naziv: 'Osnovna šola Tabor I Maribor', naslov: 'Ulica Arnolda Tovornika 21', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Leona Štuklja Maribor', naslov: 'Klinetova ulica 18', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Božidarja Jakca Ljubljana', naslov: 'Nusdorferjeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Skupnost osnovnih šol Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ob Dravinji Slovenske Konjice', naslov: 'Ulica Dušana Jereba 1', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnovna šola Pod goro Slovenske Konjice', naslov: 'Šolska ulica 3', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnovna šola Loče', naslov: 'Šolska ulica 5', posta: '3215', kraj: 'Loče' },
    { naziv: 'Osnovna šola Vitanje', naslov: 'Doliška cesta 1', posta: '3205', kraj: 'Vitanje' },
    { naziv: 'Osnovna šola Zreče', naslov: 'Šolska cesta 3', posta: '3214', kraj: 'Zreče' },
    { naziv: 'Osnovna šola Ob Rinži Kočevje', naslov: 'Cesta v Mestni log 39', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Livada', naslov: 'Ulica Dušana Kraigherja 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Pragersko', naslov: 'Pionirska ulica 13', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'VRTEC SEŽANA, enota Povir', naslov: 'Povir 24', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Osnovna šola Janka Kersnika Brdo, Podružnična šola Krašnja', naslov: 'Krašnja 14 A', posta: '1225', kraj: 'Lukovica' },
    { naziv: 'Gimnazija Jurija Vege Idrija - Enota za izobraževanje odraslih', naslov: 'Študentovska ulica 16', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Izobraževalno središče Miklošič Ljubljana', naslov: 'Miklošičeva cesta 26', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za dopisno izobraževanje Univerzum', naslov: 'Grošljeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za permanentno izobraževanje Ceneta Štuparja Ljubljana', naslov: 'Vojkova cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ljudska univerza Celje', naslov: 'Cankarjeva ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Zavod sv. Frančiška Saleškega Gimnazija Želimlje', naslov: 'Želimlje 46', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Osnovna šola I Murska Sobota', naslov: 'Ulica Štefana Kovača 32', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola II Murska Sobota', naslov: 'Cankarjeva ulica 91', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola III Murska Sobota', naslov: 'Trstenjakova ulica 73', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola IV. Murska Sobota', naslov: 'Trstenjakova ulica 71', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Skupnost osnovnošolskih vzgojnoizobraževalnih zavodov M.Sobota (v kategorijo 19)', naslov: 'Trstenjakova ulica 73', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Škofijska gimnazija Vipava', naslov: 'Goriška cesta 29', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Ljudska univerza Kočevje', naslov: 'Trg zbora odposlancev 30', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Srednja frizerska šola Ljubljana', naslov: 'Litostrojska cesta 53', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Tržič', naslov: 'Šolska ulica 2', posta: '4290', kraj: 'Tržič' },
    { naziv: 'UPI Ljudska univerza Žalec', naslov: 'Ulica Ivanke Uranjek 6', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola Dob Podružnica Krtina', naslov: 'Krtina 41', posta: '1233', kraj: 'Dob' },
    { naziv: 'Osnovna šola Col Podružnica Podkraj', naslov: 'Podkraj 9', posta: '5273', kraj: 'Col' },
    { naziv: 'Osnovna šola Hudinja Podružnica Ljubečna', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Štore Podružnična šola Kompole', naslov: 'Kompole 92', posta: '3220', kraj: 'Štore' },
    { naziv: 'Osnovna šola Štore Podružnica Svetina', naslov: 'Svetina 7', posta: '3220', kraj: 'Štore' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA CENTER', naslov: 'Kocenova ulica 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA GABERJE', naslov: 'Mariborska cesta 43 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Vojnik Podružnica Socka', naslov: 'Socka 70', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'Osnovna šola Vojnik Podružnica Nova Cerkev', naslov: 'Nova Cerkev 41', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'Osnovna šola Jurija Vege, Podružnična šola Vrhpolje', naslov: 'Vrhpolje pri Moravčah 32', posta: '1251', kraj: 'Moravče' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske toplice Podružnica Lažiše    (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske toplice  Podružnica Sedraž  (spremmba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Sveta Ana, Podružnična šola Lokavec', naslov: 'Lokavec 6', posta: '2233', kraj: 'Sv. Ana v Slov. goricah' },
    { naziv: 'Dvojezična osnovna šola 1 Lendava Podružnica Čentiba', naslov: 'Ulica Sv. Urbana 1', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Dvojezična osnovna šola 1 Lendava Podružnica Dolina', naslov: 'Glavna ulica 5', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Dvojezična osnovna šola I Lendava, Podružnična šola Gaberje', naslov: 'Glavna ulica 31', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Dvojezična osnovna šola 1 Lendava Podružnica Petišovci', naslov: 'Rudarska ulica 11', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Osnovna šola Danila Lokarja Ajdovščina Podružnica Lokavec', naslov: 'Lokavec 128', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola Danila Lokarja Ajdovščina Enota s prilagojenim programom', naslov: 'Cesta 5. maja 15', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Podružnica Iga vas', naslov: 'Iga vas 1', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Osnovna šola heroja J.Hribarja Stari trg pri Ložu Podružnica Babno polje', naslov: 'Babno Polje 1', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Osnovna šola Gabrovka - Dole, Podružnična osnovna šola Dole pri Litiji', naslov: 'Dole pri Litiji 28', posta: '1273', kraj: 'Dole pri Litiji' },
    { naziv: 'Osnovna šola Dol pri Ljubljani Podružnica Šentjakob ob Savi', naslov: 'Zajčeva pot 34', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Osnovna šola Ivana Cankarja Ljutomer Podružnica Cven', naslov: 'Cven 3 C', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Metlika, Podružnična šola Suhor', naslov: 'Dolnji Suhor pri Metliki 2', posta: '8331', kraj: 'Suhor' },
    { naziv: 'Osnovna šola Blaža Arniča Luče, Podružnična šola Solčava', naslov: 'Solčava 12', posta: '3335', kraj: 'Solčava' },
    { naziv: 'Osnovna šola Mozirje Podružnica Lepa njiva', naslov: 'Lepa Njiva 87', posta: '3330', kraj: 'Mozirje' },
    { naziv: 'Osnovna šola Mozirje Podružnica Nazarje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Mozirje Podružnica Rečica ob Savinji', naslov: '', posta: '', kraj: '' },
    { naziv: 'VVZ Kekec Grosuplje, enota Pika Šmarje Sap', naslov: 'Ljubljanska cesta 51', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'VVZ Kekec Grosuplje, enota Kosobrin', naslov: 'Št. Jurij 14', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'VVZ Kekec Grosuplje, enota Mojca', naslov: 'Kersnikova cesta 2 A', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Mozirje, Podružnica Šmihel', naslov: 'Šmihel nad Mozirjem 28', posta: '3330', kraj: 'Mozirje' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji grad Podružnica Šmartno ob Dreti', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji grad, Podružnica Nova Štifta', naslov: 'Tirosek 50', posta: '3342', kraj: 'Gornji Grad' },
    { naziv: 'Osnovna šola Sladki Vrh, Podružnična šola Velka', naslov: 'Zgornja Velka 41', posta: '2213', kraj: 'Zgornja Velka' },
    { naziv: 'Osnovna šola Domžale, Podružnična šola Ihan', naslov: 'Šolska ulica 5', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Stična Podružnična šola Višnja Gora', naslov: 'Cesta na Polževo 26', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Osnovna šola Stična, Podružnična šola Ambrus', naslov: 'Ambrus 33', posta: '1303', kraj: 'Zagradec' },
    { naziv: 'Osnovna šola Stična Podružnična šola Krka', naslov: 'Krka 1 A', posta: '1301', kraj: 'Krka' },
    { naziv: 'Osnovna šola Stična Podružnična šola Muljava', naslov: 'Muljava 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Osnovna šola Stična, Podružnična šola Zagradec', naslov: 'Zagradec 33', posta: '1303', kraj: 'Zagradec' },
    { naziv: 'Osnovna šola Dobrepolje, Podružnična šola Kompolje', naslov: 'Kompolje 78', posta: '1312', kraj: 'Videm - Dobrepolje' },
    { naziv: 'Osnovna šola Dobrepolje Podružnica Ponikve', naslov: 'Ponikve 38', posta: '1312', kraj: 'Videm - Dobrepolje' },
    { naziv: 'Osnovna šola Ferda Vesela Šentvid pri Stični Podružnica Hrastov dol', naslov: 'Hrastov Dol 2', posta: '1296', kraj: 'Šentvid pri Stični' },
    { naziv: 'Osnovna šola Ferda Vesela Šentvid pri Stični Podružnica Temenica', naslov: 'Temenica 2', posta: '1296', kraj: 'Šentvid pri Stični' },
    { naziv: 'Osnovna šola dr. Aleš Bebler - Primož Hrvatini Podružnica Ankaran', naslov: 'Regentova ulica 4', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Pier Paolo Vergerio il Vecchio Koper, Podružnična šola Bertoki', naslov: 'Cesta borcev 13', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Pier Paolo Vergerio il Vecchio Koper, Podružnična šola Hrvatini', naslov: 'Hrvatini 137', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Elvire Vatovec Prade - Koper, Podružnica Sv. Anton', naslov: 'Sv. Anton 2', posta: '6276', kraj: 'Pobegi' },
    { naziv: 'Osnovna šola Leskovec pri Krškem, Podružnica Veliki Podlog', naslov: 'Veliki Podlog 14', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško Podružnica Šentrupert', naslov: 'Šentrupert 89', posta: '3271', kraj: 'Šentrupert' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško Podružnica Rečica', naslov: 'Zgornja Rečica 26', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško Podružnica Vrh', naslov: 'Vrh nad Laškim 20', posta: '3270', kraj: 'Laško' },
    { naziv: 'Vrtci Občine Moravske Toplice, enota Bogojina', naslov: 'Bogojina 134', posta: '9222', kraj: 'Bogojina' },
    { naziv: 'Vrtci Občine Moravske Toplice, enota Filovci', naslov: 'Filovci 13', posta: '9222', kraj: 'Bogojina' },
    { naziv: 'Vrtci Občine Moravske Toplice, enota Fokovci', naslov: 'Fokovci 26', posta: '9208', kraj: 'Fokovci' },
    { naziv: 'Osnovna šola prof. dr. Josipa Plemlja Bled', naslov: 'Seliška cesta 3', posta: '4260', kraj: 'Bled' },
    { naziv: 'Osnovna šola dr. Janeza Mencingerja Bohinjska Bistrica', naslov: 'Savska cesta 10', posta: '4264', kraj: 'Bohinjska Bistrica' },
    { naziv: 'Osnovna šola Prežihovega Voranca Ravne na Koroškem', naslov: 'Gozdarska pot 11', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Osnovna šola Juričevega Drejčka Ravne na Koroškem', naslov: 'Čečovje 24', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Glasbena šola Ravne na Koroškem', naslov: 'Javornik 35', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Osnovna šola Franja Goloba Prevalje', naslov: 'Polje 4', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Osnovna šola Mežica', naslov: 'Partizanska cesta 16', posta: '2392', kraj: 'Mežica' },
    { naziv: 'Osnovna šola dr. Franceta Prešerna', naslov: 'Šolska ulica 2', posta: '1310', kraj: 'Ribnica' },
    { naziv: 'Osnovna šola dr. Ivana Prijatelja Sodražica', naslov: 'Cesta Notranjskega odreda 10', posta: '1317', kraj: 'Sodražica' },
    { naziv: 'Osnovna šola dr. Antona Debeljaka Loški Potok', naslov: 'Hrib-Loški Potok 101', posta: '1318', kraj: 'Loški Potok' },
    { naziv: 'Osnovna šola Boštanj', naslov: 'Boštanj 54 A', posta: '8294', kraj: 'Boštanj' },
    { naziv: 'Osnovna šola Blanca', naslov: 'Blanca 13', posta: '8283', kraj: 'Blanca' },
    { naziv: 'Osnovna šola Sava Kladnika Sevnica', naslov: 'Trg svobode 42', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Osnovna šola Ane Gale Sevnica', naslov: 'Cesta na Dobravo 28', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Osnovna šola Tržišče', naslov: 'Tržišče 47', posta: '8295', kraj: 'Tržišče' },
    { naziv: 'Osnovna šola Šalovci Podružnica Dolenci', naslov: 'Dolenci 28', posta: '9204', kraj: 'Šalovci' },
    { naziv: 'Osnovna šola Šalovci Podružnica Markovci', naslov: 'Markovci 93', posta: '9204', kraj: 'Šalovci' },
    { naziv: 'Osnovna šola Šturje, Ajdovščina, Podružnica Budanje', naslov: 'Budanje 24', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Vrtci Občine Moravske Toplice, enota Martjanci', naslov: 'Martjanci 36 A', posta: '9221', kraj: 'Martjanci' },
    { naziv: 'Vrtci Občine Moravske Toplice, enota Moravske Toplice', naslov: 'Levstikova ulica 11', posta: '9226', kraj: 'Moravske Toplice' },
    { naziv: 'Tehniški šolski center Maribor', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Dvojezična osnovna šola Prosenjakovci Podružnica Domanjševci', naslov: 'Domanjševci 65 A', posta: '9206', kraj: 'Križevci' },
    { naziv: 'Dvojezična osnovna šola Prosenjakovci Podružnica Hodoš', naslov: 'Hodoš 53', posta: '9205', kraj: 'Hodoš - Hodos' },
    { naziv: 'Osnovna šola Puconci, Podružnična šola Mačkovci', naslov: 'Mačkovci 51 E', posta: '9202', kraj: 'Mačkovci' },
    { naziv: 'Osnovna šola Sveti Jurij, Podružnica Pertoča', naslov: 'Pertoča 54 A', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Sv. Jurij Rogašovci Podružnica Serdica', naslov: 'Serdica 82', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Bakovci, Podružnica Dokležovje', naslov: 'Glavna ulica 18', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Osnovna šola Bogojina Podružnica Tešanovci', naslov: 'Tešanovci 45', posta: '9226', kraj: 'Moravske Toplice' },
    { naziv: 'Osnovna šola Beltinci, Podružnična šola Melinci', naslov: 'Melinci 64', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Osnovna šola Tišina Podružnica Gederovci', naslov: 'Gederovci 16', posta: '9251', kraj: 'Tišina' },
    { naziv: 'Osnovna šola 8 talcev Logatec Podružnica Laze', naslov: 'Laze 57', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Osnovna šola Tabor Logatec Podružnica Hotedršica', naslov: 'Hotedršica 120', posta: '1372', kraj: 'Hotedršica' },
    { naziv: 'Osnovna šola Tabor Logatec Podružnica Rovtarske Žibrše', naslov: 'Rovtarske Žibrše 10', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Osnovna šola Žužemberk, Podružnica Ajdovec', naslov: 'Dolnji Ajdovec 15', posta: '8361', kraj: 'Dvor' },
    { naziv: 'Osnovna šola Žužemberk Podružnica Dvor', naslov: 'Dvor 23 D', posta: '8361', kraj: 'Dvor' },
    { naziv: 'Osnovna šola Žužemberk, Podružnica Šmihel', naslov: 'Šmihel pri Žužemberku 18', posta: '8360', kraj: 'Žužemberk' },
    { naziv: 'Osnovna šola Center Novo mesto, Podružnična šola Mali Slatnik', naslov: 'Mali Slatnik 7', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Šmihel, Podružnica Birčna vas', naslov: 'Birčna vas 1', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Stopiče Podružnica Dolž', naslov: 'Šolska cesta 11', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Stopiče Podružnica Podgrad', naslov: 'Podgrad 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Ljudska univerza Slovenska Bistrica', naslov: 'Partizanska ulica 22', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Kamnica', naslov: 'Vrbanska cesta 93', posta: '2351', kraj: 'Kamnica' },
    { naziv: 'Vrtci Občine Moravske Toplice, dvojezična enota Prosenjakovci', naslov: 'Prosenjakovci 35', posta: '9207', kraj: 'Prosenjakovci - Pártosfalva' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban', naslov: 'Za povrtmi 6', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Voličina, enota vrtec Voličina', naslov: 'Spodnja Voličina 82', posta: '2232', kraj: 'Voličina' },
    { naziv: 'Osnovna šola Pesnica', naslov: 'Pesnica pri Mariboru 44', posta: '2211', kraj: 'Pesnica pri Mariboru' },
    { naziv: 'Osnovna šola Rudolfa Maistra Šentilj', naslov: 'Mladinska ulica 13', posta: '2212', kraj: 'Šentilj v Slov. goricah' },
    { naziv: 'Osnovna šola Sladki Vrh', naslov: 'Sladki Vrh 8 A', posta: '2214', kraj: 'Sladki Vrh' },
    { naziv: 'Osnovna šola Jarenina', naslov: 'Jareninski Dol 26', posta: '2221', kraj: 'Jarenina' },
    { naziv: 'Osnovna šola Jakobski Dol', naslov: 'Spodnji Jakobski Dol 4', posta: '2222', kraj: 'Jakobski dol' },
    { naziv: 'Osnovna šola Janka Glazerja Ruše', naslov: 'Lesjakova ulica 4', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Osnovna šola Stražišče Kranj, Enota Vrtec v Podružnici Besnica', naslov: 'Videmce 12', posta: '4201', kraj: 'Zgornja Besnica' },
    { naziv: 'Osnovna šola Selnica ob Dravi', naslov: 'Mariborska cesta 30', posta: '2352', kraj: 'Selnica ob Dravi' },
    { naziv: 'Glasbena šola Slovenske Konjice', naslov: 'Tattenbachova ulica 1 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Glasbena šola Sevnica - Enota pri OŠ Savo Kladnik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola V parku', naslov: 'Tattenbachova ulica 5 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnova šola Ivan Cankar Vrhnika', naslov: 'Lošca 1', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Zavod za izvajanje spremljevalnih dejavnosti Žalec (v kategorijo 19)', naslov: 'Ulica Ivanke Uranjek 2', posta: '3310', kraj: 'Žalec' },
    { naziv: 'CID - Center interesnih dejavnosti', naslov: 'Kosovelova ulica 14', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna Šola Postojna', naslov: 'Trg padlih borcev 1 A', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Dobje', naslov: 'Dobje pri Planini 20 A', posta: '3224', kraj: 'Dobje pri Planini' },
    { naziv: 'Osnovna šola Antona Žnideršiča Ilirska Bistrica', naslov: 'Rozmanova ulica 25 B', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA', naslov: 'Pot v Žapuže 14', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Vrtec Tončke Čečeve Celje', naslov: 'Mariborska cesta 43', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Manka Golarja Gornja Radgona', naslov: 'Kocljeva ulica 2', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Osnovna šola Miren, Podružnica Kostanjevica', naslov: 'Kostanjevica na Krasu 66', posta: '5296', kraj: 'Kostanjevica na Krasu' },
    { naziv: 'Osnovna šola Neznanih talcev Dravograd Podružnica Črneče', naslov: 'Črneče 41', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'Osnovna šola Neznanih talcev Dravograd Podružnica Libeliče', naslov: 'Libeliče 22', posta: '2372', kraj: 'Libeliče' },
    { naziv: 'Osnovna šola Neznanih talcev Dravograd Podružnica Ojstrica', naslov: 'Ojstrica 27', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'Osnovna šola Neznanih talcev Dravograd Podružnica Trbonje', naslov: 'Trbonje 26', posta: '2371', kraj: 'Trbonje' },
    { naziv: 'Osnovna šola Gornja Radgona Podružnica Spodnja Ščavnica', naslov: 'Spodnja Ščavnica 38', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Osnovna šola Franceta Prešerna Maribor, Podružnica Stane Lenardon Razvanje', naslov: 'Razvanjska cesta 66', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Draga Kobala Maribor, Podružnična šola Brezje', naslov: 'Na trati 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Miklavž na Dravskem polju Podružnica Dobrovce', naslov: 'Šolska ulica 1', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'VRTEC IG, enota MAVRICA', naslov: 'Govekarjeva cesta 18', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Starše, Podružnica Marjeta na Dravskem polju', naslov: 'Marjeta na Dravskem polju 3', posta: '2206', kraj: 'Marjeta na Dravskem polju' },
    { naziv: 'Ljudska univerza Kranj Center za izobraževanje in kulturo', naslov: 'Cesta Staneta Žagarja 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Dušana Flisa Hoče, Podružnična šola Reka-Pohorje', naslov: 'Hočko Pohorje 1', posta: '2311', kraj: 'Hoče' },
    { naziv: 'Osnovna šola Kamnica Podružnica Bresternica', naslov: 'Pri šoli 24', posta: '2354', kraj: 'Bresternica' },
    { naziv: 'Osnovna šola Kamnica Podružnica Gaj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Duplek Podružnica Zg. Duplek', naslov: 'Zgornji Duplek 98', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Osnovna šola Duplek Podružnica Dvorjane', naslov: 'Dvorjane 15', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Osnovna šola Franca Rozmana - Staneta Maribor Podružnična šola Ivana Cankarja Košaki', naslov: 'Šentiljska cesta 41 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ljudska univerza Krško Posavski center za permanentno izobraževanje', naslov: 'Dalmatinova ulica 8', posta: '8270', kraj: 'Krško' },
    { naziv: 'Osnovna šola Božidarja Jakca Ljubljana Podružnica Hrušica', naslov: 'Pot do šole 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Sostro Podružnica Besnica', naslov: 'Besnica 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Sostro Podružnica Janče', naslov: 'Gabrje pri Jančah 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Sostro Podružnica Javor', naslov: 'Javor 5', posta: '1261', kraj: 'Ljubljana - Dobrunje' },
    { naziv: 'Osnovna šola Sostro Podružnica Lipoglav', naslov: 'Mali Lipoglav 8', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'Osnovna šola Dolenjske Toplice, Vrtec Gumbek', naslov: 'Pionirska cesta 35', posta: '8350', kraj: 'Dolenjske Toplice' },
    { naziv: 'Vrtec pri OŠ Frana Metelka Škocjan', naslov: 'Škocjan 51', posta: '8275', kraj: 'Škocjan' },
    { naziv: 'Osnovna šola Sečovlje, enota Vrtec', naslov: 'Sečovlje 78', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Vzgojni zavod Planina', naslov: 'Planina 211', posta: '6232', kraj: 'Planina' },
    { naziv: 'Center za sluh in govor Maribor', naslov: 'Vinarska ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Center za komunikacijo, sluh in govor Portorož', naslov: 'Sončna pot 14 A', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Center IRIS - Center za izobraževanje, rehabilitacijo, inkluzijo in svetovanje za slepe in slabovidne', naslov: 'Langusova ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za izobraževanje, rehabilitacijo in usposabljanje Kamnik', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana, enota Stonoga', naslov: 'Linhartova cesta 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Novo mesto, Medpodjetniški izobraževalni center', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Polhov Gradec Podružnica Črni vrh', naslov: 'Črni Vrh 34', posta: '1355', kraj: 'Polhov Gradec' },
    { naziv: 'Osnovna šola Polhov Gradec Podružnica Šentjošt', naslov: 'Šentjošt nad Horjulom 54', posta: '1354', kraj: 'Horjul' },
    { naziv: 'Osnovna šola Preserje Podružnica Jezero', naslov: 'Jezero 34', posta: '1352', kraj: 'Preserje' },
    { naziv: 'Osnovna šola Preserje Podružnica Rakitna', naslov: 'Rakitna 54', posta: '1352', kraj: 'Preserje' },
    { naziv: 'Osnovna šola Škofljica Podružnica Želimlje', naslov: 'Želimlje 43', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Osnovna šola Primoža Trubarja Velike Lašče Podružnica Karlovica', naslov: 'Karlovica 5', posta: '1315', kraj: 'Velike Lašče' },
    { naziv: 'Osnovna šola Primoža Trubarja Velike Lašče Podružnica Turjak', naslov: 'Turjak 10', posta: '1311', kraj: 'Turjak' },
    { naziv: 'Osnovna šola Brezovica pri Ljubljani, Podružnica Notranje gorice', naslov: 'Podpeška cesta 390', posta: '1357', kraj: 'Notranje Gorice' },
    { naziv: 'Osnovna šola Oskarja Kovačiča, Podružnica Rudnik', naslov: 'Rudnik I 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Vodica Podružnica Skaručna', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Vodice Podružnica Utik', naslov: 'Utik 1', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Osnovna šola Preska, Podružnica Sora', naslov: 'Sora 11', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Miroslava Vilharja, Podružnična šola s prilagojenim uč.prog.', naslov: 'Trg padlih borcev 1 A', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Videm, Podružnična šola Leskovec', naslov: 'Zgornji Leskovec 10', posta: '2285', kraj: 'Zgornji Leskovec' },
    { naziv: 'Druga osnovna šola Slovenj Gradec', naslov: 'Kopališka ulica 29', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Gimnazija Šiška', naslov: 'Aljaževa ulica 32', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Alojza Gradnika Dobrovo Podružnica Kojsko', naslov: 'Kojsko 69', posta: '5211', kraj: 'Kojsko' },
    { naziv: 'Osnovna šola dr.Anton Debeljak Podružnica Podpreska', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Veno Pilon Ajdovščina, Dijaški dom', naslov: 'Cesta 5. maja 12', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Srednja gostinska in turistična šola Izola, Dijaški dom', naslov: 'Ulica Prekomorskih brigad 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Osnovna šola Ribnica na Pohorju, Enota Vrtec Ribnica na Pohorju', naslov: 'Ribnica na Pohorju 29', posta: '2364', kraj: 'Ribnica na Pohorju' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Sonček', naslov: 'Zidanškova ulica 1 A', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Makole', naslov: 'Makole 39', posta: '2321', kraj: 'Makole' },
    { naziv: 'Miklavžev zavod Murska Sobota, Vrtec Lavra - enota Tišina', naslov: 'Tišina 2 A', posta: '9251', kraj: 'Tišina' },
    { naziv: 'Kranjski vrtci, enota', naslov: 'Ulica Nikole Tesle 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Vuzenica, Enota Vrtec Vuzenica', naslov: 'Mladinska ulica 5', posta: '2367', kraj: 'Vuzenica' },
    { naziv: 'Osnovna šola Muta, VVE Muta', naslov: 'Gortinska cesta 5', posta: '2366', kraj: 'Muta' },
    { naziv: 'Osnovna šola dr. Antona Debeljaka Loški potok, Enota Vrtec', naslov: 'Hrib-Loški Potok 101', posta: '1318', kraj: 'Loški Potok' },
    { naziv: 'Osnovna šola dr. Ivana Prijatelja Sodražica, enota Vrtec', naslov: 'Cesta Notranjskega odreda 10', posta: '1317', kraj: 'Sodražica' },
    { naziv: 'VVE pri OŠ Savo Kladnik Sevnica', naslov: 'Trg svobode 42', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'VVE pri OŠ Tržišče', naslov: 'Tržišče 35', posta: '8295', kraj: 'Tržišče' },
    { naziv: 'Osnovna šola Krmelj, Enota Vrtec', naslov: 'Krmelj 25', posta: '8296', kraj: 'Krmelj' },
    { naziv: 'Osnovna šola Železniki, Vrtec Železniki', naslov: 'Otoki 13', posta: '4228', kraj: 'Železniki' },
    { naziv: 'Osnovna šola Simona Kosa Podbrdo, Enota Vrtec', naslov: 'Podbrdo 32', posta: '5243', kraj: 'Podbrdo' },
    { naziv: 'Vrtec Zagorje ob Savi', naslov: 'Cesta 9. avgusta 111', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Osnovna šola Bizeljsko, Enota Vrtec', naslov: 'Bizeljska cesta 78', posta: '8259', kraj: 'Bizeljsko' },
    { naziv: 'VVE pri OŠ Alojza Gradnika Dobrovo v Brdih', naslov: 'Trg 25. maja 11', posta: '5212', kraj: 'Dobrovo v Brdih' },
    { naziv: 'Osnovna šola Kanal, Enota Vrtec Kanal', naslov: 'Gradnikova ulica 25', posta: '5213', kraj: 'Kanal' },
    { naziv: 'Osnovna šola Lucijana Bratkoviča Bratuša Renče, Vrtec pri OŠ Renče', naslov: 'Trg 31', posta: '5292', kraj: 'Renče' },
    { naziv: 'VVE Renče pri OŠ Lucijana Bratkoviča-Bratuša', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Solkan, enota Vrtec Solkan', naslov: 'Šolska ulica 25', posta: '5250', kraj: 'Solkan' },
    { naziv: 'Osnovna šola Ivana Roba Šempeter pri Gorici, Enota vrtec', naslov: 'Ulica Andreja Gabrščka 1', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'VVE pri OŠ Radlje ob Dravi', naslov: 'Koroška cesta 15', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Osnovna šola Milan Majcen Šentjanž, Vrtec Šentjanž', naslov: 'Šentjanž 58 A', posta: '8297', kraj: 'Šentjanž' },
    { naziv: 'Osnovna šola Žiri, Enota Vrtec', naslov: 'Jobstova cesta 22', posta: '4226', kraj: 'Žiri' },
    { naziv: 'VVE pri osnovni šoli Cirkulane', naslov: 'Cirkulane 57', posta: '2282', kraj: 'Cirkulane' },
    { naziv: 'Osnovna šola Rače, enota vrtec Rače', naslov: 'Ulica Lackove čete 17', posta: '2327', kraj: 'Rače' },
    { naziv: 'Gimnazija Litija', naslov: 'Bevkova ulica 1 C', posta: '1270', kraj: 'Litija' },
    { naziv: 'Šolski center Novo mesto, Srednja elektro šola in tehniška gimnazija', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Višja strokovna šola za gostinstvo in turizem Maribor, Medpodjetniški izobraževalni center', naslov: 'Zagrebška cesta 18', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Dante Alighieri Izola, Enota Vrtec L Aquilone', naslov: 'Ulica oktobrske revolucije 10', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'VVE pri OŠ Brezno-Podvelka', naslov: 'Podvelka 19', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'DE Sred. šole B.Kidrič Celje DU Slovenj Gradec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Bovec, Enota Vrtec', naslov: 'Mala vas 121', posta: '5230', kraj: 'Bovec' },
    { naziv: 'Vrtec Ribnica', naslov: 'Majnikova ulica 3', posta: '1310', kraj: 'Ribnica' },
    { naziv: 'Vzgojno izobraževalni zavod Vrtec Ormož', naslov: 'Dobravska ulica 13 A', posta: '2270', kraj: 'Ormož' },
    { naziv: 'JVIZ Mozirje, OE Vrtec Mozirje', naslov: 'Šolska ulica 25', posta: '3330', kraj: 'Mozirje' },
    { naziv: 'Vrtec Morje Lucija', naslov: 'Fazanska ulica 3', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Vrtec Sežana', naslov: 'Ulica Jožeta Pahorja 1', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Vrtec Mavrica Trebnje', naslov: 'Režunova ulica 8', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Vrtec La Coccinella Piran', naslov: 'Fazan 3', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Vrtec Pedenjped', naslov: 'Cerutova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVZ Ilke DevetakBignami Tolmin', naslov: 'Ulica prekomorskih brigad 1', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Vrtec Delfino Blu Koper', naslov: 'Kolarska ulica 8', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec pri Osnovni šoli Velika Dolina', naslov: 'Velika Dolina 30', posta: '8261', kraj: 'Jesenice na Dolenjskem' },
    { naziv: 'Osnovna šola Lovrenc na Pohorju', naslov: 'Šolska ulica 6', posta: '2344', kraj: 'Lovrenc na Pohorju' },
    { naziv: 'VVZ Jožef Maslo Ilirska Bistrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Predoslje, enota Vrtec', naslov: 'Predoslje 17 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Prežihovega Voranca Jesenice', naslov: 'Cesta Toneta Tomšiča 5', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Osnovna šola Josipa Vandota Kranjska Gora', naslov: 'Koroška ulica 12', posta: '4280', kraj: 'Kranjska Gora' },
    { naziv: 'Osnovna šola 16. decembra Mojstrana', naslov: 'Ulica Alojza Rabiča 7', posta: '4281', kraj: 'Mojstrana' },
    { naziv: 'Vrtec Sežana, enota Materija', naslov: 'Bač pri Materiji 28 A', posta: '6242', kraj: 'Materija' },
    { naziv: 'Vrtec Ravne na Koroškem, ENOTA AJDA', naslov: 'Javornik 59', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor', naslov: 'Cesta XIV. divizije 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Šentjanž pri Dravogradu', naslov: 'Šentjanž pri Dravogradu 88', posta: '2373', kraj: 'Šentjanž pri Dravogradu' },
    { naziv: 'Osnovna šola Dobrepolje, Podružnična šola Struge', naslov: 'Lipa 16', posta: '1313', kraj: 'Struge' },
    { naziv: 'Osnovna šola Rovte Podružnica Vrh Svetih treh kraljev', naslov: 'Vrh Sv. Treh Kraljev 19', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Osnovna šola Gorica Velenje, Podružnična šola Vinska Gora', naslov: 'Vinska Gora 31', posta: '3320', kraj: 'Velenje' },
    { naziv: 'VIZ II. OŠ Rogaška Slatina, Podružnična šola Sveti Florijan', naslov: 'Sv. Florijan 60', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Osnovna šola Hruševec Šentjur', naslov: 'Gajstova pot 2 A', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Tončke Čeč Trbovlje Enota s prilagojenim programom', naslov: 'Keršičeva cesta 50', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Trbovlje Podružnična šola Alojza Hohkrauta', naslov: 'Trg revolucije 19', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Center za usposabljanje Elvira Vatovec Strunjan, Enota Divača', naslov: 'Kraška cesta 65', posta: '6215', kraj: 'Divača' },
    { naziv: 'VVE pri OŠ Kozje VRTEC ZMAJČEK', naslov: 'Kozje 127 A', posta: '3260', kraj: 'Kozje' },
    { naziv: 'Osnovna šola Podčetrtek, Vrtec Podčetrtek', naslov: 'Trška cesta 66', posta: '3254', kraj: 'Podčetrtek' },
    { naziv: 'VIZ Osnovna šola Rogatec, Enota Vrtec Rogatec', naslov: 'Strmolska ulica 7', posta: '3252', kraj: 'Rogatec' },
    { naziv: 'Osnovna šola Bistrica ob Sotli, Enota Vrtec Pikapolonica', naslov: 'Bistrica ob Sotli 63 A', posta: '3256', kraj: 'Bistrica ob Sotli' },
    { naziv: 'Škofijska gimnazija Vipava, Dijaški dom (zasebni zavod)', naslov: 'Grabrijanova ulica 19', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Zavod sv. Frančiška Saleškega, Dijaški dom Janeza Boska (zasebni zavod)', naslov: 'Želimlje 46', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Zavod Sv.Stanislava, Jegličev dijaški dom (zasebni zavod)', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji Grad, Enota Vrtec Gornji Grad', naslov: 'Kocbekova cesta 21', posta: '3342', kraj: 'Gornji Grad' },
    { naziv: 'Osnovna šola Janka Kersnika Brdo, VVE Medo', naslov: 'Zaboršt 2', posta: '1225', kraj: 'Lukovica' },
    { naziv: 'Srednja gostinska in turistična šola Radovljica', naslov: 'Kranjska cesta 24', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Ekonomska gimnazija in srednja šola Radovljica', naslov: 'Gorenjska cesta 13', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Muta Podružnica Pernice', naslov: 'Pernice 24', posta: '2366', kraj: 'Muta' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Kmetijska šola Grm in biotehniška gimnazija', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Srednja šola Josipa Jurčiča Ivančna Gorica', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Srednja šola Josip Jurčič Ivančna Gorica Sr. strokovna in poklicna šola', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Šolski center Velenje, Šola za rudarstvo in varstvo okolja', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Šolski center Velenje, Strojna šola', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Šolski center Velenje, Šola za storitvene dejavnosti', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Šolski center Celje, Srednja šola za strojništvo, mehatroniko in medije', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Celje, Srednja šola za kemijo, elektrotehniko in računalništvo', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Celje, Srednja šola za gradbeništvo in varovanje okolja', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Slovenj Gradec, Gimnazija', naslov: 'Gosposvetska cesta 2', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Šolski center Slovenj Gradec, Srednja zdravstvena šola', naslov: 'Gosposvetska cesta 2', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Šolski center Slovenj Gradec, Srednja gostinsko turistična in lesarska šola', naslov: 'Gosposvetska cesta 4', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Šolski center Nova Gorica, Gimnazija in zdravstvena šola', naslov: 'Erjavčeva ulica 4 A', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Radenci-Radenski mehurčki', naslov: 'Titova cesta 1', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Vrtec Ljubljana-Center', naslov: 'Poljanska cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Mojca Ljubljana', naslov: 'Levičnikova ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Radovljica', naslov: 'Kranjska cesta 13', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Miklavžev vrtec - župnijski vrtec', naslov: 'Šolska pot 1', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vrtec Vrhnika, enota Rosika', naslov: 'Bevke 16', posta: '1358', kraj: 'Log pri Brezovici' },
    { naziv: 'Osnovna šola Jožeta Hudalesa Jurovski Dol, Enota Vrtec', naslov: 'Jurovski Dol 13', posta: '2223', kraj: 'Jurovski Dol' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA PAMEČE', naslov: 'Pameče 59', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Vrtec Vodmat, enota KLINIČNI CENTER', naslov: 'Bohoričeva ulica 36', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Danile Kumar Ljubljana, mednarodna šola', naslov: 'Gogalova ulica 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod za izobraževanje in kulturo Litija, Ljudska univerza', naslov: 'Trg na Stavbah 8 A', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Stična, Podružnična šola Stična', naslov: 'Stična 24', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Osnovna šola Trbovlje', naslov: 'Mestni trg 6', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Ivana Cankarja Trbovlje', naslov: 'Trg Franca Fakina 8', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Tončke Čeč Trbovlje', naslov: 'Keršičeva cesta 50', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Svetovalni center za otroke, mladostnike in starše Maribor', naslov: 'Lavričeva ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ljudska univerza, Zavod za izobraževanje in kulturo, Rogaška Slatina', naslov: 'Celjska cesta 3 A', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'VVE pri OŠ Globoko', naslov: 'Globoko 9', posta: '8254', kraj: 'Globoko' },
    { naziv: 'Osnovna šola dr. Jožeta Toporišiča Dobova,  Vrtec Najdihojca', naslov: 'Kapelska cesta 24', posta: '8257', kraj: 'Dobova' },
    { naziv: 'Osnovna šola Žužemberk, Enota Vrtec', naslov: 'Baragova cesta 1', posta: '8360', kraj: 'Žužemberk' },
    { naziv: 'Osnovna šola dr. Pavla Lunačka Šentrupert, Vrtec Čebelica', naslov: 'Šentrupert 57', posta: '8232', kraj: 'Šentrupert' },
    { naziv: 'Osnovna šola Rudolfa Ukoviča Podgrad, Enota Vrtec', naslov: 'Podgrad 99 B', posta: '6244', kraj: 'Podgrad' },
    { naziv: 'Osnovna šola Žirovnica', naslov: 'Zabreznica 4', posta: '4274', kraj: 'Žirovnica' },
    { naziv: 'Vrtec pri VIZ v Veržeju', naslov: 'Puščenjakova ulica 1', posta: '9241', kraj: 'Veržej' },
    { naziv: 'JVIZ Vrtec Idrija', naslov: 'Arkova ulica 7', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Osnovna šola Jožeta Gorjupa Kostanjevica na Krki, enota Vrtec', naslov: 'Gorjanska cesta 2', posta: '8311', kraj: 'Kostanjevica na Krki' },
    { naziv: 'Osnovna šola Ivanjkovci', naslov: 'Ivanjkovci 71 A', posta: '2259', kraj: 'Ivanjkovci' },
    { naziv: 'Osnovna šola Rovte', naslov: 'Rovte 90 A', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Ljudska univerza Lenart', naslov: 'Nikova ulica 9', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'Vrtec Hrastnik', naslov: 'Novi log 11 A', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'VVZ Štefke Majnik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Antona Medveda Kamnik', naslov: 'Novi trg 26 B', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'VVE pri PŠ Trnovska vas', naslov: 'Trnovska vas 38 A', posta: '2254', kraj: 'Trnovska vas' },
    { naziv: 'Vrtec Jožice Flander, OE Fochova', naslov: 'Focheva ulica 51', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Semedela', naslov: 'Nova ulica 2 B', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'VRTEC LJUTOMER', naslov: 'Fulneška ulica 5', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Vrtec Jožice Flander', naslov: 'Focheva ulica 51', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC TEZNO- UPRAVA , ENOTA MIŠMAŠ', naslov: 'Dogoška cesta 20', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Murska Sobota', naslov: 'Talanyijeva ulica 6', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC', naslov: 'Prežihova ulica 2', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana', naslov: 'Parmska cesta 41', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Kočevje', naslov: 'Cesta na stadion 3', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Vrtec Ciciban Sevnica', naslov: 'Naselje heroja Maroka 22', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA MAISTROVA, oddelek Sele', naslov: 'Sele 1', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Vrtec Tržič', naslov: 'Cesta Ste Marie aux Mines 28', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Vrtec Ciciban Novo mesto', naslov: 'Ragovska ulica 18', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Nova Gorica', naslov: 'Kidričeva ulica 34 C', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Domžale', naslov: 'Ljubljanska cesta 70', posta: '1230', kraj: 'Domžale' },
    { naziv: 'VVZ Kekec Grosuplje', naslov: 'Trubarjeva cesta 15', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Trbovlje', naslov: 'Rudarska cesta 10 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Hansa Christiana Andersena', naslov: 'Rašiška ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Šentvid', naslov: 'Ulica pregnancev 6', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'VVZ Vančka Šarha Maribor', naslov: 'Smoletova ulica 7', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Šoštanj', naslov: 'Kajuhova cesta 8', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'VVE pri OŠ Jurija Vege', naslov: 'Vegova ulica 38', posta: '1251', kraj: 'Moravče' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči, Enota Vrtec', naslov: 'Most na Soči 21 A', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'Glasbena šola Frana Koruna - Koželjskega Velenje', naslov: 'Jenkova cesta 4', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Glasbena šola Celje (po koncesiji)', naslov: 'Slomškov trg 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'Ljudska univerza Sežana', naslov: 'Bazoviška cesta 9', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Vrtec Rogašovci, enota', naslov: 'Rogašovci 16 B', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Zavod za izobraževanje in kulturo Črnomelj', naslov: 'Ulica Otona Župančiča 1', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Ljudska univerza Koper', naslov: 'Cankarjeva ulica 33', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Ljudska univerza Kamnik', naslov: 'Novi trg 41 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Ljudska univerza  Kranj', naslov: 'Cesta Staneta Žagarja 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Ljudska univerza Krško', naslov: 'Dalmatinova ulica 8', posta: '8270', kraj: 'Krško' },
    { naziv: 'Ljudska univerza Jesenice', naslov: 'Delavska ulica 1', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Vrtec Nova Gorica, Enota Čriček', naslov: 'Ulica Vinka Vodopivca 23', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Radovljica, Enota Begunje', naslov: 'Begunje na Gorenjskem 25', posta: '4275', kraj: 'Begunje na Gorenjskem' },
    { naziv: 'Osnovna šola pri Ortopedski bolnici Valdoltra', naslov: 'Jadranska cesta 31', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Center za izobraževanje, rehabilitacijo in usposabljanje Vipava', naslov: 'Vojkova ulica 33', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Mladinski dom Maribor', naslov: 'Ulica Saše Deva 21', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Višja tehniška varnostna šola Ljubljana', naslov: 'Gorazdova ulica 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Univerza v Ljubljani', naslov: 'Kongresni trg 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center šolskih in obšolskih dejavnosti', naslov: 'Frankopanska ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Delavska univerza Radlje ob Dravi', naslov: 'Koroška cesta 2', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Ljudska univerza Ravne na Koroškem', naslov: 'Gačnikova pot 3', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Ljudska univerza Lendava', naslov: 'Kidričeva ulica 1', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Ljudska univerza Murska Sobota, Zavod za permanentno izobraževanje', naslov: 'Slomškova ulica 33', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Zasavska ljudska univerza', naslov: 'Trg svobode 11 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Razvojno izobraževalni center Novo mesto', naslov: 'Topliška cesta 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Zavod sv. Stanislava, Škofijska klasična gimnazija', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'RIRO d.o.o. Srednja šola tekstilne usmeritve (po koncesiji)', naslov: 'Hrastovec 26', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Osnovna šola Stara Cerkev, Podružnična šola Željne', naslov: 'Željne 21', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Ob Rinži Kočevje Podružnica Kočevska Reka', naslov: 'Kočevska Reka 6', posta: '1338', kraj: 'Kočevska Reka' },
    { naziv: 'Osnovna šola Ob Rinži Kočevje Podružnica Livold', naslov: 'Livold 7', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Simona Jenka Kranj Podružnica Primskovo', naslov: 'Zadružna ulica 11', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Simona Jenka Kranj Podružnica Center', naslov: 'Komenskega ulica 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Simona Jenka Kranj Podružnica Goriče', naslov: 'Srednja vas - Goriče 1', posta: '4204', kraj: 'Golnik' },
    { naziv: 'Osnovna šola Staneta Žagarja Kranj Podružnica Trboje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Veržej - Zav', naslov: 'Puščenjakova ulica 7', posta: '9241', kraj: 'Veržej' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana, enota Vetrnica', naslov: 'Črtomirova ulica 14', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, enota Ciciban Šentvid', naslov: 'Šentvid pri Grobelnem 12', posta: '3231', kraj: 'Grobelno' },
    { naziv: 'Srednja kmetijska šola Rakičan, Dijaški dom', naslov: 'Lendavska ulica 3', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Dom Kmetijske šole Grm Novo mesto', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Srednja gozdarska in lesarska šola Postojna, Dijaški dom', naslov: 'Tržaška cesta 36', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Dijaški dom Srednje šole za gostinstvo in turizem Radenci', naslov: 'Mladinska ulica 5', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Dijaški dom Šolskega centra Rogaška Slatina', naslov: 'Steklarska ulica 1', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Srednja šola Sevnica, Dijaški dom', naslov: 'Savska cesta 2', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Šolski center Velenje, Dijaški dom', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Livade - Izola', naslov: 'Livade 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Gimnazija Jesenice', naslov: 'Trg Toneta Čufarja 1', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'JVIZ Vrtec Otona Župančiča', naslov: 'Oblakova ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JVVZ Vrtec Laško', naslov: 'Cesta na Svetino 2 A', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Lovrenc na Pohorju, Vrtec Lovrenc na Pohorju', naslov: 'Šolska ulica 6', posta: '2344', kraj: 'Lovrenc na Pohorju' },
    { naziv: 'Vrtec Postojna', naslov: 'Cesta na Kremenco 4', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Vrtec Studenci Maribor', naslov: 'Groharjeva ulica 22', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC DRAVOGRAD', naslov: 'Trg 4. julija 65', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'Vrtec Zarja Celje', naslov: 'Zagajškova ulica 8', posta: '3000', kraj: 'Celje' },
    { naziv: 'VIZ VRTEC "MAVRICA" IZOLA', naslov: 'Ulica Osvobodilne fronte 15', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Vrtec Koper', naslov: 'Kettejeva ulica 13', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Javni zavod Cene Štupar - Center za izobraževanje Ljubljana', naslov: 'Šmartinska cesta 134 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Agencija Tolminska-Perovič Tolmin (po koncesiji)', naslov: 'Gradnikova ulica 24', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Vrtec Jožice Flander, OE VANČKA ŠARHA', naslov: 'Smoletova ulica 7', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Radovljica, Enota Radovljica', naslov: 'Kopališka cesta 12', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'VRTEC IG, enota SONČEK', naslov: 'Tomišelj 30', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor, Podružnica Kokra', naslov: 'Kokra 23', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor Podružnica Jezersko', naslov: 'Zgornje Jezersko 62', posta: '4206', kraj: 'Zgornje Jezersko' },
    { naziv: 'Osnovna šola Šenčur Podružnica Voklo', naslov: 'Voklo 7', posta: '4208', kraj: 'Šenčur' },
    { naziv: 'Glasbena šola Radlje ob Dravi', naslov: 'Koroška cesta 13', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Glasbena šola Rogaška Slatina', naslov: 'Kidričeva ulica 26', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Glasbena šola Tolmin', naslov: 'Prešernova ulica 4', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Osnovna šola Cvetka Golarja Ljutomer', naslov: 'Cvetka Golarja ulica 6', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Stražišče Kranj Podružnica Besnica', naslov: 'Videmce 12', posta: '4201', kraj: 'Zgornja Besnica' },
    { naziv: 'Osnovna šola Lucijana Seljaka Podružnica Mavčiče', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Lucijana Seljaka Podružnica Orehek', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Stražišče Kranj Podružnica Podblica', naslov: 'Podblica 3', posta: '4201', kraj: 'Zgornja Besnica' },
    { naziv: 'Osnovna šola Stražišče Kranj Podružnica Žabnica', naslov: 'Žabnica 20', posta: '4209', kraj: 'Žabnica' },
    { naziv: 'Osnovna šola Franceta Prešerna Kranj, Podružnica Kokrica', naslov: 'Cesta na Brdo 45 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Waldorfska šola Ljubljana', naslov: 'Streliška ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za izobraževanje in kulturo Trebnje', naslov: 'Kidričeva ulica 2', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'VVE pri OŠ Simona Gregorčiča Kobarid', naslov: 'Gregorčičeva ulica 18 A', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Srednja šola Krško Dijaški dom', naslov: 'Hočevarjev trg 1', posta: '8270', kraj: 'Krško' },
    { naziv: 'Srednja elektro-računalniška šola Maribor Dijaški dom', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja šola kmetijske mehanizacije Maribor, Dom Svečina - Zg. Kungota', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Studio Gizela, Grah Gizela s.p.', naslov: 'Ulica Nikole Tesla 5', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Srednja ekonomska šola Ljubljana - zasebna šola', naslov: 'Tržaška cesta 42', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Bežigrad, Mednarodna šola', naslov: 'Peričeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Koper, Enota Šalara-Polžek', naslov: 'Vanganelska cesta 87', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'VRTEC V OŠ JAKOBA ALJAŽA', naslov: 'Ulica Tončka Dežmana 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC V OŠ MATIJA ČOP', naslov: 'Ulica Tuga Vidmarja 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Frana Albrehta Kamnik Podružnica Vranja peč', naslov: 'Vranja Peč 3', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Kanal Podružnica Levpa', naslov: 'Levpa 25', posta: '5214', kraj: 'Kal nad Kanalom' },
    { naziv: 'Osnovna šola Kanal Podružnica Kal', naslov: 'Kal nad Kanalom 119', posta: '5214', kraj: 'Kal nad Kanalom' },
    { naziv: 'Osnovna šola Kanal Podružnica Ročinj', naslov: 'Ročinj 47', posta: '5215', kraj: 'Ročinj' },
    { naziv: 'Osnovna šola Ivana Roba Šempeter pri Gorici Podružnica Vogrsko', naslov: 'Vogrsko 99', posta: '5293', kraj: 'Volčja Draga' },
    { naziv: 'Osnovna šola Ivana Roba Šempeter pri Gorici Podružnica Vrtojba', naslov: 'Ulica 9. septembra 132 A', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'Osnovna šola Lucijana Bratkoviča Bratuša Renče, Podružnična osnovna šola Bukovica', naslov: 'Bukovica 44', posta: '5293', kraj: 'Volčja Draga' },
    { naziv: 'Osnovna šola Solkan, Podružnica Trnovo', naslov: 'Trnovo 41', posta: '5252', kraj: 'Trnovo pri Gorici' },
    { naziv: 'Osnovna šola Solkan, Podružnica Grgar', naslov: 'Grgar 45', posta: '5251', kraj: 'Grgar' },
    { naziv: 'Osnovna šola Dornberk Podružnica Prvačina', naslov: 'Prvačina 204', posta: '5297', kraj: 'Prvačina' },
    { naziv: 'Osnovna šola Petrovče Podružnica Galicija', naslov: 'Galicija 5', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola Cirkulane - Zavrč, Podružnica Zavrč', naslov: 'Zavrč 14', posta: '2283', kraj: 'Zavrč' },
    { naziv: 'Osnovna šola Kidričevo, Podružnica Lovrenc na Dravskem polju', naslov: 'Lovrenc na Dravskem polju 8 A', posta: '2324', kraj: 'Lovrenc na Dravskem polju' },
    { naziv: 'Osnovna šola Majšperk Podružnica Ptujska gora', naslov: 'Ptujska Gora 74', posta: '2323', kraj: 'Ptujska gora' },
    { naziv: 'Osnovna šola Majšperk Podružnica Stoperce', naslov: 'Stoperce 12', posta: '2289', kraj: 'Stoperce' },
    { naziv: 'Osnovna šola Vuzenica Podružnica Primož na Pohorju', naslov: 'Sv. Primož na Pohorju 22', posta: '2367', kraj: 'Vuzenica' },
    { naziv: 'Osnovna šola Brezno - Podvelka Podružnica Lehen na Pohorju', naslov: 'Lehen na Pohorju 49', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Brezno - Podvelka Podružnica Ožbalt ob Dravi', naslov: 'Ožbalt 10', posta: '2361', kraj: 'Ožbalt' },
    { naziv: 'Osnovna šola Brezno - Podvelka Podružnica Kapla na Kozjaku', naslov: 'Spodnja Kapla 2', posta: '2362', kraj: 'Kapla' },
    { naziv: 'Osnovna šola Brezno, Podvelka Podružnica Remšnik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Radlje ob Dravi Podružnica Vuhred', naslov: 'Vuhred 148', posta: '2365', kraj: 'Vuhred' },
    { naziv: 'Osnovna šola Radlje Podružnica Brezovec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Radovljica, Enota Posavec', naslov: 'Posavec 86', posta: '4244', kraj: 'Podnart' },
    { naziv: 'Vrtec Radovljica, Enota Brezje', naslov: 'Brezje 80 C', posta: '4243', kraj: 'Brezje' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota MARJETICA', naslov: 'Ragovska ulica 18', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Koroški jeklarji Ravne na Koroškem, Podružnična šola Kotlje', naslov: 'Kotlje 7', posta: '2394', kraj: 'Kotlje' },
    { naziv: 'Osnovna šola Črna na Koroškem Podružnica Javorje', naslov: 'Javorje 27', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'Osnovna šola Črna na Koroškem Podružnica Koprivna', naslov: 'Koprivna 10', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'Osnovna šola Črna na Koroškem Podružnica Žerjav', naslov: 'Žerjav 24', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'Osnovna šola Franja Goloba Prevalje, Podružnična šola Leše', naslov: 'Leše 30', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Osnovna šola II Murska Sobota, Podružnična šola Krog', naslov: 'Trubarjeva ulica 77', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'VIZ II.OŠ Rogaška Slatina, Podružnična šola Kostrivnica', naslov: 'Zgornja Kostrivnica 31', posta: '3241', kraj: 'Podplat' },
    { naziv: 'VIZ Osnovna šola Rogatec Podružnična šola Dobovec', naslov: 'Dobovec pri Rogatcu 35 C', posta: '3252', kraj: 'Rogatec' },
    { naziv: 'VIZ Osnovna šola Rogatec Podružnična šola Donačka gora', naslov: 'Donačka Gora 1', posta: '3252', kraj: 'Rogatec' },
    { naziv: 'Osnovna šola Podčetrtek, Podružnična osnovna šola Pristava pri Mestinju', naslov: 'Pristava pri Mestinju 27', posta: '3253', kraj: 'Pristava pri Mestinju' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Zibika', naslov: 'Zibika 5', posta: '3253', kraj: 'Pristava pri Mestinju' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Šentvid', naslov: 'Šentvid pri Grobelnem 12', posta: '3231', kraj: 'Grobelno' },
    { naziv: 'Center za permanentno izobraževanje Cene Štupar Ljubljana', naslov: 'Vojkova cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Piran', naslov: 'Bolniška ulica 11', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'JVVZ Vrtec Lenart', naslov: 'Gubčeva ulica 3', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'Vrtec Litija', naslov: 'Bevkova ulica 1', posta: '1270', kraj: 'Litija' },
    { naziv: 'Vrtec Črnuče', naslov: 'Dunajska cesta 400', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'VRTEC JELKA', naslov: 'Glavarjeva ulica 18 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana', naslov: 'Črtomirova ulica 14', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ciciban', naslov: 'Šarhova ulica 29', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Jarše', naslov: 'Rožičeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Najdihojca', naslov: 'Gorazdova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VRTEC KURIRČEK LOGATEC', naslov: 'Notranjska cesta 7 A', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Biotehniški center Naklo, Medpodjetniški izobraževalni center', naslov: 'Strahinj 99', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Vrtec Ciciban Novo mesto, enota PALČEK - bolnišnični oddelek', naslov: 'Šmihelska cesta 1', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Borisa Pečeta', naslov: 'Tomšičeva ulica 32', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Koroška vrata', naslov: 'Gasilska ulica 11', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VVZ Pohorski bataljon', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Jadvige Golež', naslov: 'Betnavska cesta 100', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ravne na Koroškem', naslov: 'Čečovje 12 A', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica', naslov: 'Zidanškova ulica 1 A', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Vrtec Slovenske Konjice', naslov: 'Usnjarska cesta 12', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Ptuj', naslov: 'Puhova ulica 6', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Škofja Loka', naslov: 'Podlubnik 1 D', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Velenje', naslov: 'Šlandrova cesta 11 A', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Vrhnika', naslov: 'Tržaška cesta 2 A', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Osnovna šola Podbočje, Enota Vrtec', naslov: 'Podbočje 82', posta: '8312', kraj: 'Podbočje' },
    { naziv: 'Vrtec pri OŠ Vavta vas', naslov: 'Vavta vas 1', posta: '8351', kraj: 'Straža pri Novem mestu' },
    { naziv: 'Osnovna šola Braslovče, Podružnična osnovna šola Trnava', naslov: 'Trnava 5 B', posta: '3303', kraj: 'Gomilsko' },
    { naziv: 'Osnovna šola Šenčur Podružnica Trboje', naslov: 'Trboje 116', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Puconci, Podružnična šola Bodonci', naslov: 'Bodonci 5', posta: '9265', kraj: 'Bodonci' },
    { naziv: 'Osnovna šola Naklo, Podružnična šola Duplje, Enota Vrtec', naslov: 'Spodnje Duplje 2', posta: '4203', kraj: 'Duplje' },
    { naziv: 'Osnovna šola Košana, enota Vrtec Košana', naslov: 'Dolnja Košana 61', posta: '6256', kraj: 'Košana' },
    { naziv: 'VVE pri OŠ Prestranek', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Ekonomsko administrativnega šolskega centra Kranj Sred.šola za gost.in tur.', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr. šole za gost.in tur.Lj. Srednja šola za elektrotehniko in gostinstvo Zagorje ob Savi', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr.š.za farm.in zdrav. Lj. Srednja šola tehniških in zdravstvene usmeritve N.mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje gradbene in narav. šole Veno Pilon Ajdovščina Sr.družb.in ek. šola S.Kosovel', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE ISKRA Srednje šole Kranj Center srednjega usmerjenega izobraževanja Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kemijske šole Ljubljana Naravoslov. SŠ center Nova g.', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kemijske šole Lj. Srednja tehniška in zdravstvena šola Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kemijske šole Ljubljana Gimnazija Koper', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sred.agroživilska šola Maribor TOZD Kmetijska mehanizacija DE pri ZŠAM Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sred.agroživilska šola Maribor TOZD Kmetijska mehanizacija DE pri DU Gornja Radgona', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr. živilske šole Maribor Srednja kmetijska šola Rakičan', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred.agroživil.šole Maribor TOZD Živilska šola Sred.gradbena šola V.Pilon', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje živilske šole Maribor Narav.sredšol.center N.Gorica', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kmetijske šole Maribor Srednja kovinarska šola Muta', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole za gostinstvo in turizem Celje SŠ EK Eko.zdr.dru.in gost.usm.', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje lesarske šole Lj. Srednja tehniška in naravoslovna šola Postojna', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje družboslovne ekonomske šole Nova Gorica Enota pri ETA Cerkno', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Radovljica, Enota Kamna Gorica', naslov: 'Kamna Gorica 61', posta: '4246', kraj: 'Kamna Gorica' },
    { naziv: 'Srednja šola zdravstvene usmeritve Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Ljubljana Srednja strojna šola Trbovlje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Ljubljana Revirska ljudska univerza', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Rudolfa Maistra Kamnik, Srednja ekonomska šola', naslov: 'Novi trg 41 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Zavod Antona Martina Slomška, Škofijska gimnazija Antona Martina Slomška', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gimnazija Jožeta Plečnika Ljubljana', naslov: 'Šubičeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šola za hortikulturo in vizualne umetnosti Celje', naslov: 'Ljubljanska cesta 97', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Brinje Grosuplje', naslov: 'Ljubljanska cesta 40 A', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Brinje Grosuplje, Podružnica Polica', naslov: 'Polica 200', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Brinje Grosuplje, Podružnična osnovna šola s prilagojenim programom', naslov: 'Ljubljanska cesta 40 A', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Peter pan d.o.o., Zasebni vrtec', naslov: 'Cankarjeva cesta 12 B', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Naklo, OE Vrtec, Vrtec Mlinček', naslov: 'Krakovo 73', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Osnovna šola Naklo', naslov: 'Glavna cesta 47', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Osnovna šola Naklo, Podružnična šola Duplje', naslov: 'Spodnje Duplje 2', posta: '4203', kraj: 'Duplje' },
    { naziv: 'Osnovna šola Naklo, Podružnična šola Podbrezje', naslov: 'Podbrezje 120', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Osnovna šola Poljane', naslov: 'Poljane nad Škofjo Loko 100', posta: '4223', kraj: 'Poljane nad Škofjo Loko' },
    { naziv: 'Osnovna šola Poljane, Podružnična šola Javorje', naslov: 'Javorje 6', posta: '4223', kraj: 'Poljane nad Škofjo Loko' },
    { naziv: 'Srednja šola za gostinstvo in turizem Maribor', naslov: 'Mladinska ulica 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC IG', naslov: 'Cesta na Kurešček 70', posta: '1292', kraj: 'Ig' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA', naslov: 'Cesta občine Hirschaid 6', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah', naslov: 'Slomškova ulica 13', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Osnovna šola Danile Kumar, International Kindergarten', naslov: 'Gogalova ulica 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VRTEC LJUTOMER, VRTEC GRESOVŠČAK', naslov: 'Gresovščak 24', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'DE ŠC Vojvodina Tolmin Srednja šola pedag.in nar.-mat. usm. Srednja šola Jurija Vege', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Teh. ŠC B.B. Nova Gorica Šolski center Vojvodina Tolmin', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole tehničnih strok in osebnih storitev Lj. DU Trbovlje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole tehničnih strok in osebnih storitev Ljubljana Ljudska univerza Celje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole tehničnih strok in osebnih storitev Ljubljana DU Litija', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Gradb.sr.šole. B.K. Maribor Srednješolski center tehniško pedagoške usmeritve M. Sobota', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj DU Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Poljane, Vrtec Agata', naslov: 'Poljane nad Škofjo Loko 100', posta: '4223', kraj: 'Poljane nad Škofjo Loko' },
    { naziv: 'Osnovna šola Fram, Enota Vrtec Fram', naslov: 'Mlinska ulica 8', posta: '2313', kraj: 'Fram' },
    { naziv: 'Osnovna šola Sv. Jurij ob Ščavnici, Enota Vrtec Sonček', naslov: 'Ulica Edvarda Kocbeka 4', posta: '9244', kraj: 'Sveti Jurij ob Ščavnici' },
    { naziv: 'Vrtec Mengeš', naslov: 'Šolska ulica 12', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'Osnovna šola dr.Janeza Mencingerja Bohinjska Bistrica, Enota Vrtec', naslov: 'Mencingerjeva ulica 4', posta: '4264', kraj: 'Bohinjska Bistrica' },
    { naziv: 'OŠ Destrnik - Trnovska vas, Vrtec pri OŠ Destrnik', naslov: 'Janežovski Vrh 45', posta: '2253', kraj: 'Destrnik' },
    { naziv: 'Osnovna šola Destrnik Podružnica Vitomarci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Livada Velenje, Podružnična šola Cirkovce', naslov: 'Škalske Cirkovce 11', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Livada Velenje, Podružnična šola Škale', naslov: 'Škale 85 C', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Šenčur Podružnica Olševek', naslov: 'Olševek 59', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'Andragoški zavod Ljudska univerza Velenje', naslov: 'Titov trg 2', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola dr. Franja Žgeča Dornava, Enota Vrtec', naslov: 'Dornava 136 B', posta: '2252', kraj: 'Dornava' },
    { naziv: 'VVE pri OŠ Martina Koresa Podlehnik', naslov: 'Dolena 7 A', posta: '2323', kraj: 'Ptujska gora' },
    { naziv: 'Osnovna šola Ljubečna', naslov: 'Kocbekova cesta 40 A', posta: '3202', kraj: 'Ljubečna' },
    { naziv: 'Javni zavod Osnovna šola Marjana Nemca Radeče, Podružnična šola Svibno', naslov: 'Svibno 18', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Osnovna šola Koroška Bela Jesenice', naslov: 'Cesta talcev 2', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Osnovna šola Toneta Čufarja Jesenice', naslov: 'Cesta Cirila Tavčarja 21', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Zavod sv. Stanislava', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'MARN DARINKA - ZASEBNA VZGOJITELJICA', naslov: 'Ljubljanska cesta 54 A', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'Osnovna šola Litija', naslov: 'Ulica Mire Pregljeve 3', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Litija, POŠ s prilagojenim programom', naslov: 'Cesta komandanta Staneta 2', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Litija, Podružnična osnovna šola Sava', naslov: 'Sava 21', posta: '1282', kraj: 'Sava' },
    { naziv: 'Osnovna šola Litija, Podružnična osnovna šola Darinke Ribič Polšnik', naslov: 'Polšnik 23', posta: '1272', kraj: 'Polšnik' },
    { naziv: 'Osnovna šola Litija Podružnica Konjšica', naslov: 'Konjšica - del 4', posta: '1272', kraj: 'Polšnik' },
    { naziv: 'Osnovna šola Prežihovega Voranca Bistrica', naslov: 'Srednja Bistrica 49 B', posta: '9232', kraj: 'Črenšovci' },
    { naziv: 'Osnovna šola Blaža Kocena Ponikva', naslov: 'Ponikva 29 A', posta: '3232', kraj: 'Ponikva' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Gimnazija Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole za rudarstvo in geologijo Zagorje ob Savi SŠ kov.in elektroteh.usm.Krško', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola tehniških strok Šiška Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole tehn. strok Litostroj Ljubljana Gimn.J.Jurčič in STŠ Ivan. G.', naslov: '', posta: '', kraj: '' },
    { naziv: 'IC Rudnika Mežica Rudarska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje agroživil. šole Lj. Sr.agroživil.šola Maribor TOZD Kmetijska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje gostinske in turistične šole Izola Sr.družb.ekonom.šola N.Gorica', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Gradbene srednje šole Borisa Kraigherja Maribor Srednja kovinarska šola Muta', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje gradbene šole Kranj Srednja kovinarska šola Muta', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Šolskega centra Postojna, Vzgojni zavod Planina pri Rakeku', naslov: 'Planina 142', posta: '6232', kraj: 'Planina' },
    { naziv: 'DE Centra za dopisno izobraževanje Univerzum Lj. DU Rogaška Slatina', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Ljudska univerza Žalec', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje upravno administrativne šole Lj. Revirska DU Trbovlje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole kmetijske mehanizacije Maribor Srednja kmetijska šola Grm', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. živilske šole Maribor Srednja gostinska in turistična šola Izola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje agroživilske šole Emona Ljubljana Služba za razvoj kadrov', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje agroživilske šole Center za izobraževanje in kulturo Trebnje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, enota Vrtec Sovodenj', naslov: 'Sovodenj 32', posta: '4225', kraj: 'Sovodenj' },
    { naziv: 'Vrtec Radovljica, Enota Lesce', naslov: 'Alpska cesta 58 A', posta: '4248', kraj: 'Lesce' },
    { naziv: 'GEA College CVŠ, Družba za višješolsko izobraževanje - Center višjih šol, d.o.o., Podružnica na Ptuju', naslov: 'Mestni trg 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Srednješolski center Ptuj Strojna in metalurška šola Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednješolski center Ptuj Gimnazija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednješolski center Ptuj Ekonomska šola Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Železniška srednja šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje elektro- računalniške šole Maribor Sr.stroj. in tek.šola M.Sobota', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole elektrotehniške in računalniške usm. Maribor Srednješol.c. D.Kvedra Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Naravoslov. srednješolskega centra Nova Gorica Srednja gradbena šola V. Pilon', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Naravoslov. srednješolskega centra Nova Gorica Sr.pomor.in promet.šola Piran', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednješolski center tehniško pedagoške usmeritve Murska Sobota', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Dijaški dom Nova Gorica', naslov: 'Streliška pot 7', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Dijaški dom Ivana Cankarja Ljubljana', naslov: 'Poljanska cesta 26', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Dijaški dom Tabor', naslov: 'Kotnikova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Dijaški in študentski dom Koper', naslov: 'Cankarjeva ulica 5', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Dijaški in študentski dom Kranj', naslov: 'Kidričeva cesta 53', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Dijaški dom Lizike Jančar', naslov: 'Titova cesta 24 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Dijaški dom Maribor', naslov: 'Gosposvetska cesta 89', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Dijaški dom Bežigrad Ljubljana', naslov: 'Kardeljeva ploščad 28', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Dijaški dom Tolmin', naslov: 'Dijaška ulica 14', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Dijaški in študentski dom Novo mesto', naslov: 'Šegova ulica 115', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Radovljica, Enota Kropa', naslov: 'Kropa 98 A', posta: '4245', kraj: 'Kropa' },
    { naziv: 'Vrtec Sežana, Enota Sežana', naslov: 'Ulica Jožeta Pahorja 1', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Vrtec Vrhovci,enota Brdo', naslov: 'Stantetova ulica 1 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Najdihojca, Enota Palček', naslov: 'Gorazdova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Miškolin, Novo Polje', naslov: 'Novo Polje, cesta VI 1', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Dijaški dom Celje', naslov: 'Ljubljanska cesta 21', posta: '3000', kraj: 'Celje' },
    { naziv: 'Dijaški dom Črnomelj', naslov: 'Ulica Otona Župančiča 7', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Dijaški dom Poljane', naslov: 'Potočnikova ulica 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Dijaški dom 26.junij Maribor', naslov: 'Železnikova ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Dijaški dom Ptuj', naslov: 'Arbajterjeva ulica 6', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Dijaški dom Kočevje', naslov: 'Cesta na stadion 5', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Dijaški dom Vič', naslov: 'Gerbičeva ulica 51 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Škofja Loka, Medpodjetniški izobraževalni center', naslov: 'Podlubnik 1 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Dijaški dom Murska Sobota', naslov: 'Tomšičeva ulica 15', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Dijaški dom Portorož', naslov: 'Sončna pot 20', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Dijaški dom Tezno', naslov: 'Zolajeva ulica 13', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ljudska univerza Ajdovščina', naslov: 'Cesta 5. maja 14', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Zavod za kulturo in izobraževanje Ljutomer', naslov: 'Glavni trg 2', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Skupnost vzgojnoizobraževalnih zavodov Lendava (v kategorijo 19)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola tehniških strok Šiška', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toneta Čufarja Jesenice (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Gorenjskega odreda Žirovnica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola 16. december', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Kranjska Gora', naslov: '', posta: '', kraj: '' },
    { naziv: 'Občina Laško - Odsek za vzgojo in izobraževanje - Skupna služba zavodov', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Pier Paolo Vergerio il Vecchio Koper, Podružnična šola Semedela', naslov: 'Semedela 28', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Gradec Litija Podružnica Hotič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Nova Gorica, Strojna, prometna in lesarska šola', naslov: 'Erjavčeva ulica 4 A', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Šentvid, Enota Sapramiška', naslov: 'Ulica pregnancev 6', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Vrtec Šentvid, Enota Vid', naslov: 'Prušnikova ulica 73 A', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Izobraževalni center Geoss d.o.o. Višja strokovna šola', naslov: 'Trg na Stavbah 8 A', posta: '1270', kraj: 'Litija' },
    { naziv: 'DE Srednje agroživilske šole Ljudska univerza Žalec', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske šole Boris Kidrič Ljubljana Ljudska univerza Kočevje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Ljubljana Ljudska univerza Kočevje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum Ljubljana Ljudska univerza Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Lj. Zavod za izobr.in kulturo Litija Delavska univerza', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum Ljudska univerza Slov.Bistrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole za ekonomsko usmeritev Tone Grčar Celje DU Slovenske Konjice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gimnazija Šiška (v ustan.) Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske in upravno-administr. šole Kranj DU Viktor Stražišar Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovinske šole Kranj DU Viktor Stražišar Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Prežihovega Voranca Ravne na Kor. Podružnica Strojna', naslov: 'Strojna 19', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Osnovna šola Rogatec Podružnica Sv. Florijan', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Sv. Štefan', naslov: 'Sveti Štefan 72', posta: '3264', kraj: 'Sveti Štefan' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah Podružnica Kristan vrh', naslov: 'Kristan Vrh 40 B', posta: '3241', kraj: 'Podplat' },
    { naziv: 'Osnovna šola Srečka Kosovela Sežana, Podružnična šola Lokev', naslov: 'Lokev 123', posta: '6219', kraj: 'Lokev' },
    { naziv: 'Osnovna šola Pohorskega odreda Slov. Bistrica Podružnica Zgornja Ložnica', naslov: 'Zgornja Ložnica 43', posta: '2316', kraj: 'Zgornja Ložnica' },
    { naziv: 'Osnovna šola Pod goro Podružnica Stranice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ob Dravinji, Podružnična šola Tepanje', naslov: 'Tepanje 28 C', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Osnovna šola Dobje pri Planini Podružnica Kalobje (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas Podružnica Javorje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas Podružnica Poljane', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Miha Pintar - Toledo Velenje Podružnica Cirkovce', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Najdihojca, Enota Čenča', naslov: 'Lepodvorska ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Najdihojca, Enota Palček, dislocirani oddelki Kebetova', naslov: 'Kebetova ulica 30', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod za predšolsko vzgojo ZVEZDICA, Dragomer', naslov: 'Rožna pot 13', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Vrtec Velenje enota Sonček', naslov: 'Arnače 2 A', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Miha Pintar - Toledo Velenje Podružnica Škale', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toneta Okrogarja, Podružnična šola Kisovec', naslov: 'Cesta 15. aprila 25', posta: '1412', kraj: 'Kisovec' },
    { naziv: 'Osnovna šola Peter Šprajc-Jura Podružnica Vinska gora', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Komenda Moste Podružnica Moste', naslov: 'Moste 40', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Osnovna šola Kanal Podružnica Lig', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Milojke Štrukelj Nova Gorica, Podružnična osnovna šola Ledine', naslov: 'Cankarjeva ulica 23', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola narodnega heroja Rajka Hrastnik, Podružnična šola Dol pri Hrastniku', naslov: 'Planinska cesta 3', posta: '1431', kraj: 'Dol pri Hrastniku' },
    { naziv: 'Osnovna šola Dravograd Podružnica Šentjanž', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Polje Podružnica Dolsko', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Polje Podružnica Križevska vas', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Polje Podružnica Senožeti', naslov: '', posta: '', kraj: '' },
    { naziv: 'VIZ Brežice Osnovna šola Bizeljsko', naslov: '', posta: '', kraj: '' },
    { naziv: 'Glasbena šola Grosuplje', naslov: 'Partizanska cesta 5', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Dragotin Kette', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Stična', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Prežihovega Voranca Bistrica (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Litija (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zavod za delovno usposabljanje Miha Pinter Dobrna', naslov: '', posta: '', kraj: '' },
    { naziv: 'Poslovna skupnost osnovnih šol Domžale (v kategorijo 19)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vzgojnoizobraževalni zavod Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovnošolski vzgojno- izobraževalni zavod Murska Sobota', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Leskovec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Jože Petek', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja glasbena in baletna šola Maribor Oddelek Slovenska Bistrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Blaža Kocena Ponikva (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Dušan Poženel Laško', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Velenje, Medpodjetniški izobraževalni center', naslov: 'Koroška cesta 62 A', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Anice Černejeve Celje, Enota Sonce', naslov: 'Kajuhova ulica 5', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Cepetavček', naslov: 'Nevlje 18', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Pedenjped, Enota Korenjak', naslov: 'Papirniški trg 5', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Osnovna šola Veljko Vlahovič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Glasbena šola Jesenice', naslov: 'Cesta Franceta Prešerna 48', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Osnovna šola Poldeta Stražišarja Jesenice', naslov: 'Ulica Viktorja Kejžarja 35', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Zavod za izvajanje spremljevalnih dejavnosti občine Šmarje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vzgojnoizobraževalni zavod Zagorje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Glasbena šola pri DU Mozirje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Antona Janša Radovljica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Dom učencev Franjo Klojčnik Škofja Loka', naslov: 'Podlubnik 1 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'DE Biotehniškega izobraževalnega centra Ljubljana, Vzgojno-izobraževalni zavod Višnja Gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Osnovna šola Miroslav Širca Petrovče', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole tiska in papirja Ljubljana Srednja šola Krško', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole tiska in papirja Ljubljana II. gimnazija Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sš tiska in papirja Lj. Delavska Univerza Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne šole Maribor Srednja kovinarska šola Muta', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole za trgovinsko dejavnost Maribor DU Slovenska Bistrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja družboslovna šola Piran', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Sr.grad.in naravosl.š.V.Pilon', naslov: '', posta: '', kraj: '' },
    { naziv: 'RSNZ Srednja penološka šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'CSŠ Titovo Velenje TOZD Sr. elektro, kovinarska in računalniška šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE CSŠ T.Velenje TOZD Sred. elek.,kov.in računal. šola DU Slovenske Konjice', naslov: '', posta: '', kraj: '' },
    { naziv: 'CSŠ Titovo Velenje TOZD Srednja rudarska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Prometna šola Maribor', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'ŽG - ŽŠC DE TOZD Žel.sred šole Maribor TOZD Želez.sred.šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sava Kranj TOZD Gumarski izobraževalni center', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Anice Černejeve Celje, Enota Bolnišnica', naslov: 'Oblakova ulica 5', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Miren, Enota vrtec Miren', naslov: 'Miren 140', posta: '5291', kraj: 'Miren' },
    { naziv: 'Zasebna glasbena šola Maestro, zavod za glasbeno kulturo, Gornja Radgona', naslov: 'Maistrov trg 2', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'VRTEC MOJCA ENOTA KEKEC', naslov: 'Klopčičeva ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Viški vrtci, enota Rožna dolina', naslov: 'Cesta 27. aprila 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'DE S.š.teh.usm.in druž.Kočevje Vzgojni zavod Planina pri Rakeku', naslov: '', posta: '', kraj: '' },
    { naziv: 'Center poklicnih šol Kočevje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred kovinarske in prometne šole Koper Srednja šola S.Kosovel Sežana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kovinarske in prometne šole Koper TŠC Branko Brelih Nova Gorica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gradbena tehniška šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr. zdravstvene šole Celje ZSC M.Marinko Trbovlje-TOZD SŠ nar.mat.in ekonomske usmeritve', naslov: '', posta: '', kraj: '' },
    { naziv: 'Poklicna kovinarska in oblačilna šola z italijanskim učnim jezikom Izola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje gradbene šole I. Kavčič Ljubljana Poklicna šola Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Gimnazije Kranj Center srednjega usmerjenega izobraževanja Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske šole Lj. Srednja tehniška in naravoslovna šola Postojna', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske šole Lj. Šolski center J.Jurčič TOZD Srednja šola družboslovne usm.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja agroživilska šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Borisa Kidriča DE Delavska univerza Slovenske Konjice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Josip Jurčič Ivančna Gorica', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'DE Srednje gostinske in turistične šole Izola ŠC Vojvodina Tolmin', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr. zdravst. šole J.Polaka Srednješolski center Dušana Kvedra Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Tehniš.srednješol.centra B.Brelih Nova gorica Sr.pomor.in promet.šola Piran', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Teh.srednješol.centra B.Brelih Nova Gorica Sr.družb.in ek. šola S.Kosovel', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Tehniškega šolskega centra Nova Gorica Gimnazija Jurija Vege Idrija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja gostinsko-turistična in ekonomska šola Bled', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola tehniško- naravoslovne in pedagoške usmeritve', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole tehniško-nar. in pedagoške usmeritve Ravne Srednja kovinarska šola Muta', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sš E.K.eko.,zdr., družb.in gost. usm. DU Ravne na Koroškem', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gimnazija Tolmin', naslov: 'Dijaška ulica 12 B', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Vrtec Vrhnika, enota Želvica', naslov: 'Tržaška cesta 2 A', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Vrtec Vrhovci,dislokacija Iga Grudna 17', naslov: 'Ulica Iga Grudna 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Nazarje', naslov: 'Zadrečka cesta 37', posta: '3331', kraj: 'Nazarje' },
    { naziv: 'Osnovna šola Fara Podružnica Osilnica', naslov: 'Osilnica 11', posta: '1337', kraj: 'Osilnica' },
    { naziv: 'Osnovna šola Gradec', naslov: 'Bevkova ulica 3', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Gradec, Podružnična osnovna šola Hotič', naslov: 'Zgornji Hotič 6', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Gradec, Podružnična osnovna šola Jevnica', naslov: 'Jevnica 33', posta: '1281', kraj: 'Kresnice' },
    { naziv: 'Osnovna šola Gradec, Podružnična osnovna šola Kresnice', naslov: 'Kresnice 26 A', posta: '1281', kraj: 'Kresnice' },
    { naziv: 'Srednja šola tehniških strok Šiška', naslov: 'Litostrojska cesta 51', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Revirska ljudska univerza Trbovlje (po koncesiji)', naslov: 'Trg svobode 11 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Muta Podružnica Branik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Louisa Adamiča Grosuplje Podružnica Šmarje Sap', naslov: 'Ljubljanska cesta 49', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'Osnovna šola Louisa Adamiča OE Grosuplje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Louisa Adamiča Grosuplje Podružnica Kopanj', naslov: 'Velika Račna 43', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Louisa Adamiča Grosuplje Podružnica Št.Jurij', naslov: 'Št. Jurij 11', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Louisa Adamiča Grosuplje Podružnica Žalna', naslov: 'Žalna 1', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Center za izobraževanje in usposabljanje Idrija', naslov: 'Ulica IX. korpusa 17', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Dom Antona Skale Maribor', naslov: 'Majcigerjeva ulica 37', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Ig, Podružnica Golo', naslov: 'Golo 37', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Ig, Podružnica Iška vas', naslov: 'Iška vas 2', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Ig, Podružnica Tomišelj', naslov: 'Tomišelj 30', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Miren, Enota vrtec Bilje', naslov: 'Bilje 185', posta: '5292', kraj: 'Renče' },
    { naziv: 'Vrtec Beltinci, Jugovo 33, 9231 Beltinci', naslov: 'Jugovo 33', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'SKALDENS, zasebni zdravstveni zavod, Višja strokovna šola za ustne higienike', naslov: 'Ulica bratov Jančar 25', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Mavrica Vojnik', naslov: 'Prušnikova ulica 29 A', posta: '3212', kraj: 'Vojnik' },
    { naziv: 'Javni zavod Vrtec Zreče', naslov: 'Cesta na Roglo 13', posta: '3214', kraj: 'Zreče' },
    { naziv: 'Vrtec Urša', naslov: 'Murnova ulica 14', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Vitanje, Enota Vrtec', naslov: 'Na gmajni 5', posta: '3205', kraj: 'Vitanje' },
    { naziv: 'VVE "Ringaraja" pri OŠ Dobrepolje', naslov: 'Videm 80', posta: '1312', kraj: 'Videm - Dobrepolje' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Sr.šola tekstilne usm. Metlika', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Delavska univerza Domžale', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE SŠ tehniških in zdravstvene usmeritve B.Kidrič Novo mesto KPD Dob pri Mirni', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Šolskega centra Velenje Kovinarska šola Zreče', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Rudarske šole Mežica Gimnazija Jurija Vege Idrija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja vzgojiteljska šola in gimnazija Ljubljana, Srednja vzgojiteljska šola', naslov: 'Kardeljeva ploščad 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Nazaret', naslov: 'Mekinčeva ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Waldorfski vrtec Mavrica', naslov: 'Prušnikova ulica 60', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Glasbeni atelje Tartini zasebna glasbena šola d.o.o.', naslov: 'Smoletova ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ŽUPNIJSKI VRTEC VRHNIKA', naslov: 'Voljčeva cesta 21', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Zavod Dominik Savio Karitas Domžale, OE Vrtec Dominik Savio Karitas Domžale', naslov: 'Tabor 10', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Pingvin - Zavod za kulturo, izobraževanje in predšolsko vzgojo', naslov: 'Popovičeva ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Petrov vrtec Šentpeter', naslov: 'Šentpeter 26', posta: '8222', kraj: 'Otočec' },
    { naziv: 'Marijin vrtec, Cerklje', naslov: 'Trg Davorina Jenka 14', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Vrtec pri Sv.Ani Žiri', naslov: 'Loška cesta 59', posta: '4226', kraj: 'Žiri' },
    { naziv: 'Zavod sv. Stanislava - Glasbena šola (zasebni zavod)', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Srednja tehniška šola Koper, Strokovna gimnazija', naslov: 'Izolska vrata 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Šolske delavnice tehniških šol Ljubjana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Radovljica, Enota Kamna Gorica', naslov: 'Kamna Gorica 61', posta: '4246', kraj: 'Kamna Gorica' },
    { naziv: 'Srednja policijska šola', naslov: 'Rocenska ulica 56', posta: '1211', kraj: 'Ljubljana - Šmartno' },
    { naziv: 'Šola za miličnike', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šola za poklicne gasilce', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Zagorje', naslov: 'Cesta zmage 5', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'DE Srednje šole za rudarstvo in geologijo Zagorje ob Savi SŠ druž.in teh.us.E.K.Črnomelj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred.lesarske šole Šk.Loka Srednja tehniška in zdravstvena šola Novo mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr.lesar.šole Škofja Loka Vzgojni zavod Planina pri Rakeku', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice', naslov: 'Aškerčeva cesta 1', posta: '3272', kraj: 'Rimske Toplice' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice Podružnica Jurklošter', naslov: 'Jurklošter 23', posta: '3273', kraj: 'Jurklošter' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice Podružnica Lažiše', naslov: 'Laziše 27', posta: '3272', kraj: 'Rimske Toplice' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice Podružnica Sedraž', naslov: 'Sedraž 21', posta: '3270', kraj: 'Laško' },
    { naziv: 'Srednja gostinska šola Radovljica, Dijaški dom', naslov: 'Kranjska cesta 24', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Simona Jenka Kranj Podružnica Trstenik', naslov: 'Trstenik 39', posta: '4204', kraj: 'Golnik' },
    { naziv: 'Ekonomska šola Ljubljana', naslov: 'Prešernova cesta 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Ormož', naslov: 'Hardek 5 A', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Vrtec Kidričevo', naslov: 'Kajuhova ulica 10 A', posta: '2325', kraj: 'Kidričevo' },
    { naziv: 'Zavod za izvajanje spremljevalnih dejavnosti Žalec', naslov: 'Ulica Ivanke Uranjek 2', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Javni zavod VIZ Murska Sobota', naslov: 'Trstenjakova ulica 73', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola Cerkno, Enota Vrtec Cerkno', naslov: 'Bevkova ulica 20', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Osnovna šola Polzela, Enota Vrtec Polzela', naslov: 'Glavni trg 1', posta: '3313', kraj: 'Polzela' },
    { naziv: 'Osnovna šola Braslovče, enota Vrtec', naslov: 'Rakovlje 15 B', posta: '3314', kraj: 'Braslovče' },
    { naziv: 'Osnovna šola Vransko - Tabor, Enota Vrtec', naslov: 'Vransko 24', posta: '3305', kraj: 'Vransko' },
    { naziv: 'Šolski center Šentjur, Dijaški dom Šentjur', naslov: 'Cesta na kmetijsko šolo 9', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Zreče, Podružnična šola Stranice', naslov: 'Stranice 36', posta: '3206', kraj: 'Stranice' },
    { naziv: 'Vrtec Danijelov levček, Opatijsko-mestni župnijski zavod Sv. Danijela Celje', naslov: 'Slomškov trg 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'OŠ dr. Jožeta Pučnika Črešnjevec, Vrtec Črešnjevec - Leskovec, enota Črešnjevec', naslov: 'Črešnjevec 47', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Orehek Kranj', naslov: 'Zasavska cesta 53 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Orehek Kranj, Podružnična šola Mavčiče', naslov: 'Mavčiče 61', posta: '4211', kraj: 'Mavčiče' },
    { naziv: 'Osnovna šola Primoža Trubarja Velike Lašče, Enota Vrtec Sončni žarek', naslov: 'Šolska ulica 9', posta: '1315', kraj: 'Velike Lašče' },
    { naziv: 'Vrtec pri OŠ Antona Žnideršiča Ilirska Bistrica', naslov: 'Rozmanova ulica 25 B', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Osnovna šola Nazarje', naslov: 'Zadrečka cesta 37', posta: '3331', kraj: 'Nazarje' },
    { naziv: 'Osnovna šola Nazarje Podružnica Šmartno ob Dreti', naslov: 'Šmartno ob Dreti 27', posta: '3341', kraj: 'Šmartno ob Dreti' },
    { naziv: 'Biotehniški izobraževalni center Ljubljana, Gimnazija in veterinarska šola', naslov: 'Cesta v Mestni log 47', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'DE Steklarske šole Rog.slatina Srednja šola za elektroniko Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'CSŠ Titovo Velenje TOZD Srednja družboslovna šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred.šole B.Kidrič Celje DU Brežice', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole B.Kidrič Celje ZŠAM Rogaška Slatina', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zavod za usposabljanje invalidne mladine Kamnik - Srednja šola', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vzgojni zavod Planina', naslov: 'Planina 152', posta: '6232', kraj: 'Planina' },
    { naziv: 'Center slepih in slabovidnih dr. Antona Kržišnika, Srednja šola Škofja Loka', naslov: 'Stara Loka 31', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vzgojno-izobraževalni zavod Višnja Gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Zavod za vzgojo in izobraževanje Logatec', naslov: 'Tržaška cesta 63', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Center za dopisno izobraževanje Univerzum Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobraževanje Univerzum Lj. Posav.center za perm.iz. Krško', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zavod za usposabljanje slušno in govorno motenih Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred.tehniške šole Celje Center za usmerjeno izobraževanje Žalec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja vrtnarska, kmetijska, gospodinjska šola Celje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Kočevje', naslov: 'Trg zbora odposlancev 22', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA HOTEDRŠICA', naslov: 'Hotedršica 120', posta: '1372', kraj: 'Hotedršica' },
    { naziv: 'VRTEC OŠ MALA NEDELJA', naslov: 'Mala Nedelja 37', posta: '9243', kraj: 'Mala Nedelja' },
    { naziv: 'VRTEC VIŠKI GAJ, ENOTA ZARJA', naslov: 'Reška ulica 31', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Urša, enota Češmin', naslov: 'Murnova ulica 14', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Glasbena šola Trebnje', naslov: 'Kidričeva ulica 11', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Šolski center Novo mesto', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'VVE pri OŠ Pesnica', naslov: 'Pesnica pri Mariboru 41 C', posta: '2211', kraj: 'Pesnica pri Mariboru' },
    { naziv: 'Antonov vrtec Železniki', naslov: 'Škovine 1', posta: '4228', kraj: 'Železniki' },
    { naziv: 'Osnovna šola Frana Metelka Škocjan Podružnica Bučka', naslov: 'Bučka 27', posta: '8276', kraj: 'Bučka' },
    { naziv: 'Gimnazija Ptuj', naslov: 'Volkmerjeva cesta 15', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Šolski center Ptuj, Ekonomska šola', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Šolski center Ptuj, Biotehniška šola', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Šolski center Ptuj, Elektro in računalniška šola', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Šolski center Ptuj, Strojna šola', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Dol pri Ljubljani Podružnica Križevska vas', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Janka Modra Dol pri Ljubljani Podružnica Dolsko', naslov: 'Dolsko 85', posta: '1262', kraj: 'Dol pri Ljubljani' },
    { naziv: 'Vrtec Medvode', naslov: 'Ostrovrharjeva ulica 2', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič, enota vrtec Sonček', naslov: 'Šolska ulica 2', posta: '8333', kraj: 'Semič' },
    { naziv: 'Osnovna šola Starše, Vrtec Najdihojca Starše', naslov: 'Starše 6', posta: '2205', kraj: 'Starše' },
    { naziv: 'Osnovna šola Duplek, Enota Vrtec', naslov: 'Korenska cesta 31', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'VVE pri OŠ Korena', naslov: 'Zgornja Korena 32', posta: '2242', kraj: 'Zgornja Korena' },
    { naziv: 'Osnovna šola Ljubno ob Savinji, Enota Vrtec pri šoli, Vrtec Ljubno ob Savinji', naslov: 'Cesta v Rastke 10', posta: '3333', kraj: 'Ljubno ob Savinji' },
    { naziv: 'VIZ Vrtci Brezovica', naslov: 'Nova pot 9', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'VVZ Radeče', naslov: 'Šolska pot 9', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Zavod za usposabljanje invalidne mladine Kamnik Srednja šola', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Ciciban, Enota Mehurčki', naslov: 'Reboljeva ulica 18', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ciciban, DE Lenka', naslov: 'Vojkova cesta 74', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Ferda Vesela Podružnica v Centru za zdravljenje bolezni otrok', naslov: 'Šentvid pri Stični 46', posta: '1296', kraj: 'Šentvid pri Stični' },
    { naziv: 'Osnovna šola Gorišnica, Vrtec Gorišnica', naslov: 'Gorišnica 83', posta: '2272', kraj: 'Gorišnica' },
    { naziv: 'Osnovna šola Jelšane, Enota Vrtec', naslov: 'Jelšane 82', posta: '6254', kraj: 'Jelšane' },
    { naziv: 'Osnovna šola Istrskega odreda Gračišče, Enota Vrtec', naslov: 'Gračišče 5', posta: '6272', kraj: 'Gračišče' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor, Enota vrtec', naslov: 'Šolska ulica 9', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'Osnovna šola Šenčur, Enota Vrtec', naslov: 'Pipanova cesta 43', posta: '4208', kraj: 'Šenčur' },
    { naziv: 'Osnovna šola Raka, Enota Vrtec', naslov: 'Raka 36', posta: '8274', kraj: 'Raka' },
    { naziv: 'Osnovna šola Artiče, Vrtec Ringa Raja', naslov: 'Artiče 39', posta: '8253', kraj: 'Artiče' },
    { naziv: 'VVE pri OŠ Toneta Tomšiča Knežak', naslov: 'Knežak 147 A', posta: '6253', kraj: 'Knežak' },
    { naziv: 'Osnovna šola Šmarje pri Kopru - vrtec', naslov: 'Šmarje 1', posta: '6274', kraj: 'Šmarje' },
    { naziv: 'Osnovna šola Adama Bohoriča Brestanica, enota Vrtec', naslov: 'Šolska cesta 29', posta: '8280', kraj: 'Brestanica' },
    { naziv: 'VVZ Kostanjevica na Krki', naslov: 'Gorjanska cesta 2', posta: '8311', kraj: 'Kostanjevica na Krki' },
    { naziv: 'Osnovna šola Dobrova, Vrtec Brezje', naslov: 'Brezje pri Dobrovi 17', posta: '1356', kraj: 'Dobrova' },
    { naziv: 'Osnovna šola Horjul, Enota Vrtec', naslov: 'Ljubljanska cesta 6', posta: '1354', kraj: 'Horjul' },
    { naziv: 'Osnovna šola Polhov Gradec, Enota Vrtec', naslov: 'Polhov Gradec 95', posta: '1355', kraj: 'Polhov Gradec' },
    { naziv: 'Osnovna šola Janka Ribiča Cezanjevci, Enota Vrtec', naslov: 'Cezanjevci 39', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Oskarja Kovačiča Škofije, Vrtec Škofije', naslov: 'Spodnje Škofije 40 D', posta: '6281', kraj: 'Škofije' },
    { naziv: 'Osnovna šola Ivana Cankarja Ljutomer, enota Vrtec', naslov: 'Cven 3 C', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'VVE pri OŠ Gornji Petrovci', naslov: 'Gornji Petrovci 2', posta: '9203', kraj: 'Petrovci' },
    { naziv: 'Osnovna šola Cankova, Enota Vrtec', naslov: 'Cankova 27', posta: '9261', kraj: 'Cankova' },
    { naziv: 'Osnovna šola Sveti Jurij, Enota Vrtec', naslov: 'Sveti Jurij 13', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Deskle, enota vrtec Deskle', naslov: 'Srebrničeva ulica 10', posta: '5210', kraj: 'Deskle' },
    { naziv: 'Osnovna šola Branik, Vrtec Rastja', naslov: 'Branik 31', posta: '5295', kraj: 'Branik' },
    { naziv: 'Osnovna šola Podgora  Kuteževo, Enota vrtec', naslov: 'Kuteževo 2 F', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Osnovna šola Franceta Prešerna Kranj, Enota Vrtec Kokrica', naslov: 'Cesta na Brdo 45 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA CENTRALNI VRTEC, oddelek Metulji', naslov: 'Notranjska cesta 14', posta: '1370', kraj: 'Logatec' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA CENTRALNI VRTEC, oddelek Metulji', naslov: 'Notranjska cesta 14', posta: '1370', kraj: 'Logatec' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA ROVTE, oddelek Lisičke', naslov: 'Rovte 84', posta: '1373', kraj: 'Rovte' },
    { naziv: 'Osnovna šola Blaža Arniča Luče, Enota Vrtec Luče', naslov: 'Luče 77', posta: '3334', kraj: 'Luče' },
    { naziv: 'Osnovna šola Destrnik Podružnica Trnovska vas', naslov: 'Trnovska vas 38 H', posta: '2254', kraj: 'Trnovska vas' },
    { naziv: 'Osnovna šola dr. Antona Debeljaka Loški Potok Podružnica Podpreska', naslov: 'Podpreska 3', posta: '1319', kraj: 'Draga' },
    { naziv: 'Ljudska univerza Tržič', naslov: 'Šolska ulica 2', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Osnovna šola Juršinci, Enota Vrtec', naslov: 'Juršinci 19', posta: '2256', kraj: 'Juršinci' },
    { naziv: 'VVE pri OŠ Borisa Kidriča Kidričevo', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Majšperk, Vrtec Majšperk', naslov: 'Breg 6 A', posta: '2322', kraj: 'Majšperk' },
    { naziv: 'Osnovna šola Videm, enota Vrtec', naslov: 'Videm pri Ptuju 47', posta: '2284', kraj: 'Videm pri Ptuju' },
    { naziv: 'VVE pri Osnovni šoli Prežihovega Voranca Bistrica', naslov: 'Srednja Bistrica 49 B', posta: '9232', kraj: 'Črenšovci' },
    { naziv: 'Osnovna šola Dobje pri Planini Podružnica Kalobje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske Toplice Podružnica Zidani Most', naslov: 'Zidani Most 34', posta: '1432', kraj: 'Zidani Most' },
    { naziv: 'Osnovna šola Orehek', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Orehek Podružnica Mavčiče', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ptuj - program ekonomska gimnazija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Novo mesto program - Tehniška gimnazija', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Mladinski dom Malči Beličeve Ljubljana', naslov: 'Mencingerjeva ulica 65', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Pedagoška fakulteta Ljubljana', naslov: 'Kardeljeva ploščad 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Pedagoška fakulteta Maribor', naslov: 'Koroška cesta 160', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Visoka upravna šola Ljubljana', naslov: 'Gosarjeva ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Pravna fakulteta Maribor', naslov: 'Mladinska ulica 9', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Fakulteta za kmetijstvo Maribor', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Visoka šola za zdravstvo Ljubljana', naslov: 'Zdravstvena pot 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ekonomsko-poslovna fakulteta Maribor', naslov: 'Razlagova ulica 14', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Fakulteta za organizacijske vede Kranj', naslov: 'Kidričeva cesta 55 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Fakulteta za strojništvo Maribor', naslov: 'Smetanova ulica 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Fakulteta za elektrotehniko, rač. in informatiko Maribor', naslov: 'Smetanova ulica 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JVIZ Vrtec Idrija, Enota Osnovna šola', naslov: 'Lapajnetova ulica 50', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Osnovna šola Globoko, Enota Vrtec', naslov: 'Globoko 9', posta: '8254', kraj: 'Globoko' },
    { naziv: 'VVE pri OŠ Komenda - Moste, oddelek Komenda', naslov: 'Glavarjeva cesta 37', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Vrtec pri OŠ Cvetko Golar Ljutomer', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Križevci, Enota Vrtec', naslov: 'Križevci pri Ljutomeru 16 B', posta: '9242', kraj: 'Križevci pri Ljutomeru' },
    { naziv: 'VVE pri OŠ Fokovci', naslov: '', posta: '', kraj: '' },
    { naziv: 'OŠ Mokronog, Vrtec Mokronožci', naslov: 'Gubčeva cesta 4', posta: '8230', kraj: 'Mokronog' },
    { naziv: 'VVE pri OŠ Trebnje Podružnica Šentlovrenc', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Mirna, Enota Vrtec Mirna', naslov: 'Glavna cesta 29', posta: '8233', kraj: 'Mirna' },
    { naziv: 'Osnovna šola Žirovnica, VVE pri OŠ Žirovnica', naslov: 'Zabreznica 4', posta: '4274', kraj: 'Žirovnica' },
    { naziv: 'Osnovna šola Antona Aškerca Rimske toplice Podružnica Zidani most (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Dijaški dom Drava Maribor', naslov: 'Smetanova ulica 67', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VVE Čebelica pri OŠ Šentjernej', naslov: 'Prvomajska cesta 9', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'VVE pri OŠ Dob', naslov: 'Šolska ulica 7', posta: '1233', kraj: 'Dob' },
    { naziv: 'Osnovna šola Roje, razvojni oddelek', naslov: 'Kettejeva ulica 15', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Šmartno v Tuhinju Podružnica Motnik', naslov: 'Motnik 8', posta: '1221', kraj: 'Motnik' },
    { naziv: 'Osnovna šola Šmartno v Tuhinju Podružnica Sela', naslov: 'Sela pri Kamniku 5', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Šmartno v Tuhinju Podružnica Zgornji Tuhinj', naslov: 'Zgornji Tuhinj 45', posta: '1219', kraj: 'Laze v Tuhinju' },
    { naziv: 'Osnovna šola Rudolfa Maistra Šentilj, Enota Vrtec Šentilj', naslov: 'Mladinska ulica 12', posta: '2212', kraj: 'Šentilj v Slov. goricah' },
    { naziv: 'Osnovna šola Sladki vrh, enota Vrtec Sladki Vrh', naslov: 'Sladki Vrh 8 B', posta: '2214', kraj: 'Sladki Vrh' },
    { naziv: 'Osnovna šola Jarenina, Vrtec Jarenina', naslov: 'Jareninski Dol 26', posta: '2221', kraj: 'Jarenina' },
    { naziv: 'Osnovna šola Jakobski Dol, enota vrtec', naslov: 'Spodnji Jakobski Dol 4', posta: '2222', kraj: 'Jakobski dol' },
    { naziv: 'Osnovna šola Janka Modra Dol pri Ljubljani Podružnica Senožeti', naslov: 'Senožeti 53', posta: '1262', kraj: 'Dol pri Ljubljani' },
    { naziv: 'Fakulteta za kemijo in kemijsko tehnologijo Ljubljana', naslov: 'Aškerčeva cesta 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za farmacijo Ljubljana', naslov: 'Aškerčeva cesta 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana Odd.za tekstilno tehnologijo', naslov: '', posta: '', kraj: '' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana Odd.za kemijsko izob.in infor.', naslov: 'Vegova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniška fakulteta Ljubljana Oddelek za agronomijo', naslov: 'Jamnikarjeva ulica 101', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniška fakulteta Ljubljana Odd. za živilstvo', naslov: 'Jamnikarjeva ulica 101', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Pedenjped Novo mesto, PIKAPOLONICA', naslov: 'Brezje 8', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Pedenjped Novo mesto, RDEČA KAPICA', naslov: 'Šegova ulica 5', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Biotehniška fakulteta Ljubljana Oddelek za gozdarstvo', naslov: 'Večna pot 83', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniška fakulteta Ljubljana Oddelek za biologijo', naslov: 'Večna pot 111', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniška fakulteta Ljubljana Oddelek za lesarstvo', naslov: '', posta: '', kraj: '' },
    { naziv: 'Biotehniška fakulteta Ljubljana Oddelek za zootehniko', naslov: '', posta: '', kraj: '' },
    { naziv: 'Veterinarska fakulteta Ljubljana', naslov: 'Gerbičeva ulica 60', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Medicinska fakulteta Ljubljana', naslov: 'Vrazov trg 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Akademija za likovno umetnost Ljubljana', naslov: 'Erjavčeva cesta 23', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Akademija za glasbo Ljubljana', naslov: 'Stari trg 34', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Akademija za gledališče, radio, film in televizijo Ljubljana', naslov: 'Nazorjeva ulica 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Teološka fakulteta Ljubljana', naslov: 'Poljanska cesta 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana, dekanat', naslov: 'Aškerčeva cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Biotehniška fakulteta Ljubljana, dekanat', naslov: 'Jamnikarjeva ulica 101', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za gradbeništvo in geodezijo Ljubljana', naslov: 'Jamova cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Tehniška fakulteta Maribor, dekanat', naslov: 'Smetanova ulica 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Univerza v Mariboru', naslov: 'Krekova ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Fakulteta za pomorstvo in promet Piran', naslov: 'Pot pomorščakov 4', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Visoka šola za socialno delo Ljubljana', naslov: 'Topniška ulica 33', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Dragotin Kette Oddelek za odrasle Ilirska Bistrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dop.iz.Univerzum Lj. Ljudska univerza Viktor Stražišar Jesenice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Veržej', naslov: 'Puščenjakova ulica 7', posta: '9241', kraj: 'Veržej' },
    { naziv: 'Osnovna šola Kungota, Enota Vrtec Zgornja Kungota', naslov: 'Plintovec 10 C', posta: '2201', kraj: 'Zgornja Kungota' },
    { naziv: 'Osnovna šola Radlje ob Dravi Podružnica Remšnik', naslov: 'Remšnik 5', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Šalovci, Enota Vrtca Šalovci', naslov: 'Šalovci 172', posta: '9204', kraj: 'Šalovci' },
    { naziv: 'Osnovna šola Kobilje, enota Vrtec', naslov: 'Kobilje 33 A', posta: '9227', kraj: 'Kobilje' },
    { naziv: 'Osnovna šola Odranci, Vrtec Mavrica', naslov: 'Žitna ulica 1', posta: '9233', kraj: 'Odranci' },
    { naziv: 'Vrtec Pedenjped Novo mesto, VIDEK', naslov: 'Foersterjeva ulica 12', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA LIBOJE', naslov: 'Liboje 105', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA LEVEC', naslov: 'Levec 46 A', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'VVE pri OŠ Komenda - Moste, oddelek Moste', naslov: 'Moste 40', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Skupnost vzgojnoizobraževalnih zavodov Lendava', naslov: 'Kidričeva ulica 1', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Srednja šola Slovenska Bistrica (v ustanavljanju)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ljubljana Splošna in strokovna gimnazija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ljubljana Srednja kemijska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ljubljana Srednja lesarska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ljubljana Srednja strojna šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Waldorfska šola Ljubljana', naslov: 'Streliška ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Prebold, enota Vrtec', naslov: 'Na bazen 1', posta: '3312', kraj: 'Prebold' },
    { naziv: 'Osnovna šola Polje Podružnica Kašelj', naslov: 'Kašeljska cesta 119 A', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Osnovna šola Rudija Mahniča- Brkinca Pregarje, enota vrtec', naslov: 'Pregarje 18', posta: '6243', kraj: 'Obrov' },
    { naziv: 'VVE pri OŠ Vodice', naslov: 'Ob šoli 2', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Osnovna šola Orehek Kranj, Enota vrtca', naslov: 'Zasavska cesta 53 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Srednja šola Slovenska Bistrica', naslov: 'Ulica dr. Jožeta Pučnika 21', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Srednja tehniška in poklicna šola Trbovlje, Tehniška gimnazija', naslov: 'Šuštarjeva kolonija 7 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Srednja tehniška in poklicna šola Trbovlje Tehniška šola', naslov: 'Šuštarjeva kolonija 7 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Srednja tehniška in poklicna šola Trbovlje Poklicna šola', naslov: 'Šuštarjeva kolonija 7 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Izobraževalni center Piramida Maribor, Srednja šola za prehrano in živilstvo', naslov: 'Park mladih 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Biotehniški izobraževalni center Ljubljana, Živilska šola', naslov: 'Ižanska cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ekonomska in trgovska šola Brežice', naslov: 'Bizeljska cesta 45', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Osnovna šola Hruševec Šentjur, Podružnična šola Kalobje', naslov: 'Kalobje 15', posta: '3233', kraj: 'Kalobje' },
    { naziv: 'Gimnazija Jurija Vege Idrija, Gimnazija', naslov: 'Študentovska ulica 16', posta: '5280', kraj: 'Idrija' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA TRJE', naslov: 'Galicija 18 E', posta: '3310', kraj: 'Žalec' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA ŠEMPETER', naslov: 'Starovaška ulica 17', posta: '3311', kraj: 'Šempeter v Savinjski dolini' },
    { naziv: 'Osnovna šola Trzin, Enota vrtec Žabica', naslov: 'Mengeška cesta 7 B', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Viški vrtci, enota Hiša pri ladji', naslov: 'Skapinova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Jurija Vege Idrija, Poklicna šola', naslov: 'Študentovska ulica 16', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Zasebna umetniška in splošna gimnazija Alojz Gradnik Gorica (zasebni zavod)', naslov: 'Kidričeva ulica 36', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Šolski center Ljubljana', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Miklavž na Dravskem polju, Vrtec Vrtiljak', naslov: 'Cesta v Dobrovce 23', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'Osnocna šola Selnica ob Dravi - enota vrtec', naslov: 'Mariborska cesta 28', posta: '2352', kraj: 'Selnica ob Dravi' },
    { naziv: 'Avto moto društvo Ajdovščina Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Dušana Flisa Hoče, VVE Rogoza', naslov: 'Rogoška cesta 38', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'Združenje staršev in otrok Sonček Šentjernej', naslov: 'Dolenja Stara vas 55', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Društvo Ringa raja', naslov: 'Ragovo 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'PETKA, šolske storitvene dejavnosti Žalec', naslov: 'Ulica Ivanke Uranjek 2', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Šolski center Ljubljana, Gimnazija Antona Aškerca', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Ljubljana, Srednja kemijska šola', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Ljubljana, Srednja lesarska šola', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Ljubljana, Srednja strojna in kemijska šola', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Ljubljana Šolske delavnice tehniških šol', naslov: 'Barjanska cesta 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja tehniška šola Koper, Poklicna in strokovna šola', naslov: 'Izolska vrata 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Srednja ekonomsko - poslovna šola Koper, Strokovna gimnazija', naslov: 'Martinčev trg 3', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Srednja ekonomsko - poslovna šola Koper, Poklicna in strokovna šola', naslov: 'Martinčev trg 3', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Tehniški šolski center Nova Gorica, Tehniška gimnazija', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Šolski center Nova Gorica, Elektrotehniška in računalniška šola', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'VVS - Fakulteta za računalništvo in informatiko Ljubljana', naslov: 'Tržaška cesta 25', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Beltinci, enota Lipovci', naslov: 'Lipovci 274', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Mehurčki', naslov: 'Dravinjska cesta 28', posta: '2319', kraj: 'Poljčane' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Pikapolonica', naslov: 'Kajuhova ulica 6', posta: '2319', kraj: 'Poljčane' },
    { naziv: 'Osnovna šola Veliki Gaber, enota Vrtec', naslov: 'Veliki Gaber 41', posta: '8213', kraj: 'Veliki Gaber' },
    { naziv: 'Osnovna šola Turnišče, VVE Turnišče', naslov: 'Prešernova ulica 2', posta: '9224', kraj: 'Turnišče' },
    { naziv: 'VVE pri OŠ Rodica', naslov: 'Kettejeva ulica 13', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Franceta Prešerna Črenšovci, Enota Vrtec Črenšovci', naslov: 'Ulica Juša Kramarja 10', posta: '9232', kraj: 'Črenšovci' },
    { naziv: 'Osnovna šola Dobrna, Enota Vrtec Dobrna', naslov: 'Dobrna 1', posta: '3204', kraj: 'Dobrna' },
    { naziv: 'Šolski center Ljubljana Šolske delavnice tehniških šol', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ekonomska in trgovska šola Brežice, Poklicna in strokovna šola', naslov: 'Bizeljska cesta 45', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Poslovno-komercialna šola Celje, Poklicna in strokovna šola', naslov: 'Kosovelova ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'Tehniški šolski center Kranj, Strokovna in poklicna šola', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Tehniški šolski center Kranj, Strokovna gimnazija', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center za pošto, ekonomijo in telekomunikacije Ljubljana, Srednja tehniška in strokovna šola', naslov: 'Celjska ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja vzgojiteljska šola in gimnazija Ljubljana, Gimnazija', naslov: 'Kardeljeva ploščad 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja elektro-računalniška šola Maribor, Poklicna in strokovna šola', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja elektro-računalniška šola Maribor, Strokovna gimnazija', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ekonomska šola Novo mesto, Poklicna in strokovna šola', naslov: 'Ulica talcev 3 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Ekonomska šola Novo mesto, Strokovna gimnazija', naslov: 'Ulica talcev 3 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Trzin', naslov: 'Ploščad dr. Tineta Zajca 1', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Javni zavod Osnovna šola Marjana Nemca Radeče, Enota Vrtec', naslov: 'Šolska pot 9', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Poslovno-komercialna šola Celje, Višja strokovna šola', naslov: 'Mariborska cesta 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'Ekonomska šola Murska Sobota, Višja strokovna šola', naslov: 'Noršinska ulica 13', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Višja strokovna šola za gostinstvo Maribor', naslov: 'Zagrebška cesta 18', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Glasbena šola Radeče Podružnica Laško', naslov: 'Trg svobode 6', posta: '3270', kraj: 'Laško' },
    { naziv: 'HIŠA OTROK MALI PRINC storitve d.o.o.', naslov: 'Triglavska ulica 5', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Kebelj', naslov: 'Kebelj 23', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Srednja tehniška in poklicna šola Trbovlje, Poklicna in tehniška šola', naslov: 'Šuštarjeva kolonija 7 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Ljudska univerza Nazarje', naslov: 'Savinjska cesta 2', posta: '3331', kraj: 'Nazarje' },
    { naziv: 'Ljudska univerza Občine Šentjur', naslov: 'Mestni trg 5', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Šolski center Slovenske Konjice - Zreče', naslov: 'Škalska cesta 7', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'MOCIS, Center za izobraževanje odraslih', naslov: 'Meškova ulica 21', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji grad, Podružnica Bočna', naslov: 'Bočna 53', posta: '3342', kraj: 'Gornji Grad' },
    { naziv: 'Glasbena šola Koper Podružnica Koper', naslov: 'Gallusova ulica 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Zavod za usposabljanje invalidne mladine Kamnik, OE Osnovna šola', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Center za izobraževanje, rehabilitacijo in usposabljanje Kamnik, Srednja šola', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Zavod za usposabljanje invalidne mladine Kamnik, OE Dom', naslov: 'Novi trg 43 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Ivana Cankarja Ljutomer Enota s prilagojenim programom', naslov: 'Cvetka Golarja ulica 6', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'OŠPP pri Kranjskih vrtcih', naslov: 'Cesta Staneta Žagarja 19', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Mežica, Enota vrtec Mežica', naslov: 'Partizanska cesta 16', posta: '2392', kraj: 'Mežica' },
    { naziv: 'VVE pri OŠ Nazarje', naslov: 'Zadrečka cesta 37', posta: '3331', kraj: 'Nazarje' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana Oddelek za farmacijo', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMD Lendava', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Riba d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Bubnič d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Bolit d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Mister X d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola 3D d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Prah Leopold in posredništvo d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtošola Polo plus', naslov: '', posta: '', kraj: '' },
    { naziv: 'Tehniški šolski center Nova Gorica, Srednja strojna in prometna šola', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Lesarska šola Maribor, Srednja poklicna in strokovna šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Tinje', naslov: 'Veliko Tinje 30', posta: '2316', kraj: 'Zgornja Ložnica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Šmartno', naslov: 'Šmartno na Pohorju 24 A', posta: '2315', kraj: 'Šmartno na Pohorju' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Zgornja Ložnica', naslov: 'Zgornja Ložnica 43', posta: '2316', kraj: 'Zgornja Ložnica' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Strokovna gimnazija', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Srednja poklicna in strokovna šola', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Srednja gradbena, geodetska in ekonomska šola Ljubljana, Srednja poklicna šola', naslov: 'Dunajska cesta 102', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja gradbena, geodetska in ekonomska šola Ljubljana, Srednja strokovna šola', naslov: 'Dunajska cesta 102', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ekonomsko-storitveni izobraževalni center Kranj, Gimnazija', naslov: 'Kidričeva cesta 65', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Ekonomsko-storitveni izobraževalni center Kranj, Ekonomsko-trgovska šola', naslov: 'Cesta Staneta Žagarja 33', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Franca Lešnika - Vuka Slivnica pri Mariboru, Enota Vrtec', naslov: 'Mariborska cesta 4', posta: '2312', kraj: 'Orehova vas' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor, enota vrtca Lenka', naslov: 'Oblakova ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Radlje ob Dravi Podružnica Sv. Anton na Pohorju', naslov: 'Sv. Anton na Pohorju 59', posta: '2365', kraj: 'Vuhred' },
    { naziv: 'Osnovna šola Radlje ob Dravi Podružnica Sv. Trije kralji', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Toneta Šraja Aljoše, enota Vrtec Nova vas', naslov: 'Nova vas 4 B', posta: '1385', kraj: 'Nova Vas' },
    { naziv: 'OŠ Črna na Koroškem Vrtec Črna na Koroškem', naslov: 'Lampreče 31', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'ERUDIO izobraževalni center', naslov: 'Litostrojska cesta 40', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Antona Martina Slomška Vrhnika', naslov: 'Pod Hruševco 33', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Osnovna šola Rečica ob Savinji', naslov: 'Rečica ob Savinji 152', posta: '3332', kraj: 'Rečica ob Savinji' },
    { naziv: 'Visoka policijsko-varnostna šola Ljubljana', naslov: 'Kotnikova ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ISCG D.O.O. DOMŽALE', naslov: 'Cesta talcev 1', posta: '1230', kraj: 'Domžale' },
    { naziv: 'ŠOLSKI CENTER CELJE - IZOBRAŽEVANJE ODRASLIH', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja zdravstvena šola Celje', naslov: 'Ipavčeva ulica 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'ZAVOD ZA TEHNIČNO IZOBRAŽEVANJE', naslov: 'Ptujska ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Desetka Celje', naslov: 'Krekov trg 3', posta: '3000', kraj: 'Celje' },
    { naziv: 'Poslovna skupnost osnovnih šol Domžale', naslov: 'Ljubljanska cesta 89', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Glasbeni center Edgar Willems, zasebni zavod za glasbeno vzgojo in izobraževanje', naslov: 'Ob dolenjski železnici 182', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVZ Ilke Devetak Bignami Tolmin, enota Volče', naslov: 'Volče 97 C', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'JVIZ Vrtec Idrija, Enota Godovič', naslov: 'Godovič 35 B', posta: '5275', kraj: 'Godovič' },
    { naziv: 'Osnovna šola Miška Kranjca Velika Polana, Enota Vrtec', naslov: 'Velika Polana 215 B', posta: '9225', kraj: 'Velika Polana' },
    { naziv: 'VRTEC PRI OŠ PIVKA', naslov: 'Prečna ulica 5', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Šolski center Novo mesto, Srednja gradbena, lesarska in vzgojiteljska šola', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Šolski center Novo mesto, Srednja zdravstvena in kemijska šola', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Srednja šola Domžale Poklicna in tehniška strojna šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Domžale Poklicna in tehniška usnjarska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Domžale Šola za prodajalce', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja šola Josip Jurčič Ivančna Gorica Gimnazija', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Šolski center Brežice Gimnazija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Brežice Poklicna trgovska in srednja ekonomska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Velenje, Elektro in računalniška šola', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Najdihojca Starše', naslov: 'Starše 6', posta: '2205', kraj: 'Starše' },
    { naziv: 'Osnovni šoli Šmartno na Pohorju, Vrtec Šmartno na Pohorju', naslov: 'Šmartno na Pohorju 24 A', posta: '2315', kraj: 'Šmartno na Pohorju' },
    { naziv: 'Vrtec Škofja Loka, enota Čebelica', naslov: 'Podlubnik 1 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Škofja Loka, enota Pedenjped', naslov: 'Frankovo naselje 51 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Škofja Loka, enota Rožle', naslov: 'Frankovo naselje 61', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Fakulteta za gradbeništvo Maribor', naslov: 'Smetanova ulica 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Fakulteta za kemio in kem.tehnologijo Maribor', naslov: 'Smetanova ulica 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Filozofska fakulteta Ljubljana', naslov: 'Aškerčeva cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ekonomska fakulteta Ljubljana', naslov: 'Kardeljeva ploščad 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Pravna fakulteta Ljubljana', naslov: 'Kongresni trg 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za družbene vede Ljubljana', naslov: 'Kardeljeva ploščad 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za šport Ljubljana', naslov: 'Gortanova ulica 22', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za strojništvo Ljubljana', naslov: 'Aškerčeva cesta 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za elektrotehniko Ljubljana', naslov: 'Tržaška cesta 25', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za arhitekturo, gradbeništvo in geodezijo Šola za arhitekturo Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Fakulteta za arhitekturo Ljubljana', naslov: 'Zoisova cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za arhitekturo, gradbeništvo in geodezijo Odd.za gradb.in geodezijo', naslov: 'Jamova cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za  matematiko in fiziko', naslov: 'Jadranska ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana Oddelek za fiziko', naslov: 'Jadranska ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Fakulteta za naravoslovje in tehnologijo Ljubljana Oddelek za montanistiko', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gimnazija Celje - Center Srednja strokovna šola', naslov: 'Kosovelova ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja šola za elektrotehniko in računalništvo, Strokovna gimnazija', naslov: 'Vegova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola za elektrotehniko in računalništvo, Srednja strokovna šola', naslov: 'Vegova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Ljubljana Šiška, Gimnazija', naslov: 'Aljaževa ulica 32', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Ljubljana Šiška, Dijaški dom Šiška', naslov: 'Aljaževa ulica 32', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Grad, Enota Vrtec', naslov: 'Grad 172', posta: '9264', kraj: 'Grad' },
    { naziv: 'Osnovna šola Kuzma, Enota vrtec', naslov: 'Kuzma 20', posta: '9263', kraj: 'Kuzma' },
    { naziv: 'Vrtec pri OŠ Puconci', naslov: 'Puconci 178', posta: '9201', kraj: 'Puconci' },
    { naziv: 'OŠ Dekani, vrtec Dekani', naslov: 'Dekani 118', posta: '6271', kraj: 'Dekani' },
    { naziv: 'Vrtec Pedenjped Novo mesto, METKA', naslov: 'Kettejev drevored 5', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Pedenjped Novo mesto, OSTRŽEK', naslov: 'Ulica Danila Bučarja 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Pedenjped Novo mesto, PEDENJPED', naslov: 'Šegova ulica 22', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'VVE pri OŠ Miren', naslov: 'Miren 140', posta: '5291', kraj: 'Miren' },
    { naziv: 'VVE pri OŠ Franceta Prešerna Duplje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Koprivnica, Enota Vrtec', naslov: 'Koprivnica 2', posta: '8282', kraj: 'Koprivnica' },
    { naziv: 'Osnovna šola Toneta Pavčka, Vrtec Cepetavček', naslov: 'Postaja 1', posta: '8216', kraj: 'Mirna Peč' },
    { naziv: 'Osnovna šola Prevole, Vrtec Prevole', naslov: 'Prevole 32', posta: '8362', kraj: 'Hinje' },
    { naziv: 'Osnovna šola Šmarjeta, Enota Vrtec Sonček', naslov: 'Šmarjeta 1', posta: '8220', kraj: 'Šmarješke Toplice' },
    { naziv: 'VVE pri OŠ Šempas', naslov: 'Šempas 76 C', posta: '5261', kraj: 'Šempas' },
    { naziv: 'Osnovna šola Brusnice, vrtec Brusnice', naslov: 'Velike Brusnice 101', posta: '8321', kraj: 'Brusnice' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, Vrtec Zala', naslov: 'Trata 40', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'VVE pri OŠ Bogojina', naslov: '', posta: '', kraj: '' },
    { naziv: 'Viški vrtci', naslov: 'Jamova cesta 23', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE pri Dvojezični OŠ Prosenjakovci', naslov: 'Prosenjakovci 97 C', posta: '9207', kraj: 'Prosenjakovci - Pártosfalva' },
    { naziv: 'VVZ Mladinsko klimatsko zdravilišče Rakitna', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola 16. decembra Mojstrana, Enota Vrtec', naslov: 'Ulica Alojza Rabiča 7', posta: '4281', kraj: 'Mojstrana' },
    { naziv: 'VVZ Šentilj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Državni izpitni center', naslov: 'Podmilščakova ulica 25', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Republiški izpitni center', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum Ljubljana Ljudska univerza Ormož', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Gradec, Podružnična osnovna šola Vače', naslov: 'Vače 24', posta: '1252', kraj: 'Vače' },
    { naziv: 'Dijaški dom Trbovlje', naslov: 'Šuštarjeva kolonija 42', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Zavod za gluhe in naglušne Ljubljana', naslov: 'Vojkova cesta 74', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Louisa Adamiča Enota s prilagojenim učnim programom', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Louisa Adamiča Podružnica Polica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Dijaški dom Murska Sobota', naslov: 'Tomšičeva ulica 15', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Dijaški dom Ptuj', naslov: 'Arbajterjeva ulica 6', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Fara, enota Vrtec Fara', naslov: 'Fara 3', posta: '1336', kraj: 'Kostel' },
    { naziv: 'Vrtec Sežana, enota Tomaj', naslov: 'Tomaj 63', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Vrtec Semedela, enota Hrvatini', naslov: 'Hrvatini 137 A', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Dijaški dom Črnomelj', naslov: 'Ulica Otona Župančiča 7', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Srednja gostinska šola Bled Dom učencev', naslov: '', posta: '', kraj: '' },
    { naziv: 'Kmetijska in gospodinjska šola Šentjur Dijaški dom Šentjur', naslov: '', posta: '', kraj: '' },
    { naziv: 'Železniška srednja šola Ljubljana Dijaški dom', naslov: '', posta: '', kraj: '' },
    { naziv: 'Prometna šola Maribor, Dijaški dom', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše, Dijaški dom', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Visoka zdravstvena šola Maribor', naslov: 'Žitna ulica 15', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gradbeni institut ZRMK Gradbeni center (po koncesiji)', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum Ljubljana Ljudska univerza Sežana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Lj. Center za izobraževanje in kulturo Trebnje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum Ljubljana Ljudska univerza Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Lj. Zavod za izobraževanje in kulturo Črnomelj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Krško', naslov: 'Prešernova cesta 13', posta: '8270', kraj: 'Krško' },
    { naziv: 'JVIZ Vrtec Rogaška Slatina', naslov: 'Ulica Kozjanskega odreda 2', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Osnovna šola Vinica, Enota Vrtec', naslov: 'Vinica 50', posta: '8344', kraj: 'Vinica' },
    { naziv: 'DE Srednje gozdarske in lesarske šole Postojna, Vzgojni zavod Planina', naslov: 'Planina 142', posta: '6232', kraj: 'Planina' },
    { naziv: 'Posvetovalnica za učence in starše v Novem mestu', naslov: '', posta: '', kraj: '' },
    { naziv: 'Svetovalni center za otroke, mladostnike in starše v Ljubljani', naslov: 'Gotska ulica 18', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Svetovalni center za otroke, mladostnike in starše v Kopru', naslov: 'Cankarjeva ulica 33', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'INTERMEZZO Koncertna poslovalnica d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Ljubljana Srednja šola Domžale', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Stari trg ob Kolpi, Enota Vrtec', naslov: 'Stari trg ob Kolpi 7', posta: '8342', kraj: 'Stari trg ob Kolpi' },
    { naziv: 'Dvojezična osnovna šola Dobrovnik, enota Vrtec', naslov: 'Dobrovnik 266 J', posta: '9223', kraj: 'Dobrovnik - Dobronak' },
    { naziv: 'VVE pri OŠ Prežihov Voranc Bistrica (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA ŽALEC I.', naslov: 'Aškerčeva ulica 7', posta: '3310', kraj: 'Žalec' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA ŽALEC II.', naslov: 'Prežihova ulica 3', posta: '3310', kraj: 'Žalec' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA ŽALEC III.', naslov: 'Prežihova ulica 2', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Konservatorij za glasbo in balet Ljubljana', naslov: 'Ižanska cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Gradec Litija Podružnica Jevnica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Gradec Litija Podružnica Kresnice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Gradec Litija Podružnica Vače', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Litija Podružnica Konjšica (spremeba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Litija Podružnica Sava (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Litija Podružnica Polšnik (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Grad Podružnica Bodonci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Antona Janše Radovljica', naslov: 'Kranjska cesta 27 A', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola 8.talcev Podružnica Rovte', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola 8.talcev Podružnica Vrh', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Miklavž pri Ormožu Podružnica Kog', naslov: 'Kog 1', posta: '2276', kraj: 'Kog' },
    { naziv: 'Osnovna šola Videm pri Ptuju Podružnica Leskovec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Mladika Podružnica Vitomarci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Mladika Podružnica Trnovska vas', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Vuzenica Podružnica Branik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Vuzenica Podružnica Pernica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Primoža Trubarja Velike Lašče Podružnica Rob', naslov: 'Rob 1', posta: '1314', kraj: 'Rob' },
    { naziv: 'Osnovna šola Oskarja Kovačiča Podružnica Lavrica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Litija Enota s prilagojenim programom (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Stara cerkev Podružnica Struge', naslov: '', posta: '', kraj: '' },
    { naziv: 'Združene osnovne šole Celje Strokovne službe (v kategorijo 19)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Dobje pri Planini  (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Matija Valjavec Preddvor Podružnica Olševek', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Franceta Prešerna Kranj Podružnica Naklo', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Davorina Jenka Cerklje na Gorenjskem, Podružnica Zalog', naslov: 'Zalog pri Cerkljah 29', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Osnovna šola France Prešern Podružnica Podbrezje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola France Prešern Podružnica Duplje', naslov: '', posta: '', kraj: '' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA PONIKVA', naslov: 'Ponikva pri Žalcu 17', posta: '3310', kraj: 'Žalec' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA GRIŽE', naslov: 'Griže 1 A', posta: '3302', kraj: 'Griže' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA ZABUKOVICA', naslov: 'Pongrac 95 B', posta: '3302', kraj: 'Griže' },
    { naziv: 'RASG D.O.O. Izobraževalni center Slovenske gorice', naslov: 'Nikova ulica 9', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'OŠ dr. Jožeta Pučnika Črešnjevec, Vrtec Črešnjevec - Leskovec, enota Črešnjevec', naslov: 'Črešnjevec 47', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Koroška Bela  (sprememba št.ROS)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vzgojnoizobraževalni zavod Veržej', naslov: 'Puščenjakova ulica 1', posta: '9241', kraj: 'Veržej' },
    { naziv: 'Vzgojnoizobraževalni zavod Frana Milčinskega Smlednik', naslov: 'Valburga 5', posta: '1216', kraj: 'Smlednik' },
    { naziv: 'Vzgojno-izobraževalni zavod Višnja Gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Vzgojni zavod Kranj', naslov: 'Šempeterska ulica 3', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Vzgojni zavod Slivnica pri Mariboru', naslov: 'Polanska cesta 6', posta: '2312', kraj: 'Orehova vas' },
    { naziv: 'Zavod za vzgojo in izobraževanje Logatec', naslov: 'Tržaška cesta 63', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Mladinski dom Jarše Ljubljana', naslov: 'Jarška cesta 44', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod za gluhe in naglušne Ljubljana', naslov: 'Vojkova cesta 74', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za usposabljanje slepih in slabovidnih Škofja Loka', naslov: 'Stara Loka 31', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'OŠ v zavodu dr. Marka Gerbca', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mladinski dom Malči Beličeve Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Prva gimnazija Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne in obutvene šole Kranj Vzgojno-izobraževalni zavod', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE CSŠ T.V. TOZD Sr.rud.šola Sred.šola kov.,pedag. in ekonomske usmeritve', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje družboslovne ekonomske šole Nova Gorica DU Postojna', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske in trgovske šole Nova Gorica Gimnazija Jurija Vege Idrija', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske družboslovne šole Nova Gorica ŠC Vojvodina Tolmin', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje agr.šole Maribor TOZD Živilska šola Maribor DU Litija', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole tiska in papirja Ljubljana Delavska univerza Domžale', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. šole tehničnih strok in osebnih storitev Ljubljana Sred. teh.in zdr.šola N.mesto', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sr.tek.,obut.in gum.š.Kranj Center za dopisno izobraževan. Univerzum Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Dragomelj', naslov: 'Dragomelj 180', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Gimnazija Bežigrad, Gimnazija', naslov: 'Peričeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA PETROVČE', naslov: 'Petrovče 97', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'Vrtec Kočevje, enota BIBA', naslov: 'Livold 7', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Osnovna šola Gornja Radgona, enota s prilagojenim programom', naslov: 'Prežihova ulica 1', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Sred.agroživilska šola Maribor TOZD Kmetijska mehanizacija DE pri DU Ormož', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole za gostinstvo in turizem v Ljubljani, Vzgojno-izob.zavod Višnja Gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Vzgojno-izob.zavod Višnja Gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'DE Srednje kemijske šole Ruše Center za usmerjeno izobraževanje Žalec', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje lesarske šole Ljubljana Srednja šola Kočevje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje lesarske šole Maribor Srednja šola Slovenj Gradec', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje lesarske šole Lj. Srednja šola Rudolfa Maistra Kamnik', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Sred. strojne šole Trbovlje Zavod za izobr. in kulturo Litija Delavska univerza', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje šole B.Kidrič Celje DU Velenje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje kovinarske in prometne šole Koper DU Sežana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Centra za dopisno izobr. Univerzum   Center za izobr. in kulturo Trebnje', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje trgovske šole Lj. Srednja šola Josip Jurčič Ivančna Gorica', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje ekonomske in družboslovne šole Koper Srednja šola S.Kosovel Sežana', naslov: '', posta: '', kraj: '' },
    { naziv: 'DE Srednje tekstilne, obutvene in gumarske šole Kranj Srednješolski center Ptuj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Babiča - Jagra Marezige, Enota Vrtec', naslov: 'Marezige 33 A', posta: '6273', kraj: 'Marezige' },
    { naziv: 'Osnovna šola Simona Jenka Kranj, Enota Vrtec', naslov: 'Ulica XXXI. divizije 7 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VVE Primskovo pri OŠ Simon Jenko, Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'VVE pri OŠ Dekani', naslov: 'Dekani 118', posta: '6271', kraj: 'Dekani' },
    { naziv: 'VVE pri OŠ Senovo', naslov: 'Trg XIV. divizije 4', posta: '8281', kraj: 'Senovo' },
    { naziv: 'VVE pri OŠ Primoža Trubarja Velike Lašče', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Stročja vas, Enota Vrtec', naslov: 'Stročja vas 101', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Srednja šola Veno Pilon Ajdovščina, Poklicna in strokovna šola', naslov: 'Cesta 5. maja 12', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'VRTEC ŠEMPAS', naslov: 'Šempas 76', posta: '5261', kraj: 'Šempas' },
    { naziv: 'Zavod sv. Stanislava, glasbena šola', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Waldorfska šola Ljubljana, Enota glasbena šola', naslov: 'Streliška ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebna glasbena šola v samostanu sv. Petra in Pavla', naslov: 'Minoritski trg 1', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Miren, Enota vrtec Kostanjevica', naslov: 'Kostanjevica na Krasu 66', posta: '5296', kraj: 'Kostanjevica na Krasu' },
    { naziv: 'III. gimnazija Maribor Gimnazija', naslov: 'Gosposvetska cesta 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'III. gimnazija Maribor Srednja strokovna šola', naslov: 'Gosposvetska cesta 4', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gimnazija Celje - Center Gimnazija', naslov: 'Kosovelova ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'SI-CAR, avto šola in servisno podjetje Litija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zebra d.o.o., storitveno podjetje Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Svetovalno izobraževalni enter Slovenske Konjice Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'GO - GO d.o.o. Avto šola GO - GO', naslov: '', posta: '', kraj: '' },
    { naziv: 'Franc Žižek s.p. Avto šola Semafor', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto Oman d.o.o. Avto šola Avto Oman', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Herby d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marvo d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'PEMI, Avto šola in prevozi d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'OŠ in vrtec Škofljica enota vrtec', naslov: 'Klanec 5', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Obvezna smer d.o.o. Gornja Radgona', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto Almira d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Acto šola Pek in Videčnik Zgornja Polskava', naslov: '', posta: '', kraj: '' },
    { naziv: 'Izobraževalni center Memory d.o.o. Višja strokovna šola', naslov: 'Ferrarska ulica 30', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Avto šola Max Trebnje podvojeni vpis', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Šic d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sanax Interier d.o.o Mengeš', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mister X d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zlatko Markovič s.p. Avto šola Nada Tours Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Auriga d.o.o. Avto šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Alojz Ozbetič s.p. Maxima Črenšovci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Herbby d.o.o. Ribnica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Krkovič d.o.o. Kočevje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Po-lo d.o.o. Logatec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vzgojnovarstvena enota Nina Vir pri Domžalah', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtošola Lisjak Dragan Petrovič s.p. Logatec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Leila d.o.o.; Višja strokovna šola za poslovne sekretarje Brezovica', naslov: 'Mencingerjeva ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ravne na Koroškem, ENOTA LEVI DEVŽEJ', naslov: 'Kotlje 11', posta: '2394', kraj: 'Kotlje' },
    { naziv: 'Vrtec Pedenjped, enota Učenjak', naslov: 'Cesta II. grupe odredov 41', posta: '1261', kraj: 'Ljubljana - Dobrunje' },
    { naziv: 'Vrtec Trbovlje, Enota Pikapolonica', naslov: 'Rudarska cesta 10 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'K & D d.o.o. Avto šola K & D', naslov: '', posta: '', kraj: '' },
    { naziv: 'Jorgič, storitve, proizvodnja in trgovina d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Antlej, avto šola d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto storitve in izobraževanje Novo mesto d.o.o', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Prednost d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtobusni promet Murska Sobota d.d.; Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mc Kensie, turizem in storitve d.o.o. - Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo TAM Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Motomentor d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Eros d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Urška d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Cerknica d.o.o., avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Kozmodent d.o.o. Višja strokovna šola za ustne higienike v ustanavljanju', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMT d.o.o. Trbovlje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtotrade d.o.o. Sinja Gorica 11 Vrhnika', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Slovenj Gradec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mega moto d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola in prevozi Benjamin Jožef Ščap s.p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Prednost, avto šola, trgovina in storitve Bresnica 5a, Ormož', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marjan Kolar, d.o.o. Cesta III/8, Velenje', naslov: '', posta: '', kraj: '' },
    { naziv: 'AMD Asta d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Jus-Security, varnostna, detektivska in intervencijska služba d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola ing. Humar d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Andragoški zavod Maribor Ljudska univerza', naslov: 'Maistrova ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Daba, podjetje za trgovino in storitve d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Maxima, Alojz Ozbetič, avto šola in zastopstva s.p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Branko Kos d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Gorica Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Združenje šoferjev in avtomehanikov Celje Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Anton Rosenstein s.p. Avto šola Traffic', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marko Krašovec s.p. Arclin 4, Škofja vas Avto šola Mark', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Koper, Enota Vanganel-Pikapolonica', naslov: 'Bonini 1 A', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Trbovlje, Enota Ciciban', naslov: 'Trg revolucije 4 C', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'DAP, avto šola d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Pinko Tomažič Piran Avto šola AMD Piran', naslov: '', posta: '', kraj: '' },
    { naziv: 'Milan Velički s.p. Avto šola Intercar', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Bela Krajina Črnomelj Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Fortuna d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ida, avto šola Črnomelj d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Izletnik Celje d.d. prometno, gostinsko in turistično podjetje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Zala d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mikanec, podjetje za vzgojo, izobraževanje in usposabljanje voznikov motornih vozil', naslov: '', posta: '', kraj: '' },
    { naziv: 'Združenje šoferjev in avtomehanikov Domžale Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Roal Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sana, avto šola in računovodske storitve', naslov: '', posta: '', kraj: '' },
    { naziv: 'Rahimič Mustafa s.p. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Stop d.o. o., podjetje za trženje in storitve', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Super finish Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Policijska akademija, Srednja policijska šola Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Terzič & Bektaš (Bešić) d.n.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Timit Ljubljana Simona Kumer s.p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Varnost Bežigrad Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Vezi d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vinko Žužek, Knafljev prehod 13, Ribnica d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Voyage d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Voznik Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Zveza šoferjev in avtomehanikov Grosuplje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mi amigo d.o. o. Izobraževanje in druge storitve', naslov: 'Slovenska cesta 28', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'Avto šola Alt inženiring d.o.o Logatec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtoprevozništvo in servisi Koroška 64, Velenje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ferdinand Rakuša, s. p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Rajko Bončina, s. p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Lenart, Enota vrtec Lenart', naslov: 'Ptujska cesta 25', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'Vrtec Litija, Enota Taček', naslov: 'Zgornji Hotič 32', posta: '1270', kraj: 'Litija' },
    { naziv: 'Šolski center Slovenske Konjice - Zreče, Gimnazija Slovenske Konjice', naslov: 'Tattenbachova ulica 2 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Šolski center Slovenske Konjice - Zreče, Srednja poklicna in strokovna šola Zreče', naslov: 'Dravinjska cesta 1', posta: '3214', kraj: 'Zreče' },
    { naziv: 'Vrtec Galjevica', naslov: 'Galjevica 35', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Trnovo', naslov: 'Kolezijska ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Karla Destovnika-Kajuha Šoštanj Podružnica Ravne', naslov: 'Ravne 82', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Franceta Bevka Tolmin, Podružnična šola za izobraževanje in usposabljanje otrok s posebnimi potrebami v Tolminu', naslov: 'Dijaška ulica 12 B', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Šolski center Ptuj, Višja strokovna šola', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Šolski center Postojna, Gimnazija Ilirska Bistrica', naslov: 'Ulica IV. armije 1', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Osnovna šola dr. Ivana Korošca Borovnica, Enota Vrtec', naslov: 'Paplerjeva ulica 5', posta: '1353', kraj: 'Borovnica' },
    { naziv: 'Osnovna šola Janka Modra Dol pri Ljubljani, Enota Vrtec', naslov: 'Videm 17', posta: '1262', kraj: 'Dol pri Ljubljani' },
    { naziv: 'Osnovna šola Markovci, Enota Vrtec', naslov: 'Markovci 33 D', posta: '2281', kraj: 'Markovci' },
    { naziv: 'Vrtnarska šola Celje', naslov: 'Ljubljanska cesta 97', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šola za hortikulturo in vizualne umetnosti Celje, Višja strokovna šola', naslov: 'Ljubljanska cesta 97', posta: '3000', kraj: 'Celje' },
    { naziv: 'ABIS podjetje za izobraževanje, d.o.o., Ljubljana', naslov: 'Kantetova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'AGORA, podjetje za ustvarjalne komunikacije d.o.o.', naslov: 'Parmova ulica 53', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Avto šola Zdruiženje šoferjev in avtomehanikov Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola  Avto moto društvo Zvezda Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'ALFABET, izobraževanje, raziskovanje in intelektualne storitve, d.o.o.', naslov: 'Ob Koprivnici 5', posta: '3000', kraj: 'Celje' },
    { naziv: 'ANIMACIJA podjetje za izobraževanje, razvoj in storitve d.o.o.', naslov: 'Aškerčeva ulica 1', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'AS-ASISTENT poslovno in kadrovsko svetovanje ter izobraževanje d.o.o.', naslov: 'Glavni trg 17 B', posta: '2000', kraj: 'Maribor' },
    { naziv: 'ASPINO, agencijaza svetovanje, posredovanje, izobraževanje, nadzor in ocenjevanje d.o.o.', naslov: 'Vanganel 62', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'AVTOŠOLA BULC d.o.o. družba za šolanje voznikov', naslov: 'Kajuhova ulica 32 B', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'BABYLON, jezikovno izobraževanje in svetovanje d.o.o.', naslov: 'Komenskega ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'CTJ, izobraževalni center za tuje jezike, d.d.', naslov: 'Vilharjeva cesta 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'EDUKA, Zavod za izobraževanje in svetovanje, Slovenj Gradec', naslov: 'Gubčeva ulica 7', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Evropa, podjetje za poučevanje tujih jezikov in prevajanje Bled, d.o.o.', naslov: 'Finžgarjeva cesta 15', posta: '4260', kraj: 'Bled' },
    { naziv: 'GIMNAZIJA VITEZ A za odrasle, splošna smer, Ljubljana', naslov: 'Scopolijeva ulica 18 B', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'GLOTTA NOVA center za novo znanje, d.o.o.', naslov: 'Ulica Carla Benza 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'KADRING - kadrovsko in poslovno svetovanje, d.o.o.', naslov: 'Trg svobode 26', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Izobraževalni center energetskega sistema Višja strokovna šola', naslov: 'Letališka cesta 16 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Izobraževalni center Memory, Organizacijska enota v Kopru', naslov: 'Ferrarska ulica 30', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Javni zavod za šport, izobraževanje odraslih in mladino Ljutomer', naslov: 'Ormoška cesta 2', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'JEZIKOVNI CENTER PALATIN, družba za jezikovno izobraževanje d.o.o.', naslov: 'Dunajska cesta 51', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'KRONA PLUS,podjetje za tečaje tujih jezikov, d.o.o.', naslov: 'Tržaška cesta 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Lingula, jezikovni center d.o.o.', naslov: 'Šmartinska cesta 152', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod LINGVA izobraževanje in svetovanje', naslov: 'Ložane 12 B', posta: '2231', kraj: 'Pernica' },
    { naziv: 'Ljudska univerza Tržič', naslov: 'Šolska ulica 2', posta: '4290', kraj: 'Tržič' },
    { naziv: 'MADE Poslovne storitve, d.o.o.', naslov: 'Gregorčičeva ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ministrstvo za javno upravo', naslov: 'Tržaška cesta 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MINT Intelektualne storitve d.o.o. Ljubljana', naslov: 'Kersnikova ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MITRA izobraževanje in poslovne storitve, d. o. o.', naslov: 'Poljanska cesta 73', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MUCH, izobraževanje, d.o.o.', naslov: 'Komenskega ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'NEW COLLEGE, Izobraževanje d.o.o.', naslov: 'Med ogradami 11 A', posta: '5250', kraj: 'Solkan' },
    { naziv: 'ODIN, organiziranje dejavnosti intelektualne narave d.o.o.', naslov: 'Stegne 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PANTEON COLLEGE,  šola za tuje jezike, d.o.o.', naslov: 'Škrabčeva ulica 31', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Posoški razvojni center', naslov: 'Trg tigrovcev 1', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'PRAH, izobraževalni center, izobraževanje in usposabljanje, d.o.o.', naslov: 'Kidričeva ulica 28', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'PROGRESS, jezikovni tečaji d.o.o.', naslov: 'Seljakovo naselje 33', posta: '4000', kraj: 'Kranj' },
    { naziv: 'PROMETEJ INŽENIRING Izobraževanje in oblikovanje, d.o.o. Ljubljana', naslov: 'Cankarjeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'SMERI izobraževanje, svetovanje in razvoj d.o.o.', naslov: 'Koroška cesta 14', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'SPEKTRA-JAMEX, trgovsko podjetje, d.o.o. Celje', naslov: 'Malgajeva ulica 10', posta: '3000', kraj: 'Celje' },
    { naziv: 'SREDIŠČE ROTUNDA, primorski družbeni center, Koper', naslov: 'Vojkovo nabrežje 23', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Svarog - izobraževalni zavod', naslov: 'Ulica Planinčevih 24', posta: '2204', kraj: 'Miklavž na Dravskem polju' },
    { naziv: 'TIN Ljubljana, Zavod za svetovanje in izobraževanje', naslov: 'Lepodvorska ulica 23 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'TONSON, šola tujih jezikov d.o.o.', naslov: 'Kandijska cesta 63', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Univerza v Ljubljani, Fakulteta za pomorstvo in promet', naslov: 'Pot pomorščakov 4', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'YURENA izobraževanje, turizem in trgovina, Novo mesto, d.o.o.', naslov: 'Glavni trg 11', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'ZARIS,Zavod za razvoj, izobraževanje in svetovanje', naslov: 'Podjunska ulica 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'B2 izobraževanje in informacijske storitve, d.o.o.', naslov: 'Tržaška cesta 42', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Avto šola Pek - Videčnik Zgornja Polskava', naslov: '', posta: '', kraj: '' },
    { naziv: 'ABS storitve d.o.o. Radovljica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Prometno varnostni center', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Štart, inštruktaža voznikov d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Cosmos, trgovsko in servisno podjetje d.o.o,.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Ptuj Tehniška kmetijska šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Stenvent inštruktaža in servis d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja kmetijska šola Rakičan', naslov: 'Lendavska ulica 3', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Dejan Parežanin s.p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Breza, hortikulturni inženiring, proizvodnja in trgovina d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marko, poslovno svetovanje d.o', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMD Velenje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marus d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Anton Prah, s.p. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Slovenska Bistrica Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'A.Z.V., avto šola in trgovina d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Rudolf Zupančič d.o.o. Avto šola Aša', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Alma Veržej d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Voznik, podjetje za izobraževa nje voznikov motornih vozil - avto šola, d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Boris Kidrič Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Stanislav Voglar, s.p. Lenart', naslov: '', posta: '', kraj: '' },
    { naziv: 'Boris Gole s.p. Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Vodmat', naslov: 'Korytkova ulica 24', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Miškolin', naslov: 'Novo Polje, cesta VI 1', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'VRTEC VIŠKI GAJ', naslov: 'Reška ulica 31', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Pod Gradom', naslov: 'Praprotnikova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Kolezija', naslov: 'Pod bukvami 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Dr. France Prešeren', naslov: 'Erjavčeva cesta 29', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Vrhovci', naslov: 'Vrhovci, cesta XIX 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Gimnazija Celje - Center, Gimnazija', naslov: 'Kosovelova ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Krško - Sevnica, Srednja poklicna in strokovna šola Krško', naslov: 'Cesta krških žrtev 131', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Studenice', naslov: 'Studenice 53', posta: '2319', kraj: 'Poljčane' },
    { naziv: 'Glasbena šola Litija - Šmartno', naslov: 'Ljubljanska cesta 3', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Šoštanj Podružnica Zavodnje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Karla Destovnika-Kajuha Šoštanj', naslov: 'Koroška cesta 7', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Osnovna šola Karla Destovnika-Kajuha  Šoštanj Podružnica Topolšica', naslov: 'Topolšica 98', posta: '3326', kraj: 'Topolšica' },
    { naziv: 'Prometna šola Maribor', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Prometna šola Maribor, Višja prometna šola', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtnarska šola Celje, Višja strokovna šola', naslov: 'Ljubljanska cesta 97', posta: '3000', kraj: 'Celje' },
    { naziv: 'Tehniški šolski center Kranj, Višja strokovna šola', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Škofja Loka, Višja strokovna šola', naslov: 'Podlubnik 1 B', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'B2 d.o.o., OE VSŠ Primorska - zasebna šola', naslov: 'Obala 22', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Višja policijska šola', naslov: 'Rocenska ulica 56', posta: '1211', kraj: 'Ljubljana - Šmartno' },
    { naziv: 'ZARIS, Zavod za razvoj, izobraževanje in svetovanje, Višja strokovna šola', naslov: 'Litostrojska cesta 40', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'INTER-ES d.o.o., Višja strokovna šola', naslov: 'Cesta na Brdo 85', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'INTER-ES d.o.o., enota VSŠ v Črnomlju', naslov: 'Zadružna cesta 1', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Izobraževalni zavod HERA, Višja strokovna šola', naslov: 'Kržičeva ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'LAMPRET CONSULTING d.o.o. Višja  strokovna šola', naslov: 'Ulica tolminskih puntarjev 4', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Izobraževalno razvojni zavod IZRAZ Višja strokovna šola', naslov: 'Poklukarjeva ulica 70', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Izobraževalni center MEMORY, izobraževanje in poslovne storitve, d.o.o., OE Višja strokovna šola v Dutovljah', naslov: 'Dutovlje 32', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Zavod IRC Višja strokovna šola', naslov: 'Litostrojska cesta 40', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'LEILA, Višja strokovna šola d.o.o.', naslov: 'Mencingerjeva ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'GEA College CVŠ, Družba za višješolsko izobraževanje - Center višjih šol, d.o.o.', naslov: 'Dunajska cesta 156', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ACADEMIA Višja strokovna šola', naslov: 'Glavni trg 17 B', posta: '2000', kraj: 'Maribor' },
    { naziv: 'ELEKTRO SLOVENIJA, d.o.o., Izobraževalni center elektrogospodarstva Slovenije, Višja strokovna šola za elektroenergetiko', naslov: 'Hajdrihova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ELEKTRO SLOVENIJA, d.o.o.,a Višje strokovne šole za elektroenergetiko v Ljubljani', naslov: 'Hajdrihova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'B2 Izobraževanje in informacijske storitve d. o. o., Višja strokovna šola', naslov: 'Tržaška cesta 42', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'EDC - zavod za strokovno izobraževanje, Kranj, Višja strokovna šola', naslov: 'Gorenjesavska cesta 9', posta: '4000', kraj: 'Kranj' },
    { naziv: 'B&B Izobraževanje in usposabljanje d.o.o., Višja strokovna šola v Kranju', naslov: 'Cesta Staneta Žagarja 27 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'B&B Izobraževanje in usposabljanje d.o.o., Višja strokovna šola v Ljubljani', naslov: 'Letališka cesta 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'GRI d.o.o., Višja strokovna šola', naslov: 'Trg na Stavbah 8', posta: '1270', kraj: 'Litija' },
    { naziv: 'MUCH, izobraževanje, d.o.o. Višja strokovna šola', naslov: 'Koprska ulica 94', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MINERVA, inštitut za spodbujanje razvoja osebnosti,  OE Višja šola', naslov: 'Valvazorjev dvor 13', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'EMONA EFEKTA, izobraževanje in svetovanje, d.o.o., Višja strokovna šola', naslov: 'Stegne 21 C', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Nova Gorica, Višja strokovna šola', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Prometna šola Maribor, Srednja prometna šola in dijaški dom', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Postojna, Višja strokovna šola', naslov: 'Cesta v Staro vas 2', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Šolski center Nova Gorica, Dijaški Dom', naslov: 'Delpinova ulica 9', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Prometna šola Maribor Višja prometna šola', naslov: 'Preradovičeva ulica 33', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec pri OŠ Trzin', naslov: 'Mengeška cesta 7 B', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Vrtec Pedenjped Novo mesto', naslov: 'Šegova ulica 22', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško Podružnica Debro', naslov: 'Poženelova ulica 26', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola F. S. Finžgarja Lesce, Podružnica Begunje', naslov: 'Begunje na Gorenjskem 27 B', posta: '4275', kraj: 'Begunje na Gorenjskem' },
    { naziv: 'Šolski center Novo mesto, Srednja šola Metlika', naslov: 'Partizanski trg 4', posta: '8330', kraj: 'Metlika' },
    { naziv: 'Center šolskih in obšolskih dejavnosti, Dijaški dom Tolmin', naslov: 'Dijaška ulica 14', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Center šolskih in obšolskih dejavnosti, Dijaški dom Kočevje', naslov: 'Cesta na stadion 5', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Šolski center Škofja Loka, Srednja šola za strojništvo', naslov: 'Podlubnik 1 B', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Šola za hortikulturo in vizualne umetnosti Celje, Srednja poklicna in strokovna šola', naslov: 'Ljubljanska cesta 97', posta: '3000', kraj: 'Celje' },
    { naziv: 'Tehniški šolski center Nova Gorica, Srednja lesarska in gradbena šola', naslov: 'Cankarjeva ulica 10', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'DE Srednje poklicne in strokovne šole Bežigrad-Ljubljana, Vzg.-izobr. zavod Višnja gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Vrtec Polhek', naslov: 'Cesta Notranjskega odreda 32', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Zavod Salesianum, OE Glasbena šola Rakovnik', naslov: 'Rakovniška ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'DE Srednje upravno - administrativne šole Ljubljana, Vzgojno-izobraževalni zavod Višnja gora', naslov: 'Cesta Dolenjskega odreda 19', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'Združenje šoferjev in avtome hanikov Ljutomer Avto šola ZŠAM', naslov: '', posta: '', kraj: '' },
    { naziv: 'IKA storitve v prometu d.o.o. Avto šola IKA', naslov: '', posta: '', kraj: '' },
    { naziv: 'I & I Avtobusni prevozi d.d. Avto šola I&I', naslov: '', posta: '', kraj: '' },
    { naziv: 'Kvasnik Cvetko s.p. Avto šola K & C', naslov: '', posta: '', kraj: '' },
    { naziv: 'Pi-Bip Mouse, avto šola in storitve d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto touring klub Koper Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Pevec Franc d.o.o. Avto šola Bodoči voznik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Modri dirkač d.o.o. Markišavci', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Novo mesto Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola in trgovina Savana d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Herak, podjetje za učenje vožnje z vozili na motorni pogon in opravljenje?', naslov: '', posta: '', kraj: '' },
    { naziv: 'Relax, podjetje za turizem, trgovino in svetovanje d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zlatko Kampl d.o.o. Zg. Hajdina Avto šola De&Ma', naslov: '', posta: '', kraj: '' },
    { naziv: 'Dual Expres d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zeleni val, avto šola, trgovina, export-import d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Postojna, Srednja šola', naslov: 'Cesta v Staro vas 2', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Brinovec d.o.o., avto šola in prevozi', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Mazzoni, prevozi in špedicija d.o.o. Avto šola Mazzoni', naslov: '', posta: '', kraj: '' },
    { naziv: 'Žniderič d.o.o. Avto šola Žniderič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Sepi, avto šola d.o.o. Vitanje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Peter - storitve in trgovina d.o.o. - Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo MTT', naslov: '', posta: '', kraj: '' },
    { naziv: 'Žilavec d.o.o. Avto šola Žilavec', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srednja kovinarska in cestno prometna šola Škofja Loka avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Trebnje Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto društvo Postojna Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avtohiša Strikovič d.o.o. Avto šola Strikovič', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ana Brigita Grobelnik s.p. Avto šola Brigita', naslov: '', posta: '', kraj: '' },
    { naziv: 'Božidar Knežic s.p. Avto šola Golf', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vremec, podjetje za izobraže- vanje, proizvodnjo ter storitv Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana, enota Čira čara', naslov: 'Belokranjska ulica 27', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Jesenice, DE Ivanke Krničar', naslov: 'Blejska Dobrava 44', posta: '4273', kraj: 'Blejska Dobrava' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Mlinček Oplotnica', naslov: 'Ulica Pohorskega bataljona 23 A', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Oplotnica I.', naslov: 'Ulica Pohorskega bataljona 19', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Prihova', naslov: 'Prihova 1', posta: '2317', kraj: 'Oplotnica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Ciciban', naslov: 'Tomšičeva ulica 1', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Čebelica', naslov: 'Tomšičeva ulica 12', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'VRTEC SEŽANA, enota Lokev', naslov: 'Lokev 124', posta: '6219', kraj: 'Lokev' },
    { naziv: 'Vrtec Sežana, enota Senožeče', naslov: 'Senožeče 103 A', posta: '6224', kraj: 'Senožeče' },
    { naziv: 'Vrtec Sežana, enota Divača', naslov: 'Kosovelova ulica 24', posta: '6215', kraj: 'Divača' },
    { naziv: 'Vrtec Sežana, enota Komen', naslov: 'Komen 61 B', posta: '6223', kraj: 'Komen' },
    { naziv: 'Vrtec Kočevje, Enota Kekec', naslov: 'Reška cesta 8', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Vrtec Kočevje, Enota Mojca', naslov: 'Stara Cerkev 20', posta: '1332', kraj: 'Stara Cerkev' },
    { naziv: 'JVIZ Mozirje, OE Vrtec Mozirje, DE Mozirje', naslov: 'Šolska ulica 25', posta: '3330', kraj: 'Mozirje' },
    { naziv: 'Zavod za razvoj waldorfske pedagogike Maribor', naslov: 'Wilsonova ulica 18', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Lucijana Bratkoviča Bratuša Renče, Vrtec pri OŠ Renče, enota Bukovica', naslov: 'Bukovica 44', posta: '5293', kraj: 'Volčja Draga' },
    { naziv: 'Vrtec Jarše, enota Mojca', naslov: 'Clevelandska ulica 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, dislocirani oddelek enote Sonček v Zibiki', naslov: 'Zibika 5', posta: '3253', kraj: 'Pristava pri Mestinju' },
    { naziv: 'Šolski center Celje, Gimnazija Lava', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Slovenj Gradec, Srednja ekonomska šola', naslov: 'Koroška ulica 11', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Šolski center Nova Gorica, Gimnazija', naslov: 'Delpinova ulica 9', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Odprt zavod zaradi raznih obdelav', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Nova Gorica, Biotehniška šola', naslov: 'Ulica padlih borcev 26', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'Šolski center Rudolfa Maistra Kamnik, Gimnazija', naslov: 'Novi trg 41 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Srednja šola Črnomelj, Gimnazija', naslov: 'Kidričeva ulica 18 A', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Srednja šola Črnomelj, Srednja poklicna in strokovna šola', naslov: 'Kidričeva ulica 18 A', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Gimnazija in ekonomska srednja šola Trbovlje, Gimnazija', naslov: 'Gimnazijska cesta 10', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Leskovec pri Krškem, Enota vrtec', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'JVIZ Vrtec Idrija, Enota Črni Vrh', naslov: 'Črni Vrh 87', posta: '5274', kraj: 'Črni vrh nad Idrijo' },
    { naziv: 'JVIZ Vrtec Idrija, Enota Prelovčeva', naslov: 'Prelovčeva ulica 11', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Osnovna šola Miren, Enota Vrtec Opatje selo', naslov: 'Opatje selo 9', posta: '5291', kraj: 'Miren' },
    { naziv: 'Srednja šola Domžale, Poklicna in strokovna šola', naslov: 'Cesta talcev 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Srednja šola Domžale, Gimnazija', naslov: 'Cesta talcev 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Šolski center Šentjur, Srednja poklicna in strokovna šola', naslov: 'Cesta na kmetijsko šolo 9', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Srednja poklicna in strokovna šola Ljubljana, Ptujska 6 Avto šola Ježica', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Srednja šola kmetijske mehanizacije Maribor Vrbanska c. 10, Maribor', naslov: '', posta: '', kraj: '' },
    { naziv: 'KOMTAR - PETROVIČ, storitve in izobraževanje, k.d Vrhnika', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola B&B Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMD Laško', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Jure d.o.o. Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola 1 Mah d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Adamovič in Ilič Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMTK Ljubljana Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Andrej Brulc s.p. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AS Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AVTO-CLUB Smlednik', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola ATS d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Blisk Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Jaguar d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Kasandra Medvode', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Kočija Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Korelat d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Larex Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola LMM Tilia Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Moste Avto šola AMD Moste Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola No 2 d.o.o. Notranje Gorice', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Nada Tours Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Omega-Aleks d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'VIZ Vrtec Mladi rod Ljubljana, enota Kostanjčkov vrtec', naslov: 'Peričeva ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Celje, Medpodjetniški izobraževalni center', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Avto šola P - Winner Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Polo - promet d.o.o. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Polžek Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Primax Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Promet Orel Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Prometej Inženiring Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Ressel - Selan & co. Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Šolski center Šentjur, Višja strokovna šola', naslov: 'Cesta na kmetijsko šolo 9', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Puconci Podružnica Brezovci', naslov: 'Brezovci 50', posta: '9201', kraj: 'Puconci' },
    { naziv: 'Osnovna šola Drska', naslov: 'Ulica Slavka Gruma 63', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Avto šola Ideal Ljubljana', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola COM.TAR Vrhnika (nezanesljivi podatki)', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Petrovče Podružnica Trje', naslov: 'Galicija 18 E', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Osnovna šola in vrtec Apače, Podružnica Stogovci', naslov: 'Stogovci 33', posta: '9253', kraj: 'Apače' },
    { naziv: 'Osnovna šola Cerkvenjak - Vitomarci, Podružnična šola Vitomarci', naslov: 'Vitomarci 42 B', posta: '2255', kraj: 'Vitomarci' },
    { naziv: 'Društvo šoferjev in avtomehanikov Maribor Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Krpan d.o.o. izobraževanje in svetovanje Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Rudi Lešnik d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ljudska univerza Gornja Radgona Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto touring d.o.o. Avto šola AMD', naslov: '', posta: '', kraj: '' },
    { naziv: 'Srečko Gostenčnik s.p. Vuhred Avto šola Felix', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto moto d.o.o., podjetje za prometno preventivo Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto Hrast, družba za prometno in transportno dejavnost d.o.o Avto šola Avto-Hrast', naslov: '', posta: '', kraj: '' },
    { naziv: 'Združenje šoferjev in avtome- hanikov Ptuj Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Stermecki, Izobraže- valno, trgovsko in prevozno podjetje d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto - moto društvo Idrija Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Alojzij Rajšelj s.p. Ivanja vas 26, Mirna Peč Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Alojz Zorko d.o.o. Avto šola Otto', naslov: '', posta: '', kraj: '' },
    { naziv: 'Forming, trgovina, gostinstvo, turizem in avto šola d.o.o. Avto šola Forming', naslov: '', posta: '', kraj: '' },
    { naziv: 'Zulić Asmir, avto šola, storitveno in trgovsko podjetje, s.p.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Biser, trgovina in storitve d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Stop; Čehič Braco, Fery in sod. d.n.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC MARJETICA, disloc. Muljava', naslov: 'Muljava 3 A', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Vrtec Anice Černejeve Celje, Enota Luna', naslov: 'Ljubljanska cesta 48', posta: '3000', kraj: 'Celje' },
    { naziv: 'Kmetijska in gospodinjska šola Šentjur Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Center d.o.o. Krško', naslov: '', posta: '', kraj: '' },
    { naziv: 'Čadej Milan s.p. Kasaze 98a, Petrovče Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mototempo, avto šola, storitve in trgovina d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Marjan Prevoršek, izobraževanje in prevozne storitve d.o.o.', naslov: '', posta: '', kraj: '' },
    { naziv: 'Anton Simšič, s.p. Avto šola Nino', naslov: '', posta: '', kraj: '' },
    { naziv: 'Kajdič Zvonko s.p. Avto šola Delta Murska Sobota', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Cik cak d.o.o. Tolmin', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Z.N. Victoria d.o.o. Domžale', naslov: '', posta: '', kraj: '' },
    { naziv: 'Praktikum d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Lončar d.o.o. Domžale', naslov: '', posta: '', kraj: '' },
    { naziv: 'AMG 2000 d.o.o. izobraževanje in storitve AMG 2000 Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ljudska univerza Lenart p.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Lidex d.o.o. Avto šola Lidex', naslov: '', posta: '', kraj: '' },
    { naziv: 'Matak d.o.o. Avto šola Matak Mengeš', naslov: '', posta: '', kraj: '' },
    { naziv: 'Ambrož Nikolovski d.n.o. Avto šola Ambrož & Nikolovski', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola AMD Šlander Celje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola ZUM d.o.o. Zagorje ob Savi', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto-club Podjetje za usposabljanje voznikov', naslov: '', posta: '', kraj: '' },
    { naziv: 'Bortan-Žagar & co. d.n.n. Avto šola Šmarca', naslov: '', posta: '', kraj: '' },
    { naziv: 'Branko Turnšek, avto šola in avtoprevozništvo s.p. Avto šola Turnšek', naslov: '', posta: '', kraj: '' },
    { naziv: 'Avto šola Larax d.o.o. Ruše', naslov: '', posta: '', kraj: '' },
    { naziv: 'Krajnc Zlatko d.o.o. Avto šola Krajnc Celje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mueller d.o.o. Avto šola Muller', naslov: '', posta: '', kraj: '' },
    { naziv: 'Študentski servis p.o. Avto šola Študentski servis', naslov: '', posta: '', kraj: '' },
    { naziv: 'Lotos L&B d.o.o. Avto šola Lotos', naslov: '', posta: '', kraj: '' },
    { naziv: 'Josip Rudman s.p. Avto šola Stop Škocjan', naslov: '', posta: '', kraj: '' },
    { naziv: 'SKPŠ & CO d.o.o. Avto šola SKPŠ', naslov: '', posta: '', kraj: '' },
    { naziv: 'Mobil marketing d.o.o. Avto šola Ruše', naslov: '', posta: '', kraj: '' },
    { naziv: 'Amigo Zreče d.o.o. Avto šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Slavko Žager s.p. Kandijska c. 43, Novo mesto Avto šola Max', naslov: '', posta: '', kraj: '' },
    { naziv: 'Josip Kujundžič d.o.o. Avto šola Josip', naslov: '', posta: '', kraj: '' },
    { naziv: 'VVZ Kekec Grosuplje enota Tinkara', naslov: 'Tovarniška cesta 12', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Litija, Enota Najdihojca', naslov: 'Cesta komandanta Staneta 18', posta: '1270', kraj: 'Litija' },
    { naziv: 'Šolski center Velenje, Višja strokovna šola', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'IZOBRAŽEVALNI CENTER ORGANOV ZA NOTRANJE ZADEVE', naslov: 'Rocenska ulica 56', posta: '1211', kraj: 'Ljubljana - Šmartno' },
    { naziv: 'B&B izobraževanje in usposabljanje d. o. o.', naslov: 'Cesta Staneta Žagarja 27 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'DOBA Evropsko poslovno izobraževalno središče', naslov: 'Prešernova ulica 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'TOP D.O.O.', naslov: 'Šercerjeva ulica 22', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'VITEZ A  LJUBLJANA', naslov: 'Ulica Ane Ziherlove 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'RUDNIK MEŽICA, POKLICNA RUDARSKA ŠOLA', naslov: 'Polena 6', posta: '2392', kraj: 'Mežica' },
    { naziv: 'FORMULA MARIBOR', naslov: 'Vrbanska cesta 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Zavod za izobraževanje odraslih Continua, Domžale', naslov: 'Cesta talcev 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'ABITURA, Podjetje za izobraževanje, d.o.o., Celje', naslov: 'Lava 7', posta: '3000', kraj: 'Celje' },
    { naziv: 'B2 d.o.o., Višja strokovna šola, enota Maribor', naslov: 'Glavni trg 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'ALEA družba za negospodarske in gospodarske storitve d.o.o., Ljubljana', naslov: 'Cesta Ljubljanske brigade 23 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'EDC - zavod za strokovno izobraževanje, Kranj', naslov: 'Gorenjesavska cesta 9', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Celje, Višja strokovna šola', naslov: 'Pot na Lavo 22', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Antona Žnideršiča Ilirska Bistrica, OE Ljudska univerza', naslov: 'Rozmanova ulica 25 B', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Šolski center Novo Mesto, Višja strokovna šola', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Izobraževalni center Piramida Maribor, Višja strokovna šola', naslov: 'Park mladih 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'ZAVOD IRC', naslov: 'Cesta Andreja Bitenca 68', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ekonomska in trgovska šola Brežice, Višja strokovna šola', naslov: 'Bizeljska cesta 45', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Srednja šola Zagorje', naslov: 'Cesta zmage 5', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Srednja šola Kočevje', naslov: 'Trg zbora odposlancev 22', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše (neakt.)', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše Gimnazija', naslov: '', posta: '', kraj: '' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše Poklicna in strokovna šola', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec pri OŠ Cankova', naslov: 'Cankova 27', posta: '9261', kraj: 'Cankova' },
    { naziv: 'Osnovna šola Koper', naslov: 'Cesta Zore Perello-Godina 1', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'ZAVOD ŠOLSKIH SESTER ND ILIRSKA BISTRICA, OE VRTEC ANTONINA', naslov: 'Vodnikova ulica 13', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Tehniški šolski center Maribor, Medpodjetniški izobraževalni center', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Primoža Trubarja Laško - Enota s prilagojenim programom', naslov: 'Valvasorjev trg 2', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Ivana Cankarja Vrhnika, Podružnica Drenov grič', naslov: 'Drenov Grič 25', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Waldorfska šola Ljubljana, enota Vrtec', naslov: 'Streliška ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Melodija zasebna glasbena šola Muta d.o.o.', naslov: 'Cankarjeva ulica 6', posta: '2366', kraj: 'Muta' },
    { naziv: 'Splošna bolnišnica dr. Franca Derganca   Odd. za invalid. mlad. Stara gora', naslov: 'Ulica padlih borcev 13 A', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'LANDIS , izobraževanje in poslovno svetovanje, d.o.o. Ljubljana', naslov: 'Šmartinska cesta 106', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'EMONA EFEKTA, izobraževanje in svetovanje, d.o.o.', naslov: 'Stegne 21 C', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Nova Gorica Podružnica Šempeter pri Gorici', naslov: 'Cesta Prekomorskih brigad 9', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'Glasbena šola Ljubljana Moste - Polje Podružnica Polje', naslov: 'Polje 21', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Glasbena šola Koper Podružnica Izola', naslov: 'Gregorčičeva ulica 76', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Glasbena šola Koper Podružnica Piran', naslov: 'Levstikova ulica 8', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Glasbena šola Idrija Podružnica Cerkno', naslov: 'Bevkova ulica 12', posta: '5282', kraj: 'Cerkno' },
    { naziv: 'Glasbena šola Franca Šturma Podružnica Bežigrad', naslov: 'Reboljeva ulica 18', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Franca Šturma Podružnica Črnuče', naslov: 'Dunajska cesta 398', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Glasbena šola Franca Šturma Podružnica Medvode', naslov: 'Cesta na Svetje 16', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Glasbena šola Franca Šturma Podružnica Šentvid', naslov: 'Prušnikova ulica 100', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Glasbena šola Franca Šturma Podružnica Zg.Šiška', naslov: 'Drenikova ulica 22', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Franca Šturma Ljubljana Podružnica Brinje', naslov: 'Zupanova ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JVVZ Vrtec Prevalje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Glasbena šola Sežana Podružnica Komen', naslov: 'Komen 61 A', posta: '6223', kraj: 'Komen' },
    { naziv: 'Višja strokovna šola za gostinstvo, velnes in turizem Bled', naslov: 'Prešernova cesta 32', posta: '4260', kraj: 'Bled' },
    { naziv: 'Grm Novo mesto - center biotehnike in turizma, Višja strokovna šola', naslov: 'Sevno 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Lesarska šola Maribor, Višja strokovna šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center za pošto, ekonomijo in telekomunikacije Ljubljana, Višja strokovna šola', naslov: 'Celjska ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'DOBA EPIS, d.o.o.', naslov: 'Prešernova ulica 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana, enota Ringaraja', naslov: 'Na peči 20 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Beltinci, enota Melinci', naslov: 'Melinci 64', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Vrtec Litija, Enota Kekec', naslov: 'Sava 21', posta: '1282', kraj: 'Sava' },
    { naziv: 'Ekonomska šola Novo mesto, Višja strokovna šola', naslov: 'Ulica talcev 3 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Šolski center Slovenj Gradec, Višja strokovna šola', naslov: 'Koroška ulica 11', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Avto šola Com-tar', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Danila Lokarja Ajdovščina Podružnica Budanje', naslov: 'Budanje 37', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Glasbena šola Sevnica', naslov: 'Cesta na grad 22', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'VVE pri OŠ Komenda - Moste', naslov: 'Glavarjeva cesta 35', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Osnovna šola Benedikt, Enota Vrtec', naslov: 'Šolska ulica 2', posta: '2234', kraj: 'Benedikt' },
    { naziv: 'Osnovna šola Cerkvenjak - Vitomarci, Enota Vrtec Cerkvenjak', naslov: 'Cerkvenjak 24 A', posta: '2236', kraj: 'Cerkvenjak' },
    { naziv: 'Osnovna šola Sveta Ana, Enota vrtec', naslov: 'Sv. Ana v Slov. goricah 14', posta: '2233', kraj: 'Sv. Ana v Slov. goricah' },
    { naziv: 'Osnovna šola Škofljica Podružnica Lavrica', naslov: 'Dolenjska cesta 342', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Osnovna šola Pod goro Slovenske Konjice Podružnica Špitalič', naslov: 'Špitalič pri Slov. Konjicah 2', posta: '3215', kraj: 'Loče' },
    { naziv: 'Osnovna šola Antona Šibelja - Stjenka Komen, Podružnica Štanjel', naslov: 'Štanjel 63', posta: '6222', kraj: 'Štanjel' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše, Gimnazija', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše, Poklicna in strokovna šola', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Srednja ekonomsko turistična šola Radovljica Strokovna gimnazija', naslov: 'Gorenjska cesta 13', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Srednja ekonomsko turistična šola Radovljica Srednja strokovna šola', naslov: 'Gorenjska cesta 13', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Srednja šola Srečka Kosovela Sežana, Gimnazija', naslov: 'Stjenkova ulica 3', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Srednja šola Srečka Kosovela Sežana, Srednja ekonomska šola', naslov: 'Stjenkova ulica 3', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Ekonomska šola Murska Sobota, Strokovna gimnazija', naslov: 'Noršinska ulica 13', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Ekonomska šola Murska Sobota, Poklicna in strokovna šola', naslov: 'Noršinska ulica 13', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Srednja gradbena šola Maribor, Strokovna gimnazija', naslov: 'Smetanova ulica 35', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Srednja gradbena šola Maribor, Poklicna in strokovna šola', naslov: 'Smetanova ulica 35', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Jesenice, DE Frančiške Ambrožič', naslov: 'Hrušica 55 A', posta: '4276', kraj: 'Hrušica' },
    { naziv: 'Vrtec Hansa Christiana Andersena, enota Marjetica', naslov: 'Bitenčeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Hansa Christiana Andersena, enota Andersen', naslov: 'Rašiška ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE Solčava', naslov: 'Solčava 12', posta: '3335', kraj: 'Solčava' },
    { naziv: 'Osnovna šola Radlje ob Dravi, Enota vrtca Vuhred', naslov: 'Vuhred 148', posta: '2365', kraj: 'Vuhred' },
    { naziv: 'Vrtec Litija, Enota Sonček', naslov: 'Vače 28', posta: '1252', kraj: 'Vače' },
    { naziv: 'Vrtec Nova Gorica, Enota Centralni vrtec', naslov: 'Trubarjeva ulica 5', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Nova Gorica, Enota Julka Pavletič Solkan', naslov: 'Ulica Ludvika Slokarja 8', posta: '5250', kraj: 'Solkan' },
    { naziv: 'VVE pri Osnovni šoli Veržej', naslov: 'Puščenjakova ulica 7', posta: '9241', kraj: 'Veržej' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Pedenjped', naslov: 'Jakopičeva ulica 27', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Kekec', naslov: 'Zgornje Stranje 22', posta: '1242', kraj: 'Stahovica' },
    { naziv: 'Osnovna šola in vrtec Sveta Trojica, Enota Vrtec', naslov: 'Meznaričeva ulica 1', posta: '2235', kraj: 'Sv. Trojica v Slov. goricah' },
    { naziv: 'VRTEC TEZNO, ENOTA PEDENJPED VRTEC IN JASLI', naslov: 'Ulica heroja Nandeta 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC TEZNO, ENOTA MEHURČKI', naslov: 'Janševa ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA RIBNIK', naslov: 'Pot v Žapuže 14', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VIPAVA', naslov: 'Gradiška cesta 14', posta: '5271', kraj: 'Vipava' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA ZIDANI MOST', naslov: 'Zidani Most 34', posta: '1432', kraj: 'Zidani Most' },
    { naziv: 'Vrtec Ptuj, Enota Mačice', naslov: 'Potrčeva cesta 9 A', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA SEDRAŽ', naslov: 'Sedraž 21', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Brezno', naslov: 'Brezno 78', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Lehen', naslov: 'Lehen na Pohorju 49', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Kapla', naslov: 'Spodnja Kapla 2', posta: '2362', kraj: 'Kapla' },
    { naziv: 'Izobraževalni center Memory, d.o.o. Višja strokovna šola Dutovlje', naslov: 'Dutovlje 32', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Kranjski vrtci, Enota Biba', naslov: 'Zgornje Bitnje 266', posta: '4209', kraj: 'Žabnica' },
    { naziv: 'KRANJSKI VRTCI, ENOTA ČEBELICA', naslov: 'Planina 39', posta: '4000', kraj: 'Kranj' },
    { naziv: 'KRANJSKI VRTCI, ENOTA NAJDIHOJCA', naslov: 'Ulica Nikole Tesle 4', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC IG enota HRIBČEK', naslov: 'Golo 37', posta: '1292', kraj: 'Ig' },
    { naziv: 'Vrtec Borisa Pečeta, Enota Bresternica', naslov: 'Na Gaj 4', posta: '2354', kraj: 'Bresternica' },
    { naziv: 'Vrtec Domžale, Enota Kekec', naslov: 'Dermastijeva ulica 2 A', posta: '1235', kraj: 'Radomlje' },
    { naziv: 'Vrtec Domžale, Enota Cicidom', naslov: 'Šubičeva ulica 18', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Vrtec Domžale, Enota Ostržek', naslov: 'Kettejeva ulica 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Vrtec Domžale, Enota Racman', naslov: 'Dragomelj 180 A', posta: '1230', kraj: 'Domžale' },
    { naziv: 'VRTEC MOJCA ENOTA ROŽLE', naslov: 'Ulica bratov Učakar 64', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Srečka Kosovela Sežana, Gimnazija in ekonomska šola', naslov: 'Stjenkova ulica 3', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Ekonomsko-storitveni izobraževalni center Kranj, Višja strokovna šola', naslov: 'Župančičeva ulica 22', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Pomorski in tehniški izobraževalni center Portorož, Srednja šola in dijaški dom', naslov: 'Pot pomorščakov 4', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'VRTEC MAVRICA VOJNIK - ENOTA ŠMARTNO V ROŽNI DOLINI', naslov: 'Šmartno v Rožni dolini 3', posta: '3201', kraj: 'Šmartno v Rožni dolini' },
    { naziv: 'ČAS - Zasebna šola za varnostno izobraževanje d.o.o.', naslov: 'Cesta Andreja Bitenca 68', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Idrijsko-Cerkljanska razvojna agencija d.o.o. Idrija', naslov: 'Arkova ulica 1', posta: '5280', kraj: 'Idrija' },
    { naziv: 'VVZ Kekec Grosuplje, oddelek Zvonček pri POŠ Žalna', naslov: 'Žalna 1', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Stari trg ob Kolpi, enota Vrtec', naslov: 'Stari trg ob Kolpi 7', posta: '8342', kraj: 'Stari trg ob Kolpi' },
    { naziv: 'Center za usposabljanje, delo in varstvo Črna na Koroškem', naslov: 'Center 144', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'Enota vrtec Log - Dragomer, Enota Log, oddelek', naslov: 'Loška cesta 12', posta: '1358', kraj: 'Log pri Brezovici' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Mojca', naslov: 'Železnikova ulica 24', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA RIMSKE TOPLICE', naslov: 'Cankarjeva ulica 14', posta: '3272', kraj: 'Rimske Toplice' },
    { naziv: 'KRANJSKI VRTCI, ENOTA JANINA', naslov: 'Kebetova ulica 9', posta: '4000', kraj: 'Kranj' },
    { naziv: 'KRANJSKI VRTCI, ENOTA KEKEC', naslov: 'Cesta Kokrškega odreda 9', posta: '4000', kraj: 'Kranj' },
    { naziv: 'KRANJSKI VRTCI, ENOTA ČENČA', naslov: 'Oprešnikova ulica 4 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Kranjski vrtci, Enota Mojca', naslov: 'Ulica Nikole Tesle 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Kranjski vrtci, Enota Mojca razvojni oddelek', naslov: 'Ulica Nikole Tesle 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC KRIŽEVCI', naslov: 'Križevci pri Ljutomeru 16 B', posta: '9242', kraj: 'Križevci pri Ljutomeru' },
    { naziv: 'VVE pri OŠ Most na Soči - oddelek vrtca Šentviška Gora', naslov: 'Šentviška Gora 31', posta: '5283', kraj: 'Slap ob Idrijci' },
    { naziv: 'Vrtec Manka Golarja Gornja Radgona, enota Črešnjevci', naslov: 'Črešnjevci 166', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Vrtec Manka Golarja Gornja Radgona, enota Negova', naslov: 'Negova 20', posta: '9245', kraj: 'Spodnji Ivanjci' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC ROBINDVOR', naslov: 'Robindvor 60', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC ČRNEČE', naslov: 'Črneče 157', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC LIBELIČE', naslov: 'Libeliče 22', posta: '2372', kraj: 'Libeliče' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC TRBONJE', naslov: 'Trbonje 26', posta: '2371', kraj: 'Trbonje' },
    { naziv: 'Vrtec Radenci-Radenski mehurčki, enota Grozdek', naslov: 'Kapelski Vrh 95', posta: '9252', kraj: 'Radenci' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC DRAVOGRAD, ENOTA DRAVOGRAD', naslov: 'Trg 4. julija 65', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'JAVNI VZGOJNO IZOBRAŽEVALNI ZAVOD VRTEC POBREŽJE', naslov: 'Cesta XIV. divizije 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Regionalni center za razvoj d.o.o.', naslov: 'Cankarjev trg 3', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec OŠ Fram', naslov: 'Eberlova ulica 8', posta: '2313', kraj: 'Fram' },
    { naziv: 'Vrtec Škratek Svit Vodice', naslov: 'Franca Seška cesta 15', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Osnovna šola Toneta Tomšiča Knežak, Enota Vrtec', naslov: 'Knežak 147 A', posta: '6253', kraj: 'Knežak' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, dislocirani oddelek enote Sonček na Svetem Štefanu', naslov: 'Sveti Štefan 72', posta: '3264', kraj: 'Sveti Štefan' },
    { naziv: 'VVE PRI OŠ VUZENICA', naslov: 'Mladinska ulica 5', posta: '2367', kraj: 'Vuzenica' },
    { naziv: 'Osnovna šola XIV. divizije Senovo, Enota Vrtec', naslov: 'Trg XIV. divizije 4', posta: '8281', kraj: 'Senovo' },
    { naziv: 'Vzgojnoizobraževali zavod vrtec Ormož, enota Podgorci', naslov: 'Podgorci 81 A', posta: '2273', kraj: 'Podgorci' },
    { naziv: 'Osnovna šola Ivanjkovci, vrtec Ivanjkovci', naslov: 'Ivanjkovci 71 A', posta: '2259', kraj: 'Ivanjkovci' },
    { naziv: 'Osnovna šola Miklavž pri Ormožu, enota Vrtec Kog', naslov: 'Kog 1', posta: '2276', kraj: 'Kog' },
    { naziv: 'Zavod svetega Cirila in Metoda Beltinci, OE Glasbena šola Beltinci', naslov: 'Mladinska ulica 4', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Kekec', naslov: 'Ulica Štravhovih 50', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VIZ VRTEC MAVRICA IZOLA, ENOTA LIVADE, ODDELEK KORTE', naslov: 'Korte 14 A', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'ENOTA CENTRALNI VRTEC, dislokacija Tičnica', naslov: 'Tržaška cesta 148', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vrtec Mornarček Piran, enota Barčica Portorož', naslov: 'Ulica IX. korpusa 40 A', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Vrtec Ivana Glinška, enota Pristan', naslov: 'Usnjarska ulica 11', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška, enota Gregorčičeva - Krekova', naslov: 'Gregorčičeva ulica 32 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška Maribor, dislokacija enote Gregorčičeva - Krekova', naslov: 'Krekova ulica 27', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška Maribor, enota Gledališka', naslov: 'Gledališka ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška, enota Ribiška', naslov: 'Ribiška ulica 11', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška, enota Smetanova', naslov: 'Smetanova ulica 34 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ivana Glinška, enota Kosarjeva', naslov: 'Kosarjeva ulica 41', posta: '2000', kraj: 'Maribor' },
    { naziv: 'KRANJSKI VRTCI, ENOTA JEŽEK', naslov: 'Trstenik 8', posta: '4204', kraj: 'Golnik' },
    { naziv: 'VRTEC IG enota KRIMČEK', naslov: 'Cesta na Kurešček 70', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Dobje, enota Vrtec Dobje pri Planini', naslov: 'Dobje pri Planini 21', posta: '3224', kraj: 'Dobje pri Planini' },
    { naziv: 'Osnovna šola narodnega heroja Rajka Hrastnik, oddelki za izobraževanje otrok s posebnimi potrebami', naslov: 'Log 19', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Vrtec Medvode, enota Smlednik', naslov: 'Valburga 26', posta: '1216', kraj: 'Smlednik' },
    { naziv: 'A.L.P. PECA podjetje za razvoj in trženje produktov Mežiške doline d.o.o.', naslov: 'Prežihova ulica 17', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'VVE pri Osnovni šoli Sveti Jurij', naslov: 'Sveti Jurij 13', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Cirkulane - Zavrč, Vrtec Zavrč', naslov: 'Zavrč 14', posta: '2283', kraj: 'Zavrč' },
    { naziv: 'Osnovna šola Prestranek, Vrtec Prestranek', naslov: 'Ulica 25. maja 14 A', posta: '6258', kraj: 'Prestranek' },
    { naziv: 'OŠ Simona Gregorčiča Kobarid, Vrtec Smast', naslov: 'Smast 13', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'OŠ Simona Gregorčiča Kobarid, Vrtec Drežnica', naslov: 'Drežnica 16 E', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'OŠ Simona Gregorčiča Kobarid, Vrtec Breginj', naslov: 'Breginj 64', posta: '5223', kraj: 'Breginj' },
    { naziv: 'Center za usposabljanje, delo in varstvo Dolfke Boštjančič', naslov: 'Draga 1', posta: '1292', kraj: 'Ig' },
    { naziv: 'Osnovna šola Čepovan, Vrtec Čepovan', naslov: 'Čepovan 87', posta: '5253', kraj: 'Čepovan' },
    { naziv: 'Osnovna šola Sečovlje, enota Podružnična šola in vrtec Sveti Peter', naslov: 'Sv. Peter 13', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Osnovna šola Sečovlje, enota Podružnična šola in vrtec Sveti Peter', naslov: 'Sv. Peter 13', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Ob gozdu', naslov: 'Ob gozdu 22', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Brezje', naslov: 'Na trati 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota GRINČ', naslov: 'Cesta XIV. divizije 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Vransko - Tabor, Enota Vrtec Tabor', naslov: 'Tabor 20', posta: '3304', kraj: 'Tabor' },
    { naziv: 'VRTEC IG enota GOZDIČEK', naslov: 'Draga 1', posta: '1292', kraj: 'Ig' },
    { naziv: 'Vrtec Galjevica, Enota Galjevica', naslov: 'Galjevica 38', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Galjevica, Lokacija pot k ribniku', naslov: 'Pot k ribniku 20', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Domžale, Enota Palček', naslov: 'Hubadova ulica 12', posta: '1230', kraj: 'Domžale' },
    { naziv: 'VRTEC MOJCA ENOTA TINKARA', naslov: 'Pečnikova ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE pri OŠ Davorina Jenka Cerklje na Gorenjskem', naslov: 'Krvavška cesta 2', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Vrtec Medvode, enota Ostržek', naslov: 'Ostrovrharjeva ulica 2', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Cirkulane - Zavrč, Vrtec Cirkulane', naslov: 'Cirkulane 69', posta: '2282', kraj: 'Cirkulane' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA LAŠKO', naslov: 'Cesta na Svetino 2 A', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Dornberk, enota vrtca Dornberk', naslov: 'Gregorčičeva ulica 30 A', posta: '5294', kraj: 'Dornberk' },
    { naziv: 'Osnovna šola Miklavž pri Ormožu, enota Vrtec Miklavž pri Ormožu', naslov: 'Miklavž pri Ormožu 30', posta: '2275', kraj: 'Miklavž pri Ormožu' },
    { naziv: 'Vrtec Miškolin, Rjava cesta, oddelek blok', naslov: 'Polje, cesta V 3', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Center za usposabljanje, delo in varstvo Matevža Langusa', naslov: 'Cesta na Jezerca 17', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'GEKO, zasebni vrtec d.o.o.', naslov: 'Motnica 11', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Vrtec Mengeš, Enota Sonček', naslov: 'Zoranina ulica 5', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Jasli Grinič', naslov: 'Cesta XIV. divizije 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Ogledalo, enostavne rešitve d.o.o.', naslov: 'Gornji Rudnik I 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec pri OŠ Puconci, enota Puconci', naslov: 'Puconci 50', posta: '9201', kraj: 'Puconci' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Čebelica', naslov: 'Malečnik 52', posta: '2229', kraj: 'Malečnik' },
    { naziv: 'Vrtec pri OŠ Puconci, enota Brezovci', naslov: 'Brezovci 51', posta: '9201', kraj: 'Puconci' },
    { naziv: 'Vrtec pri OŠ Puconci, enota Bodonci', naslov: 'Bodonci 5', posta: '9265', kraj: 'Bodonci' },
    { naziv: 'Vrtec Šentjur, enota Hruševec', naslov: 'Gajstova pot 2 A', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Vrtec Šentjur, enota Slivnica', naslov: 'Gorica pri Slivnici 71', posta: '3263', kraj: 'Gorica pri Slivnici' },
    { naziv: 'Vrtec Šentjur, enota Ponikva', naslov: 'Ponikva 29 A', posta: '3232', kraj: 'Ponikva' },
    { naziv: 'Vrtec Šentjur, enota Planina', naslov: 'Planina pri Sevnici 80', posta: '3225', kraj: 'Planina pri Sevnici' },
    { naziv: 'Vrtec Šentjur, enota Loka', naslov: 'Loka pri Žusmu 74', posta: '3223', kraj: 'Loka pri Žusmu' },
    { naziv: 'Vrtec Šentjur, enota Blagovna', naslov: 'Proseniško 22', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Vrtec Galjevica, Lokacija Dolenjska cesta', naslov: 'Dolenjska cesta 52', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Strokovni izobraževalni center Ljubljana, Srednja poklicna in strokovna šola Bežigrad', naslov: 'Ptujska ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Strokovni izobraževalni center Ljubljana, Avtošola Ježica', naslov: 'Na produ 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod sv. Stanislava, Osnovna šola Alojzija Šuštarja Ljubljana', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Vrtec Medvode, enota Preska', naslov: 'Kalanova ulica 3', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Vrtec Medvode, enota Ostržek, DO Senica', naslov: 'Zgornja Senica 45', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Vrtec Medvode, enota Medvoška', naslov: 'Medvoška cesta 16', posta: '1215', kraj: 'Medvode' },
    { naziv: 'JVIZ OTONA ŽUPANČIČA MARIBOR, OE MEHURČKI', naslov: 'Ulica Arnolda Tovornika 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Župnijski zavod Sv. Janeza Krstnika, OE Rahelin vrtec - hiša otrok montessori', naslov: 'Preška cesta 33', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Vrtec Mornarček Piran', naslov: 'Ulica IX. korpusa 40 A', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Vrtec pri OŠ Dornberk, enota', naslov: 'Gregorčičeva ulica 30 A', posta: '5294', kraj: 'Dornberk' },
    { naziv: 'Osnovna šola Dornberk, enota vrtca Prvačina', naslov: 'Prvačina 48 A', posta: '5297', kraj: 'Prvačina' },
    { naziv: 'Vrtec pri OŠ Dornberk, enota Dornberk', naslov: 'Gregorčičeva ulica 30 A', posta: '5294', kraj: 'Dornberk' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA DEBRO', naslov: 'Poženelova ulica 22', posta: '3270', kraj: 'Laško' },
    { naziv: 'ERUDIO Višja strokovna šola', naslov: 'Litostrojska cesta 40', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Kolezija, enota Kolezija', naslov: 'Rezijanska ulica 22', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Šentjur, enota Pešnica', naslov: 'Cesta Miloša Zidanška 11', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Vrtec Kolezija, enota Murgle', naslov: 'Pod bukvami 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'KRANJSKI VRTCI, ENOTA ŽIV-ŽAV', naslov: 'Jernejeva ulica 14', posta: '4000', kraj: 'Kranj' },
    { naziv: 'KRANJSKI VRTCI, ENOTA SONČEK', naslov: 'Cesta 1. maja 17', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC IG enota STUDENČEK', naslov: 'Govekarjeva cesta 6', posta: '1292', kraj: 'Ig' },
    { naziv: 'VVZ SLOVENJ GRADEC', naslov: 'Maistrova ulica 2 A', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Vrtec Galjevica, Enota Orlova', naslov: 'Ob dolenjski železnici 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PRININI svetovanje in posredovanje, d.o.o.', naslov: 'Cankarjeva cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JVIZ Vrtec Otona Župančiča Maribor, OE Lenka', naslov: 'Oblakova ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VRTEC MOJCA ENOTA MOJCA', naslov: 'Levičnikova ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna Sveti Jurij, Enota Vrtec Pertoča', naslov: 'Pertoča 54 A', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Osnovna šola Sveti Jurij, Enota Vrtec Rogašovci', naslov: 'Rogašovci 16 B', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'VRTEC MEDO Krašnja', naslov: 'Krašnja 14 A', posta: '1225', kraj: 'Lukovica' },
    { naziv: 'Zavod za usposabljanje, delo in varstvo Dr.Marijana Borštnarja Dornava', naslov: 'Dornava 128', posta: '2252', kraj: 'Dornava' },
    { naziv: 'VVE pri OŠ Matija Valjavec Preddvor, enota STORŽEK', naslov: 'Šolska ulica 9', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - ENOTA PIKA NOGAVIČKA', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Leskovec pri Krškem, Enota vrtec, enota', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Inštitut montessori, PE Zasebni vrtec rumena hiša Dravlje', naslov: 'Sojerjeva ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Gustava Šiliha Laporje, Vrtec Laporje', naslov: 'Laporje 31', posta: '2318', kraj: 'Laporje' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA TIČNICA', naslov: 'Tržaška cesta 105', posta: '1370', kraj: 'Logatec' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA CENTRALNI VRTEC, oddelek Tičnica', naslov: 'Tržaška cesta 148', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vrtec Škofja Loka, enota Ciciban', naslov: 'Sv. Duh 11', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'VVE pri OŠ Most na Soči - oddelek vrtca Podmelec', naslov: 'Kneža 1', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'Vrtec Kolezija, enota Mencingerjeva', naslov: 'Mencingerjeva ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE pri OŠ Most na Soči - oddelek vrtca Dolenja Trebuša', naslov: 'Dolenja Trebuša 68', posta: '5283', kraj: 'Slap ob Idrijci' },
    { naziv: 'Vrtec Radenci-Radenski mehurčki, enota Vrtec na hribčku', naslov: 'Janžev Vrh 37', posta: '9252', kraj: 'Radenci' },
    { naziv: 'Vrtec Kolezija, enota Kolezija, dislokacija vzg. var. družina', naslov: 'Čanžekova ulica 26', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Borisa Pečeta, Enota Kamnica', naslov: 'Vrbanska cesta 93 A', posta: '2351', kraj: 'Kamnica' },
    { naziv: 'Ekonomska šola Murska Sobota, Srednja šola in gimnazija', naslov: 'Noršinska ulica 13', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Šolski center Novo mesto, Enota za izobraževanje odraslih', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Biotehniški izobraževalni center Ljubljana, Medpodjetniški izobraževalni center', naslov: 'Ižanska cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Log - Dragomer, Enota vrtec Log - Dragomer, Enota Log', naslov: 'Loška cesta 12', posta: '1358', kraj: 'Log pri Brezovici' },
    { naziv: 'VRTEC MEDO Prevoje', naslov: 'Zaboršt 2', posta: '1225', kraj: 'Lukovica' },
    { naziv: 'Osnovna šola Dekani, Enota vrtec Dekani', naslov: 'Dekani 118', posta: '6271', kraj: 'Dekani' },
    { naziv: 'Osnovna šola Dekani, Enota vrtca Rižana', naslov: 'Rižana 2', posta: '6271', kraj: 'Dekani' },
    { naziv: 'Vrtec pri OŠ Frana Metelka Škocjan, enota', naslov: 'Škocjan 51', posta: '8275', kraj: 'Škocjan' },
    { naziv: 'Vrtec pri šoli, OŠ Log-Dragomer, Enota vrtec Log - Dragomer', naslov: 'Šolska ulica 1', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'OŠ Log - Dragomer, Enota vrtec Log - Dragomer, Enota Dragomer, enota', naslov: 'Na Grivi 1', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Center za usposabljanje, delo in varstvo Dobrna', naslov: 'Lokovina 10', posta: '3204', kraj: 'Dobrna' },
    { naziv: 'Vrtec Tržič, enota Palček', naslov: 'Cesta Ste Marie aux Mines 28', posta: '4290', kraj: 'Tržič' },
    { naziv: 'Vrtec Tržič, enota Deteljica', naslov: 'Kovorska cesta 2', posta: '4290', kraj: 'Tržič' },
    { naziv: 'VVZ Tržič, enota Križe', naslov: 'Cesta Kokrškega odreda 22', posta: '4294', kraj: 'Križe' },
    { naziv: 'Vrtec Tržič, Lom', naslov: 'Lom pod Storžičem 12', posta: '4290', kraj: 'Tržič' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - ENOTA MALI PRINC', naslov: 'Cesta krških žrtev 132', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Škofja Loka, enota Najdihojca', naslov: 'Podlubnik 1 D', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Škofja Loka, enota Pedenjped, oddelek', naslov: 'Reteče 43', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Vrtec Pod gonje Prevalje, enota', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Brezno', naslov: 'Brezno 78', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'VVE pri OŠ Most na Soči - oddelek vrtca Podmelec', naslov: 'Kneža 1', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'VRTEC DRAVOGRAD, VRTEC ŠENTJANŽ', naslov: 'Šentjanž pri Dravogradu 88', posta: '2373', kraj: 'Šentjanž pri Dravogradu' },
    { naziv: 'Vrtec Kolezija, enota Livada', naslov: 'Ulica Dušana Kraigherja 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'KRANJSKI VRTCI, ENOTA OSTRŽEK', naslov: 'Golnik 67', posta: '4204', kraj: 'Golnik' },
    { naziv: 'Vrtec Mornarček Piran, Enota Barčica Portorož', naslov: 'Sončna pot 20 A', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Šolski center Ptuj, Medpodjetniški izobraževalni center', naslov: 'Volkmerjeva cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Tehniški šolski center Kranj, Medpodjetniški izobraževalni center', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Vrtec Domžale, Enota Mlinček', naslov: 'Cesta borcev 3', posta: '1235', kraj: 'Radomlje' },
    { naziv: 'Vrtec Domžale, Enota Krtek', naslov: 'Šolska ulica 5', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Vrtec Domžale, Enota Savska', naslov: 'Savska cesta 3', posta: '1230', kraj: 'Domžale' },
    { naziv: 'VRTEC MOJCA ENOTA MUCA', naslov: 'Zoletova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Zagorje ob Savi, Enota Maja', naslov: 'Pečarjeva ulica 4', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec Zagorje ob Savi, Enota Smrkci', naslov: 'Cesta 9. avgusta 111', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec Zagorje ob Savi, Enota Ciciban', naslov: 'Trg Pohorskega bataljona 2', posta: '1412', kraj: 'Kisovec' },
    { naziv: 'Vrtec Zagorje ob Savi, Enota Kekec', naslov: 'Izlake 6', posta: '1411', kraj: 'Izlake' },
    { naziv: 'Pomorski in tehniški izobraževalni center Portorož, Višja strokovna šola', naslov: 'Pot pomorščakov 4', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Dvojezična osnovna šola Prosenjakovci, Vrtec Domanjševci', naslov: 'Domanjševci 65 A', posta: '9206', kraj: 'Križevci' },
    { naziv: 'VRTEC MAVRICA VOJNIK - ENOTA FRANKOLOVO', naslov: 'Verpete 1 B', posta: '3213', kraj: 'Frankolovo' },
    { naziv: 'VRTEC MAVRICA VOJNIK - ENOTA SOCKA', naslov: 'Socka 70', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'VRTEC MAVRICA VOJNIK - ENOTA NOVA CERKEV', naslov: 'Nova Cerkev 41', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'VRTEC MAVRICA VOJNIK - ENOTA VOJNIK I.', naslov: 'Ulica Stanka Kvedra 2', posta: '3212', kraj: 'Vojnik' },
    { naziv: '2. osnovna šola Slovenska Bistrica', naslov: 'Šolska ulica 5', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'JVIZ VRTEC POBREŽJE', naslov: 'Cesta XIV. divizije 14 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Stražišče Kranj, Enota Vrtec v Podružnici Žabnica', naslov: 'Žabnica 20', posta: '4209', kraj: 'Žabnica' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, dislocirani oddelek enote Sonček na Kristan Vrhu', naslov: 'Kristan Vrh 40', posta: '3241', kraj: 'Podplat' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, dislocirani oddelek enote Sonček na Sladki Gori', naslov: 'Sladka Gora 5', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Tinkara', naslov: 'Klavčičeva ulica 1', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Miškolin, Rjava cesta', naslov: 'Rjava cesta 1', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec Bled, enota Bled', naslov: 'Trubarjeva cesta 7', posta: '4260', kraj: 'Bled' },
    { naziv: 'Vrtec Slovenske Konjice, dislociran oddelek KS Žiče', naslov: 'Žiče 14', posta: '3215', kraj: 'Loče' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE GABERJE', naslov: 'Glavna ulica 29', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE PETIŠOVCI', naslov: 'Rudarska ulica 11', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Osnovna šola Pod goro Slovenske Konjice Enota s prilagojenim učnim programom', naslov: 'Tattenbachova ulica 5 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Leskovec', naslov: 'Leskovec 67', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota KEKEC', naslov: 'Smrečnikova ulica 16', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'OŠ Radlje ob Dravi, enota vrtca Radlje', naslov: 'Koroška cesta 15', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Pestrna', naslov: 'Groharjeva ulica 1', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Selnica ob Dravi, enota Vrtec', naslov: 'Mariborska cesta 28', posta: '2352', kraj: 'Selnica ob Dravi' },
    { naziv: 'Javni zavod Vrtec Zreče, Enota Stranice', naslov: 'Stranice 36', posta: '3206', kraj: 'Stranice' },
    { naziv: 'Vrtec Pedenjped, Enota Vrtnar', naslov: 'Cerutova ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Frana Kocbeka Gornji Grad, Enota Vrtec Bočna', naslov: 'Bočna 53', posta: '3342', kraj: 'Gornji Grad' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC VIPAVSKI KRIŽ', naslov: 'Vipavski Križ 10', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC COL', naslov: 'Col 78', posta: '5273', kraj: 'Col' },
    { naziv: 'Vrtec Slovenske Konjice, Enota Slomškova', naslov: 'Slomškova ulica 1', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Slovenske Konjice, Enota Tepanje', naslov: 'Tepanje 27', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Slovenske Konjice, dislociran oddelek KS Jernej', naslov: 'Sveti Jernej 40', posta: '3215', kraj: 'Loče' },
    { naziv: 'Vrtec Slovenske Konjice, dislociran oddelek KS Zbelovo', naslov: 'Zbelovo 33 A', posta: '3215', kraj: 'Loče' },
    { naziv: 'Vrtec Ptuj, Enota Zvonček', naslov: 'Mlinska cesta 1 B', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Ptuj, Enota Deteljica', naslov: 'Mladinska ulica 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Velenje enota Tinkara', naslov: 'Šlandrova cesta 11 A', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Vinska gora', naslov: 'Vinska Gora 12', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Jurček', naslov: 'Škale 138', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Čebelica', naslov: 'Konovska cesta 21', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Črnuče, ENOTA SAPRAMIŠKA', naslov: 'Cesta 24. junija 48', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Vrtec Črnuče, ENOTA GMAJNA', naslov: 'Cesta v Pečale 1', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'VVZ SLOVENJ GRADEC', naslov: 'Sele 1', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC MARJETICA', naslov: 'Cesta občine Hirschaid 6', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC ČEBELICA', naslov: 'Šentvid pri Stični 48', posta: '1296', kraj: 'Šentvid pri Stični' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA JURKLOŠTER', naslov: 'Jurklošter 23', posta: '3273', kraj: 'Jurklošter' },
    { naziv: 'Zavod Bled - šola za tuje jezike, izobraževalni zavod za odrasle, otroke in mladostnike', naslov: 'Kajuhova cesta 11', posta: '4260', kraj: 'Bled' },
    { naziv: 'Zavod za izobraževanje in razvoj AI - Akademija za kozmetična izobraževanja', naslov: 'Cesta na Brdo 67', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Dušana Flisa Hoče, VVE Hoče', naslov: 'Šolska ulica 12', posta: '2311', kraj: 'Hoče' },
    { naziv: 'Zavod za izobraževanje odraslih Radovljica', naslov: 'Kranjska cesta 2 A', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Znanstveno - raziskovalno središče Republike Slovenije, Koper', naslov: 'Garibaldijeva ulica 18', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'ŽGUR-PRO produkcija glasbenih projektov d.o.o.', naslov: 'Potrčeva ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja ekonomska šola Maribor - zasebna šola', naslov: 'Glavni trg 17', posta: '2000', kraj: 'Maribor' },
    { naziv: 'DIALOG , šola za tuje jezike d.o.o., Ljubljana', naslov: 'Bratovševa ploščad 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'INTER-ES, Izobraževanje in svetovanje d.o.o. - enota Cesta na Brdo', naslov: 'Cesta na Brdo 85', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Micro Team, družba za računalniški inženiring, d.o.o.', naslov: 'Cesta Andreja Bitenca 68', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'NISTA poslovne dejavnosti in trgovina, d.o.o.', naslov: 'Šmarska cesta 5 D', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'POLIGLOT , podjetje za izobraževanje d.o.o.', naslov: 'Ljubljanska cesta 110', posta: '1230', kraj: 'Domžale' },
    { naziv: 'SIBON, Center za tuje jezike d.o.o.', naslov: 'Ljubljanska cesta 76', posta: '1230', kraj: 'Domžale' },
    { naziv: 'TIGRAM specializirani  tečaji tujih jezikov, d.o.o.', naslov: 'Kamnikarjeva ulica 19', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'VERIS, verodostojne etične razumne intelektualne storitve, d.o.o.', naslov: 'Puhova ulica 14', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Inštitut in akademija za multimedije, Višja šola za multimedije', naslov: 'Leskoškova cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Višja strokovna šola za gostinstvo in turizem Maribor', naslov: 'Cafova ulica 7', posta: '2000', kraj: 'Maribor' },
    { naziv: 'GEA College CVŠ, Družba za višješolsko izobraževanje - Center višjih šol, d.o.o., Podružnica v Piranu', naslov: 'Kidričevo nabrežje 2', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'ELEKTRO SLOVENIJA, d.o.o.,a Višje strokovne šole za elektroenergetiko v Mariboru', naslov: 'Vetrinjska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Izobraževalni center MEMORY, izobraževanje in poslovne storitve, d.o.o., OE Višja strokovna šola v Kopru', naslov: 'Ferrarska ulica 30', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'JVIZ Mozirje, OE Vrtec Mozirje, DE Rečica ob Savinji', naslov: 'Rečica ob Savinji 55', posta: '3332', kraj: 'Rečica ob Savinji' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, enota Sonček Šmarje', naslov: 'Slomškova ulica 13', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Mojca', naslov: 'Ulica Matije Blejca 14', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Mavrica', naslov: 'Ljubljanska cesta 16 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Pedenjped, Enota Potepuh', naslov: 'Gabrje pri Jančah 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Srečka Kosovela Sežana, Višja strokovna šola', naslov: 'Kraška ulica 2', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Biotehniški center Naklo, Višja strokovna šola', naslov: 'Strahinj 99', posta: '4202', kraj: 'Naklo' },
    { naziv: 'VVZ Kekec Grosuplje, enota Kekec', naslov: 'Trubarjeva cesta 15', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Šentvid, Enota Mravljinček', naslov: 'Martinova pot 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Hrastnik, enota Dolinca na Logu', naslov: 'Novi log 11 A', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Vrtec Martin Krpan Cerknica, Enota Rakek', naslov: 'Stara cesta 5 A', posta: '1381', kraj: 'Rakek' },
    { naziv: 'Vrtec Martin Krpan Cerknica, Enota Grahovo', naslov: 'Grahovo 120', posta: '1384', kraj: 'Grahovo' },
    { naziv: 'Vrtec Antona Medveda Kamnik, enota Polžki', naslov: 'Motnik 8', posta: '1221', kraj: 'Motnik' },
    { naziv: 'Osnovna šola Dobrova, Vrtec Dobrova', naslov: 'Stara cesta 13', posta: '1356', kraj: 'Dobrova' },
    { naziv: 'Osnovna šola Simona Jenka Kranj, Vrtec v Podružnični šoli Primskovo', naslov: 'Zadružna ulica 11', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VRTEC TEZNO ENOTA LUPINICA VRTEC IN JASLI', naslov: 'Dogoška cesta 20', posta: '2000', kraj: 'Maribor' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA OB HUBLJU', naslov: 'Ob Hublju 1', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC SELO', naslov: 'Selo 39', posta: '5262', kraj: 'Črniče' },
    { naziv: 'Vrtec Ptuj, Enota Vijolica', naslov: 'Rogaška cesta 19', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Slovenske Konjice, Enota Loče', naslov: 'Šolska ulica 2', posta: '3215', kraj: 'Loče' },
    { naziv: 'Vrtec Slovenske Konjice, dislociran oddelek KS Špitalič', naslov: 'Špitalič pri Slov. Konjicah 2', posta: '3215', kraj: 'Loče' },
    { naziv: 'Vrtec Ptuj, Enota Narcisa', naslov: 'Raičeva ulica 12', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Ptuj, Enota Trobentica', naslov: 'Slovenskogoriška cesta 13', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Velenje enota Najdihojca', naslov: 'Prešernova cesta 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Lučka', naslov: 'Kardeljev trg 12', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Vrtiljak', naslov: 'Cesta talcev 20', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Ciciban', naslov: 'Koželjskega ulica 8', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Velenje enota Jakec', naslov: 'Ulica Pohorskega bataljona 14', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Danijelov levček, enota SLOMŠEK', naslov: 'Don Boskov trg 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Studenci Maribor, OE Radvanje', naslov: 'Grizoldova ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Studenci Maribor, OE Limbuš', naslov: 'Šolska ulica 25', posta: '2341', kraj: 'Limbuš' },
    { naziv: 'Vrtec Studenci Maribor, OE Pekrska', naslov: 'Pekrska cesta 16', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VIZ VRTEC MAVRICA IZOLA ENOTA ŠKOLJKA', naslov: 'Ulica oktobrske revolucije 11 A', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'VIZ VRTEC MAVRICA IZOLA ENOTA LIVADE', naslov: 'Ulica Osvobodilne fronte 15', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Vrtec Semedela, enota Ankaran', naslov: 'Bevkova ulica 1', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Jurija Vege, VVE Vojke Napokoj', naslov: 'Vegova ulica 38', posta: '1251', kraj: 'Moravče' },
    { naziv: 'OŠ Radlje ob Dravi, enota vrtca Vuhred', naslov: 'Vuhred 148', posta: '2365', kraj: 'Vuhred' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, Enota Mavrica Mestinje', naslov: 'Mestinje 43', posta: '3241', kraj: 'Podplat' },
    { naziv: 'Vrtec Jarše, enota Kekec', naslov: 'Ulica Hermana Potočnika 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Hrastnik, enota Sonček na Rudniku', naslov: 'Cesta padlih borcev 3', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Vrtec Hrastnik, enota Lučka na Dolu', naslov: 'Planinska cesta 5', posta: '1431', kraj: 'Dol pri Hrastniku' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana, Enota Čurimuri', naslov: 'Novosadska ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana, Enota Živ-Žav', naslov: 'Na peči 20', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Miškolin, Sneberje', naslov: 'Šmartinska cesta 246', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec Miškolin, Zajčja Dobrava', naslov: 'Zadobrovška cesta 28 A', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'OŠ Kanal, Vrtec KAL', naslov: 'Kal nad Kanalom 119', posta: '5214', kraj: 'Kal nad Kanalom' },
    { naziv: 'Osnovna šola Solkan, Enota Vrtec Grgar', naslov: 'Grgar 45', posta: '5251', kraj: 'Grgar' },
    { naziv: 'Osnovna šola Solkan, enota Vrtec Trnovo', naslov: 'Trnovo 41', posta: '5252', kraj: 'Trnovo pri Gorici' },
    { naziv: 'Center za poslovno usposabljanje, Višja strokovna šola Ljubljana, Podružnica Ptuj', naslov: 'Mestni trg 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Bled enota Bohinjska Bela', naslov: 'Bohinjska Bela 56', posta: '4263', kraj: 'Bohinjska Bela' },
    { naziv: 'Vrtec Ptuj, Enota Marjetica', naslov: 'Med vrti 11', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC ČRNIČE', naslov: 'Črniče 43', posta: '5262', kraj: 'Črniče' },
    { naziv: 'Vrtec Slovenske Konjice, Enota Tatenbachova', naslov: 'Tattenbachova ulica 5', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE GENTEROVCI', naslov: 'Šolska ulica 2', posta: '9223', kraj: 'Dobrovnik - Dobronak' },
    { naziv: 'Osnovna šola Središče ob Dravi, enota Vrtec Navihanček', naslov: 'Breg 41', posta: '2277', kraj: 'Središče ob Dravi' },
    { naziv: 'Vrtec Ptuj, Enota Tulipan', naslov: 'Med vrti 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Velenje enota Cirkovce', naslov: 'Škalske Cirkovce 11', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Studenci Maribor, OE Iztokova', naslov: 'Žabotova ulica 10', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Biotehniški center Naklo, Srednja šola', naslov: 'Strahinj 99', posta: '4202', kraj: 'Naklo' },
    { naziv: 'Šolski center Ravne na Koroškem, Srednja šola', naslov: 'Na gradu 4 A', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA ŠMARTNO', naslov: 'Šmartno pri Slovenj Gradcu 69 A', posta: '2383', kraj: 'Šmartno pri Slovenj Gradcu' },
    { naziv: 'VVE pri PŠ Trnovska vas', naslov: 'Trnovska vas 38 A', posta: '2254', kraj: 'Trnovska vas' },
    { naziv: 'Vrtec Nova Gorica, Enota Kekec', naslov: 'Cankarjeva ulica 66', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Nova Gorica, Enota Kurirček', naslov: 'Cankarjeva ulica 32', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Nova Gorica, Enota Mojca', naslov: 'Trubarjeva ulica 5 A', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota CICIBAN', naslov: 'Ragovska ulica 18', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'VVZ Ilke Devetak-Bignami Tolmin, enota Volarje', naslov: 'Volarje 54', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'Vrtec Zelena jama, Enota Vrba', naslov: 'Brodarjev trg 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Zelena jama, Enota Zelena jama', naslov: 'Zvezna ulica 24', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Cerkvenjak - Vitomarci, Enota Vrtec Vitomarci', naslov: 'Vitomarci 42 B', posta: '2255', kraj: 'Vitomarci' },
    { naziv: 'Vrtec Pedenjped, Enota Radovednež', naslov: 'Ob studencu 11 A', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Sneguljčica', naslov: 'Kovinarska cesta 11', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Javni zavod Vrtec Zreče, Enota Gorenje', naslov: 'Gorenje pri Zrečah 19', posta: '3214', kraj: 'Zreče' },
    { naziv: 'Osnovna šola Dekani, Enota vrtec', naslov: 'Dekani 32', posta: '6271', kraj: 'Dekani' },
    { naziv: 'VRTEC JELKA, ENOTA JELKA', naslov: 'Glavarjeva ulica 18 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC BUDANJE', naslov: 'Budanje 37', posta: '5271', kraj: 'Vipava' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC PODNANOS', naslov: 'Podnanos 77', posta: '5272', kraj: 'Podnanos' },
    { naziv: 'Vrtec Črnuče, ENOTA OSTRŽEK', naslov: 'Dunajska cesta 400', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Vrtec Hrastnik', naslov: 'Novi log 11 A', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Vrtec Hansa Christiana Andersena, dislocirana enota Marjetica - POLŽEK', naslov: 'Gotska ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC PIKAPOLONICA', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC MIŠKA', naslov: 'Stična 24', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC POLŽEK', naslov: 'Ciglerjeva ulica 27', posta: '1294', kraj: 'Višnja Gora' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, VRTEC SONČEK', naslov: 'Zagradec 31', posta: '1303', kraj: 'Zagradec' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA LEGEN', naslov: 'Zidanškova ulica 39', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Vrtec Koper, Enota Kekec', naslov: 'Župančičeva ulica 23', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Zarja Celje, ENOTA RINGA RAJA', naslov: 'Nušičeva ulica 2 C', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Zarja Celje, ČIRA-ČARA', naslov: 'Škapinova ulica 6 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Zarja Celje, ENOTA ISKRICA', naslov: 'Pucova ulica 3', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Jesenice, VVE Angelce Ocepek', naslov: 'Cesta Cirila Tavčarja 3 A', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Vrtec Jesenice, VVE Angelce Ocepek', naslov: 'Cesta Cirila Tavčarja 21', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Vrtec Jesenice, VVE Julke Pibernik', naslov: 'Cesta Toneta Tomšiča 3', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Vrtec Jesenice, VVE Cilke Zupančič', naslov: 'Cesta Ivana Cankarja 4 E', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Vrtec Semedela, enota Ankaran/Regentova', naslov: 'Regentova ulica 4 C', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, oddelek Petelinčki', naslov: 'Ustje 18', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, oddelek Mravljice', naslov: 'Pungrt 9', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Waldorfska šola Ljubljana, enota Vrtec, enota', naslov: 'Streliška ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Murska Sobota, enota RINGARAJA', naslov: 'Gregorčičeva ulica 23', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Murska Sobota, enota ČEBELICA', naslov: 'Zadružna ulica 5', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Marjetica', naslov: 'Ljubljanska cesta 3 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Zavod Za Jutri, enota Zasebni vrtec Vila Mezinček', naslov: 'Savska cesta 32', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Hiša otrok Frančiška Sedeja, vzgoja in varstvo predšoslkih otrok d.o.o.', naslov: 'Sedejeva ulica 2', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Ljudska univerza Ormož', naslov: 'Vrazova ulica 12', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Sončni dvori', naslov: 'Brezje pri Grosupljem 74', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Spodnja Šiška', naslov: 'Gasilska cesta 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Beltinci', naslov: 'Panonska ulica 35 B', posta: '9231', kraj: 'Beltinci' },
    { naziv: 'Osnovna šola Voličina', naslov: 'Spodnja Voličina 82', posta: '2232', kraj: 'Voličina' },
    { naziv: 'Osnovna šola Žetale', naslov: 'Čermožiše 45 B', posta: '2287', kraj: 'Žetale' },
    { naziv: 'Vrtec Pod Gradom, enota Prule', naslov: 'Praprotnikova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Petelinček', naslov: 'Ustje 18', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Mravljica', naslov: 'Pungrt 9', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA DEBRO', naslov: 'Poženelova ulica 24', posta: '3270', kraj: 'Laško' },
    { naziv: 'Vrtec Podčetrtek', naslov: 'Trška cesta 66', posta: '3254', kraj: 'Podčetrtek' },
    { naziv: 'Inštitut Sofijin izvir Maribor, Zasebni waldorfski vrtec Studenček', naslov: 'Valvasorjeva ulica 94', posta: '2000', kraj: 'Maribor' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Leskovec pri Krškem, Enota vrtec, oddelek VILA', naslov: 'Ulica Staneta Žagarja 2', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Čira čara', naslov: 'Gorenje Vrhpolje 55', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Ples plus d.o.o., PE Zasebni vrtec Zvezdica - V STEČAJU', naslov: 'Rakovniška ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Medvode, oddelek', naslov: 'Sora 1 B', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Vrtec Ptuj, Enota Podlesek', naslov: 'Med vrti 2', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Vrtec Radovljica, enota', naslov: 'Kopališka cesta 10', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Osnovna šola Komandanta Staneta Dragatuš, Enota Vrtec Dragatuš', naslov: 'Dragatuš 48', posta: '8343', kraj: 'Dragatuš' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Ostržek', naslov: 'Slakova ulica 5', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Vesolje malčkov, zasebni vrtec, d.o.o.', naslov: 'Gerbičeva ulica 90', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'P.E. Zasebni vrtec CutePlay', naslov: 'Celovška cesta 144', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Dante Alighieri Izola, Enota Vrtec L Aquilone, enota', naslov: 'Ulica oktobrske revolucije 10', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Vrtec Otona Župančiča Ljubljana, enota Čebelica', naslov: 'Hruševska cesta 81', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod Mali svet Ljubljana', naslov: 'Podmilščakova ulica 24', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VIZ Vrtci Brezovica, enota NOTRANJE GORICE', naslov: 'Cesta na Log 2', posta: '1357', kraj: 'Notranje Gorice' },
    { naziv: 'Hiša otrok Mali koraki - zasebni Montessori vrtec, Mežica', naslov: 'Trg svobode 15', posta: '2392', kraj: 'Mežica' },
    { naziv: 'Viški vrtci, enota Študentski dom', naslov: 'Cesta v Hrastje 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center za sluh in govor Maribor, OE Osnovna šola in vrtec', naslov: 'Vinarska ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Zavod za kreativno varstvo Pri Dobri teti', naslov: 'Ručigajeva cesta 34', posta: '4000', kraj: 'Kranj' },
    { naziv: 'VVZ Kekec Grosuplje, enota Rožle', naslov: 'Ljubljanska cesta 4 F', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Gabrovka - Dole, Enota Vrtec Čebelica', naslov: 'Gabrovka 61', posta: '1274', kraj: 'Gabrovka' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA CENTRALNI VRTEC', naslov: 'Notranjska cesta 7 A', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vzgojnoizobraževalni zavod vrtec Ormož, enota Velika Nedelja', naslov: 'Velika Nedelja 7 A', posta: '2274', kraj: 'Velika Nedelja' },
    { naziv: 'JVIZ Vrtec Pobrežje Maribor, enota Najdihojca', naslov: 'Majeričeva ulica 9', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Mengeš, Enota Mavrica', naslov: 'Šolska ulica 5', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - oddelek Mali Princ', naslov: 'Cesta krških žrtev 132', posta: '8270', kraj: 'Krško' },
    { naziv: 'PRAH, izobraževalni center, avtošola in drugo izobraževanje, d.o.o., Višja strokovna šola Rogaška Slatina', naslov: 'Kidričeva ulica 28', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Vrtec Zarja Celje, MEHURČEK, enota', naslov: 'Cesta v Lokrovec 30', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Kranj', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Ekonomska šola Celje', naslov: 'Kosovelova ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'Gimnazija, elektro in pomorska šola Piran', naslov: 'Bolniška ulica 11', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Srednja šola Izola', naslov: 'Ulica Prekomorskih brigad 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Ekonomska šola Celje, Gimnazija in srednja šola', naslov: 'Kosovelova ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center Kranj, Srednja ekonomska, storitvena in gradbena šola', naslov: 'Cesta Staneta Žagarja 33', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Maribor, Srednja elektro-računalniška šola', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Maribor', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Maribor, Višja strokovna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Kranj, Višja strokovna šola', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Maribor, Srednja lesarska šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Gimnazija Franceta Prešerna', naslov: 'Kidričeva cesta 65', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Ekonomska šola Celje, Višja strokovna šola', naslov: 'Mariborska cesta 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Janka Glazerja Ruše - enota Vrtec Ruše, Bistrica ob Dravi', naslov: 'Ulica 27. decembra 8', posta: '2345', kraj: 'Bistrica ob Dravi' },
    { naziv: 'Osnovna šola Kašelj', naslov: 'Kašeljska cesta 119 A', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec Tončke Čečeve Celje, enota VRTCA ČIRA ČARA', naslov: 'Škapinova ulica 6 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Biotehniški izobraževalni center Ljubljana, Višja strokovna šola', naslov: 'Ižanska cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Enota Hiša otrok Cinca Binca - Galjevica', naslov: 'Jalnova ulica 70', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JVVZ Vrtec Laško, oddelek Šentrupert', naslov: 'Šentrupert 98', posta: '3271', kraj: 'Šentrupert' },
    { naziv: 'Zavod za predšolsko vzgojo ZVEZDICA, Dragomer, enota', naslov: 'Celovška cesta 108', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Litija, Enota Ribica', naslov: 'Ljubljanska cesta 3', posta: '1270', kraj: 'Litija' },
    { naziv: 'Vrtec Litija, Enota Griček', naslov: 'Partizanska pot 21 A', posta: '1270', kraj: 'Litija' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič', naslov: 'Šolska ulica 1', posta: '8333', kraj: 'Semič' },
    { naziv: 'Javni zavod Osnovna šola Marjana Nemca Radeče', naslov: 'Šolska pot 5', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Osnovna šola Karla Destovnika Kajuha Ljubljana', naslov: 'Jakčeva ulica 42', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Antonina, zavod za vzgojo, izobraževanje in družino, Hiša otrok Antonina, zasebni vrtec montessori', naslov: 'Bilje 30', posta: '5292', kraj: 'Renče' },
    { naziv: 'Zasebni vrtec Bambi d.o.o., enota Veronikin kotiček', naslov: 'Braslovče 23', posta: '3314', kraj: 'Braslovče' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Brezje 79', naslov: 'Brezje pri Grosupljem 79', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola in vrtec Ankaran', naslov: 'Regentova ulica 4', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Blanca, Enota vrtca Blanca', naslov: 'Blanca 13', posta: '8283', kraj: 'Blanca' },
    { naziv: 'Zavod Montessori vsakdan', naslov: 'Socka 64 F', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'Vrtec Jadvige Golež, Cesta zmage', naslov: 'Cesta zmage 28', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, enota Livada', naslov: 'Vegova ulica 22', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Vrtec Zvezdica, zasebno varstvo otrok, d.o.o.', naslov: 'Rakovniška ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'JVIZ Vrtec Ivana Glinška Maribor', naslov: 'Gledališka ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Gorje, Enota Vrtec Gorje', naslov: 'Zgornje Gorje 44', posta: '4247', kraj: 'Zgornje Gorje' },
    { naziv: 'Vrtec Krško, enota Grič', naslov: 'Gubčeva ulica 6', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Krško, enota Dolenja vas', naslov: 'Dolenja vas pri Krškem 55', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Nazaret', naslov: 'Mekinčeva ulica 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'IES Ljubljana mednarodna šola d.o.o., PE Zasebni vrtec Moje sanje', naslov: 'Zlatek 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Montessori - zavod zasebni vrtec Cinca Binca za vzgojo in izobraževanje', naslov: 'Hruševska cesta 48 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Franca Rozmana - Staneta Maribor', naslov: 'Kersnikova ulica 10', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Selnica ob Dravi, podružnica Sveti Duh na Ostren vrhu - enota vrtec, enota', naslov: 'Sv. Duh na Ostrem vrhu 53', posta: '2353', kraj: 'Sv. Duh na Ostrem Vrhu' },
    { naziv: 'Zbornica za razvoj slovenskega zasebnega varovanja, Center za usposabljanje varnostnega osebja', naslov: 'Dimičeva ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Postojna, Enota Škratek', naslov: 'Vilharjeva ulica 14', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Vrtec Čebelica Šentjernej, družinsko varstvo Tatjana Mohorič', naslov: 'Turopolje 4', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Osnovna šola Antona Ukmarja Koper', naslov: 'Pot v gaj 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Osnovna šola Dragotina Ketteja', naslov: 'Šegova ulica 114', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Glasbena šola Lendava', naslov: 'Glavna ulica 70', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Vrtec Bled', naslov: 'Trubarjeva cesta 7', posta: '4260', kraj: 'Bled' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Dobrnič', naslov: 'Dobrnič 1', posta: '8211', kraj: 'Dobrnič' },
    { naziv: 'ZASEBNI VRTEC PINGVIN, oddelek', naslov: 'Kamniška ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Ribniček Ivančna Gorica, enota Komenskega', naslov: 'Ulica 6. junija 12', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'ABITURA, Podjetje za izobraževanje, d.o.o., Celje, Višja strokovna šola', naslov: 'Lava 7', posta: '3000', kraj: 'Celje' },
    { naziv: 'Gimnazija Franca Miklošiča Ljutomer', naslov: 'Prešernova ulica 34', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Osnovna šola Voličina, enota vrtec Selce', naslov: 'Selce 78', posta: '2232', kraj: 'Voličina' },
    { naziv: 'Konservatorij za glasbo in balet Ljubljana, Višja baletna šola', naslov: 'Ižanska cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Konservatorij za glasbo in balet Ljubljana, Glasbena šola', naslov: 'Ižanska cesta 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ciciban Novo mesto, enota MEHURČKI', naslov: 'Ragovska ulica 30', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Košana-enota vrtca', naslov: 'Dolnja Košana 61', posta: '6256', kraj: 'Košana' },
    { naziv: 'Ljudska univerza Slovenska Bistrica (po koncesiji)', naslov: 'Partizanska ulica 22', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Kranjski vrtci, Enota Ciciban', naslov: 'Likozarjeva ulica 22', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Tehniški šolski center Maribor, Višja strokovna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Jesenice', naslov: 'Cesta Cirila Tavčarja 3 A', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Osnovna šola Korena', naslov: 'Zgornja Korena 32', posta: '2242', kraj: 'Zgornja Korena' },
    { naziv: 'Osnovna šola Šmarje pri Jelšah', naslov: 'Vegova ulica 26', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Osnovna šola Martina Konšaka Maribor', naslov: 'Prekmurska ulica 67', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Šoštanj, Enota Biba', naslov: 'Kajuhova cesta 8', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Zavod Znanje Postojna, javni zavod', naslov: 'Ljubljanska cesta 2', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Ljudska univerza Nova Gorica', naslov: 'Cankarjeva ulica 8', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Osnovna šola Duplek, Enota Vrtec, enota Duplek', naslov: 'Korenska cesta 31', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Osnovna šola Duplek, Enota Vrtec, enota Dvorjane', naslov: 'Dvorjane 15 B', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Osnovna šola Duplek, Enota Vrtec, enota', naslov: 'Zgornji Duplek 8 D', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'AMARILIS, glasbeno in drugo izobraževanje', naslov: 'Savska cesta 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Murska Sobota, enota URŠKA', naslov: 'Prešernova ulica 10', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'INTER-ES d.o.o., enota VSŠ v Šempetru pri Novi Gorici', naslov: 'Cesta Goriške fronte 46', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'Waldorfski zasebni vrtec Lira, enota', naslov: 'Stožice 20', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod Antona Martina Slomška', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Zavod Antona Martina Slomška, Glasbena in baletna šola Antona Martina Slomška Maribor', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'KRANJSKI VRTCI, ENOTA ČIRA ČARA', naslov: 'Cesta Staneta Žagarja 6', posta: '4000', kraj: 'Kranj' },
    { naziv: 'KRANJSKI VRTCI, ENOTA ČIRČE', naslov: 'Smledniška cesta 136', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Polhek', naslov: 'Litijska cesta 1', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Hajdina, enota vrtca', naslov: 'Spodnja Hajdina 24', posta: '2288', kraj: 'Hajdina' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor, enota vrtca Mehurčki', naslov: 'Janševa ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Svetovalna Ustvarjalnica SU, PE Zasebni vrtec Volkec', naslov: 'Kajuhova ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Kobacaj', naslov: 'Gorenja Brezovica 28', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Živžav', naslov: 'Orehovica 35 B', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtec Zelena jama', naslov: 'Zvezna ulica 24', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja šola Veno Pilon Ajdovščina, Gimnazija', naslov: 'Cesta 5. maja 12', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Gimnazija in srednja kemijska šola Ruše - Dijaški dom', naslov: 'Šolska ulica 16', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Šolski center Krško - Sevnica, Gimnazija Krško', naslov: 'Cesta krških žrtev 131', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Koper, Enota Ribica', naslov: 'Ferrarska ulica 17', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE DOLGA VAS', naslov: 'Vaška ulica 1', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Vrtec Ciciban Novo mesto, Enota BIBE', naslov: 'Seidlova cesta 40', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Črnuče, ENOTA SONČEK', naslov: 'Kraljeva ulica 10', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Vrtec Slovenske Konjice, Enota Prevrat', naslov: 'Usnjarska cesta 12', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Ptuj, Enota Spominčica', naslov: 'Potrčeva cesta 9', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'OTROŠKI VRTEC AJDOVŠČINA, ENOTA VRTEC VRHPOLJE', naslov: 'Vrhpolje 42', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Zavod Antona Martina Slomška, Dijaški dom Antona Martina Slomška', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Izobraževalni center Memory, d.o.o. Višja strokovna šola Koper', naslov: 'Ferrarska ulica 30', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - ENOTA PETER PAN', naslov: 'Veliki Podlog 16', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Ožbalt', naslov: 'Ožbalt 10', posta: '2361', kraj: 'Ožbalt' },
    { naziv: 'OŠ Črna na Koroškem Vrtec Črna na Koroškem, enota Žerjav', naslov: 'Žerjav 27', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'VVZ SLOVENJ GRADEC, enota CELJSKA', naslov: 'Celjska cesta 29', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Vrtec Ciciban Novo mesto, enota ŽABICA', naslov: 'Drejčetova pot 8', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Pivka, enota Vrtec Vetrnica', naslov: 'Prečna ulica 5', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Vrtec Kočevje, enota Brlogec', naslov: 'Trg zbora odposlancev 22', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Mamin vrtec 1, zasebno varstvo in vzgoja, d.o.o.', naslov: 'Dravska ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Kranj, Medpodjetniški izobraževalni center', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Kranj, Strokovna gimnazija', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Šolski center Maribor, Medpodjetniški izobraževalni center', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'OŠ dr. Jožeta Pučnika Črešnjevec,Vrtec Črešnjevec - Leskovec, Enota Črešnjevec', naslov: 'Črešnjevec 47', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Vrtec Mengeš, enota Oblaček', naslov: 'Šolska ulica 5', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'NBK, svetovanje in raziskave, d.o.o., PE Zasebni vrtec, Mini vrtec', naslov: 'Kadilnikova ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Dobrega pastirja v Zavodu sv. Stanislava', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Papilot, Center za usposabljanje, rehabilitacijo in zaposlovanje, OE Krško', naslov: 'Ulica 11. novembra 45', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Papilot, Center za usposabljanje, rehabilitacijo in zaposlovanje, OE Koper', naslov: 'Vanganelska cesta 18', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Papilot, Center za usposabljanje, rehabilitacijo in zaposlovanje, OE Novo mesto', naslov: 'Florjanov trg 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Zavod Montessori vsakdan, PE Hiša otrok Nova Cerkev', naslov: 'Nova Cerkev 25', posta: '3203', kraj: 'Nova Cerkev' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor, enota vrtca Sovice', naslov: 'Majcigerjeva ulica 31', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Podčetrtek', naslov: 'Trška cesta 66', posta: '3254', kraj: 'Podčetrtek' },
    { naziv: 'Vrtec Ciciban Novo mesto, enota PALČICA', naslov: 'Vrhovčeva ulica 4', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA TIČNICA, oddelek Slončki', naslov: 'Tržaška cesta 148', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vrtec Mavrica Brežice, Zvezdica', naslov: 'Bizeljska cesta 45', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Škofljica', naslov: 'Kamnikarjeva ulica 8', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Osnovna šola Trbovlje Podružnična šola Dobovec', naslov: 'Dobovec 19', posta: '1423', kraj: 'Dobovec' },
    { naziv: 'Zasebni vrtec Zvezdnato nebo, d.o.o.', naslov: 'Klanec 17 A', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Srednja šola Jesenice', naslov: 'Ulica bratov Rupar 2', posta: '4270', kraj: 'Jesenice' },
    { naziv: 'Gimnazija Moste', naslov: 'Zaloška cesta 49', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Mali grof d.o.o., PE Mali grof na deželi', naslov: 'Konjiška vas 1', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Gradiček', naslov: 'Gorenje Gradišče pri Šentj. 6', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'VVE pri OŠ Kranjska Gora', naslov: 'Koroška ulica 12', posta: '4280', kraj: 'Kranjska Gora' },
    { naziv: 'OSNOVNA ŠOLA JANKA GLAZERJA RUŠE', naslov: 'Lesjakova ulica 4', posta: '2342', kraj: 'Ruše' },
    { naziv: 'Osnovna šola Mala Nedelja, Enota vrtec', naslov: 'Mala Nedelja 37', posta: '9243', kraj: 'Mala Nedelja' },
    { naziv: 'Šolski center Maribor, Srednja strojna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Šolski center Kranj, Srednja tehniška šola', naslov: 'Kidričeva cesta 55', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Tehniški šolski center Maribor, Srednja šola in dijaški dom', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Postojna, Enota Pastirček', naslov: 'Cesta na Kremenco 4', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Antona Globočnika Postojna', naslov: 'Cesta na Kremenco 2', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Rastem z montessori, zavod za vzgojo in izobraževanje', naslov: 'Laknerjeva ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Rastem z Montessori, zavod, PE Zasebni vrtec Hiša otrok Ivančna Gorica', naslov: 'Ljubljanska cesta 4 A', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Zavod Eneja so.p., PE Zasebni vrtec Lunba', naslov: 'Cesta Dolomitskega odreda 12 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Medvode', naslov: 'Ostrovrharjeva ulica 4', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Podružnica Iga vas, enota Vrtec', naslov: 'Iga vas 1', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Vrtec Borisa Pečeta, Enota Košaki', naslov: 'Krčevinska ulica 10', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Vrhnika, enota Žabica', naslov: 'Stara Vrhnika 1 A', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'JVIZ Vrtec Rogaška Slatina, enota POTOCEK', naslov: 'Ulica XIV. divizije 24', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'JVIZ Vrtec Rogaška Slatina, enota KAPLJICA', naslov: 'Sv. Florijan 60', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'JVIZ Vrtec Rogaška Slatina, enota IZVIR', naslov: 'Ulica Kozjanskega odreda 2', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Vrtec Šentjur, enota Kalobje', naslov: 'Kalobje 15', posta: '3233', kraj: 'Kalobje' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Oblaček', naslov: 'Trdinova ulica 7 A', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Zasebni vrtec Sonček, varstvo, vzgoja in izobraževanje d.o.o.', naslov: 'Fužine 5', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Gabrovka - Dole, Enota vrtec Čebelica', naslov: 'Gabrovka 30', posta: '1274', kraj: 'Gabrovka' },
    { naziv: 'Vrtec Krško, enota Zdole', naslov: 'Zdole 18', posta: '8272', kraj: 'Zdole' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, E HOTIZA', naslov: 'Strnčka ulica 2', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Ekonomska šola Novo mesto, Srednja šola in gimnazija', naslov: 'Ulica talcev 3 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Brezno - Podvelka, Oddelek vrtca Podvelka', naslov: 'Podvelka 19', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'LINGUARUS, družba za prevajanje, izobraževanje in podjetništvo d. o. o.', naslov: 'Splitska ulica 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PE SERŠ Maribor', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'PE Šentvid', naslov: 'Prušnikova ulica 98', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'PE Kranj', naslov: 'Ljubljanska cesta 24 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'PE Tacen', naslov: 'Marinovševa cesta 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Palčki', naslov: 'Za povrtmi 6', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Kekec', naslov: 'Prešernova ulica 7', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'VVE pri OŠ Rudolf Maister Šentilj, enota Ceršak', naslov: 'Delavska ulica 8', posta: '2215', kraj: 'Ceršak' },
    { naziv: 'VVE pri OŠ Franceta Prešerna Črenšovci, enota', naslov: 'Ulica Juša Kramarja 10', posta: '9232', kraj: 'Črenšovci' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA LAZE', naslov: 'Laze 57', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Savinja, Enota osnovna šola', naslov: 'Ulica Ivanke Uranjek 6', posta: '3310', kraj: 'Žalec' },
    { naziv: 'VVE pri OŠ dr.Janeza Mencingerja Bohinjska Bistrica, oddelki pri matični šoli', naslov: 'Savska cesta 10', posta: '4264', kraj: 'Bohinjska Bistrica' },
    { naziv: 'Zakladnica Montessori, OE zasebni vrtec Hiša otrok srečnih rok', naslov: 'Cesta v Zgornji log 42', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Škofljica, Enota Cekinček', naslov: 'Kamnikarjeva ulica 10', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'ENOTA ALBREHTROVA', naslov: 'Albrehtova ulica 6', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA ČIRA ČARA - ŠKAPINOVA', naslov: 'Škapinova ulica 6 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Srednja šola za gostinstvo in turizem Celje, Medpodjetniški izobraževalni center', naslov: 'Kosovelova ulica 2', posta: '3000', kraj: 'Celje' },
    { naziv: 'Zasebni vrtec Ribniček Ivančna Gorica', naslov: 'Stična 2 A', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'LEILA d.o.o.', naslov: 'Mencingerjeva ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Inštitut Lila, OE OŠ Lila Ljubljana', naslov: 'Langusova ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Jožefe Maslo Ilirska Bistrica', naslov: 'Rozmanova ulica 25 D', posta: '6250', kraj: 'Ilirska Bistrica' },
    { naziv: 'Vrtec Domžale, Enota Gaj', naslov: 'Igriška ulica 44', posta: '1235', kraj: 'Radomlje' },
    { naziv: 'Osnovna šola Cerklje ob Krki, enota Vrtec Pikapolonica', naslov: 'Cerklje ob Krki 3', posta: '8263', kraj: 'Cerklje ob Krki' },
    { naziv: 'Vrtec Tržič, enota Križe', naslov: 'Cesta Kokrškega odreda 22', posta: '4294', kraj: 'Križe' },
    { naziv: 'Osnovna šola Litija, Enota Vrtec Polhek Polšnik', naslov: 'Polšnik 25 C', posta: '1272', kraj: 'Polšnik' },
    { naziv: 'Vrtec Hansa Christiana Andersena, dislocirana enota Lastovica - KRTEK', naslov: 'Celovška cesta 161', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Zgornji kraj Prevalje', naslov: '', posta: '', kraj: '' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, Vrtec Zala, enota Lučine', naslov: 'Lučine 11', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'Glasbena šola Slovenska Bistrica', naslov: 'Ozka ulica 1', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Strokovni izobraževalni center Ljubljana, Medpodjetniški izobraževalni center', naslov: 'Ptujska ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VVE Mehurčki pri OŠ Komenda Moste, enota Čebelica', naslov: 'Glavarjeva cesta 35', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Vrtec Škofljica, dislocirani oddelek Modrin', naslov: 'Albrehtova ulica 6', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'INTER-ES, Izobraževanje in svetovanje d.o.o.', naslov: 'Trg svobode 19', posta: '5213', kraj: 'Kanal' },
    { naziv: 'Vrtec Šentjur', naslov: 'Gajstova pot 2 A', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Vrtec Šentjur, enota Šentjur', naslov: 'Ulica Dušana Kvedra 25', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Osnovna šola Oskarja Kovačiča Škofije', naslov: 'Spodnje Škofije 40 D', posta: '6281', kraj: 'Škofije' },
    { naziv: 'Vrtec Pedenjped, enota SLADKOSNED', naslov: 'Cerutova ulica 6', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Škofljica, enota Pisanček', naslov: 'Dolenjska cesta 342', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Vrtec Mavrica Brežice, Vrtec Oblaček', naslov: 'Maistrova ulica 4', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Mavrica Brežice, Trg Izgnancev', naslov: 'Trg izgnancev 15 A', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfska šola Maribor, Enota vrtec', naslov: 'Valvasorjeva ulica 94', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Murska Sobota, enota GOZDIČEK', naslov: 'Ulica Štefana Kovača 19 A', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vzgojno-izobraževalni zavod Montessori, Zasebni vrtec Montessori, Vrhniška hiša otrok', naslov: 'Robova cesta 27', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Osnovna šola Vitka Pavliča Hrastnik', naslov: 'Log 19', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Zakladnica Montessori, Zavod za vzgojo in izobraževanje', naslov: 'Pot čez gmajno 101', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Bambi d.o.o.', naslov: 'Rečica ob Paki 60 A', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'Zavod za raziskave, izobraževanje in trajnostni razvoj, Celje', naslov: 'Opekarniška cesta 15 A', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Mehurčki, dislocirana enota Čebelica', naslov: 'Glavarjeva cesta 35', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Osnovna šola Brežice', naslov: 'Levstikova ulica 18', posta: '8250', kraj: 'Brežice' },
    { naziv: 'OTROŠKI VRTEC METLIKA', naslov: 'Župančičeva cesta 1', posta: '8330', kraj: 'Metlika' },
    { naziv: 'Vesolje malčkov, zasebni vrtec, d.o.o., enota', naslov: 'Gerbičeva ulica 90', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota čARDAK, oddelek OŠ Mirana Jarca', naslov: 'Ulica Otona Župančiča 8', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Celje, Enota Vrtec', naslov: 'Breg 3', posta: '3000', kraj: 'Celje' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - ENOTA VILA', naslov: 'Ulica Staneta Žagarja 2', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Zavod Mali svet Ljubljana, enota Vič', naslov: 'Ulica Malči Beličeve 109', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Silva Peterca - zasebna vzgojiteljica', naslov: 'Dolsko 94 A', posta: '1262', kraj: 'Dol pri Ljubljani' },
    { naziv: 'Zasebni vrtec Dobra teta d.o.o., enota Pri dobri Tinci', naslov: 'Partizanska cesta 10', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Zasebni vrtec Sonček, enota Sončni dvori', naslov: 'Brezje pri Grosupljem 79', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Trbovlje, oddelek na OŠ Alojza Hohkrauta', naslov: 'Trg revolucije 19', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'PARATUS izobraževanje in svetovanje d.o.o., OE Višja strokovna šola', naslov: 'Prešernova cesta 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Višja šola za računovodstvo in finance, Ljubljana', naslov: 'Stegne 21 C', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VEC, Poklicni izobraževalni center, VEC - OE Višja strokovna šola', naslov: 'Kardeljeva ploščad 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec La Coccinella Piran, enota Piran', naslov: 'Ulica IX. korpusa 40 A', posta: '6330', kraj: 'Piran - Pirano' },
    { naziv: 'Osnovna šola Ivana Tavčarja Gorenja vas, enota Vrtec Dobrava', naslov: 'Trata 40', posta: '4224', kraj: 'Gorenja vas' },
    { naziv: 'Osnovna šola Idrija Enota s prilagojenim učnim programom', naslov: 'Lapajnetova ulica 50', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Vrtec Murska Sobota, enota Srnica', naslov: 'Trubarjeva ulica 77', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'SONCE, Zavod za vzgojo in izobraževanje', naslov: 'Cesta II. grupe odredov 13 C', posta: '1261', kraj: 'Ljubljana - Dobrunje' },
    { naziv: 'Vrtec Krkine lučke pri OŠ Vavta vas', naslov: 'Vavta vas 1', posta: '8351', kraj: 'Straža pri Novem mestu' },
    { naziv: 'Vrtec pri OŠ Vavta vas', naslov: 'Vavta vas 1', posta: '8351', kraj: 'Straža pri Novem mestu' },
    { naziv: 'Vrtec Črnuče, ENOTA OSTRŽEK, oddelek ŠKRATI', naslov: 'Dunajska cesta 390', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'VVE pri OŠ dr.Janeza Mencingerja Bohinjska Bistrica, oddelek pri podružnici Srednja vas', naslov: 'Srednja vas v Bohinju 69', posta: '4267', kraj: 'Srednja vas v Bohinju' },
    { naziv: 'PRAH, izobraževalni center, avtošola in drugo izobraževanje, d.o.o., Višja strokovna šola Rogaška Slatina, Podružnica Ljubljana', naslov: 'Središka ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ROSSANA izobraževanje, trgovina, gostinstvo d.o.o.', naslov: 'Tržaška cesta 17', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Vzgojno-izobraževalni zavod Montessori, Zasebni vrtec Montessori, Gorenjska hiša otrok', naslov: 'Kranjska cesta 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Glasbeni center DO RE MI, Zasebni zavod za vzgojo in izobraževanje', naslov: 'Trubarjeva cesta 7', posta: '4260', kraj: 'Bled' },
    { naziv: 'Osnovna šola Toneta Čufarja', naslov: 'Čufarjeva ulica 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola narodnega heroja Rajka Hrastnik', naslov: 'Log 19', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Javni zavod Knjižnica Gornja Radgona', naslov: 'Trg svobode 4', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Šolski center Velenje, Šola za strojništvo, geotehniko in okolje', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Poljane, Vrtec Agata, enota Javorje', naslov: 'Poljane nad Škofjo Loko 100', posta: '4223', kraj: 'Poljane nad Škofjo Loko' },
    { naziv: 'Osnovna šola Žetale, Enota vrtec', naslov: 'Čermožiše 45 B', posta: '2287', kraj: 'Žetale' },
    { naziv: 'Vrtec Miškolin, enota Zajčja Dobrava 2', naslov: 'Zadobrovška cesta 12', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Zavod Za Jutri', naslov: 'Savska cesta 32', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Center za poslovno usposabljanje, Višja strokovna šola Ljubljana, Podužnica Kočevje', naslov: 'Trg zbora odposlancev 30', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Glasbena šola Grosuplje, podružnica Škofljica', naslov: 'Klanec 5', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Glasbena šola Grosuplje, podružnica Dobrepolje', naslov: 'Videm 34', posta: '1312', kraj: 'Videm - Dobrepolje' },
    { naziv: 'Zasebni vrtec Vilinski gaj, zasebni vzgojno-izobraževalni zavod', naslov: 'Peršonova ulica 1', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Montessori zavod - Vrtec Cinca Binca', naslov: 'Medno 49 E', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Podjetniški inkubator Kočevje', naslov: 'Trata XIV 6 A', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota MAJER', naslov: 'Majer 1', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota NOVI VRTEC', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec La Coccinella Piran, matična enota Lucija', naslov: 'Fazan 3', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Izobraževalni center Piramida Maribor, Medpodjetniški izobraževalni center', naslov: 'Park mladih 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Murska Sobota, enota MIŠKE', naslov: 'Talanyijeva ulica 6', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Murska Sobota, enota KRTEK', naslov: 'Vrtna ulica 2', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Vrhnika, enota Komarček', naslov: 'Poštna ulica 1', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'Osnovna šola Podlehnik, Enota Vrtec', naslov: 'Podlehnik 7 A', posta: '2286', kraj: 'Podlehnik' },
    { naziv: 'Šolski center Velenje, Gimnazija', naslov: 'Trg mladosti 3', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Osnovna šola Sostro Podružnica Prežganje', naslov: 'Prežganje 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Urša, enota Mavrica', naslov: 'Krtina 41', posta: '1233', kraj: 'Dob' },
    { naziv: 'Kranjski vrtci', naslov: 'Ulica Nikole Tesle 2', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Vrtec Dr. France Prešeren, enota Vrtača', naslov: 'Erjavčeva cesta 29', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Malček', naslov: 'Veliko Mlačevo 53 B', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Kungota', naslov: 'Plintovec 10 C', posta: '2201', kraj: 'Zgornja Kungota' },
    { naziv: 'Ustanova lokalna razvojna fundacija za Pomurje', naslov: 'Martjanci 36', posta: '9221', kraj: 'Martjanci' },
    { naziv: 'Šolski center Novo mesto, Srednja strojna šola', naslov: 'Šegova ulica 112', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec La Coccinella Piran, enota Sečovlje', naslov: 'Sečovlje 79', posta: '6333', kraj: 'Sečovlje - Sicciole' },
    { naziv: 'Vrtec pri OŠ Puconci, enota šola', naslov: 'Puconci 178', posta: '9201', kraj: 'Puconci' },
    { naziv: 'VVE pri OŠ Simona Gregorčiča Kobarid, enota', naslov: 'Gregorčičeva ulica 18 A', posta: '5222', kraj: 'Kobarid' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Sončni dvori, oddelek', naslov: 'Brezje pri Grosupljem 75', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Franja Malgaja Šentjur', naslov: 'Ulica skladateljev Ipavcev 2', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Dvojezična osnovna šola Prosenjakovci, vrtec Hodoš', naslov: 'Hodoš 53', posta: '9205', kraj: 'Hodoš - Hodos' },
    { naziv: 'VVE pri Osnovni šoli Sveti Jurij', naslov: 'Sveti Jurij 13', posta: '9262', kraj: 'Rogašovci' },
    { naziv: 'Vrtec Kočevje, Enota Narcisa', naslov: 'Kočevska Reka 1', posta: '1338', kraj: 'Kočevska Reka' },
    { naziv: 'Osnovna šola Muta, Oddelki osnovne šole s prilagojenim programom', naslov: 'Gasilska ulica 4', posta: '2366', kraj: 'Muta' },
    { naziv: 'Osnovna šola Sveti Tomaž, Vzgojno-varstvena enota', naslov: 'Sveti Tomaž 11', posta: '2258', kraj: 'Sv.Tomaž' },
    { naziv: 'Vrtec Šentjur, enota Prevorje', naslov: 'Lopaca 3', posta: '3262', kraj: 'Prevorje' },
    { naziv: 'Vrtec Medvode; enota Pirniče', naslov: 'Zgornje Pirniče 13', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Vrtec Šentjur, enota Dramlje', naslov: 'Dramlje 7 A', posta: '3222', kraj: 'Dramlje' },
    { naziv: 'LEILA d.o.o., OE Mala akademija', naslov: 'Vrhniška cesta 12', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Osnovna šola Maksa Pleteršnika Pišece, Enota Vrtec', naslov: 'Pišece 34', posta: '8255', kraj: 'Pišece' },
    { naziv: 'Osnovna šola Črna na Koroškem', naslov: 'Center 142', posta: '2393', kraj: 'Črna na Koroškem' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Studenec', naslov: 'Studenec 38 A', posta: '8293', kraj: 'Studenec' },
    { naziv: 'Vrtec Dr. France Prešeren, enota Prešernova', naslov: 'Prešernova cesta 29', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Dr. France Prešeren, enota Puharjeva', naslov: 'Puharjeva ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Ljubljana, Višja strokovna šola', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Vrhovci, enota Iga Grudna', naslov: 'Ulica Iga Grudna 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ledina', naslov: 'Čufarjeva ulica 14', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Postojna, Enota Ravbarček', naslov: 'Planina 152', posta: '6232', kraj: 'Planina' },
    { naziv: 'Vrtec Najdihojca, enota AETERNIA', naslov: 'Gorazdova ulica 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Pivka, enota Vrtec Mavrica', naslov: 'Pot na Orlek 2', posta: '6257', kraj: 'Pivka' },
    { naziv: 'Osnovna šola Borisa Kidriča, Enota Vrtec Kidričevo', naslov: 'Kajuhova ulica 10 A', posta: '2325', kraj: 'Kidričevo' },
    { naziv: 'Izobraževalni center Memory, d.o.o.', naslov: 'Dutovlje 32', posta: '6221', kraj: 'Dutovlje' },
    { naziv: 'Osnovna šola Rada Robiča Limbuš', naslov: 'Limbuška cesta 62', posta: '2341', kraj: 'Limbuš' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE I Lendava', naslov: 'Župančičeva ulica 5', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda, DE II LENDAVA', naslov: 'Tomšičeva ulica 6 A', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'PRAH, izobraževalni center, Srednja strokovna in poklicna šola Rogaška Slatina, Podružnica šole Ljubljana', naslov: 'Središka ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PRAH, izobraževalni center, Srednja strokovna in poklicna šola Rogaška Slatina, Podružnica šole Celje', naslov: 'Gregorčičeva ulica 4', posta: '3000', kraj: 'Celje' },
    { naziv: 'PRAH, izobraževalni center, Srednja strokovna in poklicna šola Rogaška Slatina, Podružnica šole Krško', naslov: 'Cesta krških žrtev 130', posta: '8270', kraj: 'Krško' },
    { naziv: 'Vrtec Manka Golarja Gornja Radgona, enota Kocljeva 4', naslov: 'Kocljeva ulica 4', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'JVIZ Vrtec Rogaška Slatina, enota STUDENČEK', naslov: 'Zgornja Kostrivnica 31', posta: '3241', kraj: 'Podplat' },
    { naziv: 'Vrtec Postojna, Enota Škratek', naslov: 'Vilharjeva ulica 14', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Bučke', naslov: 'Velika Kostrevnica 31', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Vrtec Jurček, varstvo otrok, d.o.o.', naslov: 'Mala vas pri Grosupljem 1 B', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Naklo, OE Vrtec, Vrtec Jelka', naslov: 'Spodnje Duplje 2', posta: '4203', kraj: 'Duplje' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Mlačevo', naslov: 'Veliko Mlačevo 53 B', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Čebelica Šentjernej', naslov: 'Trubarjeva cesta 8', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Zasebni vrtec Tinka Binka, vzgoja in varstvo predšolskih otrok d.o.o.', naslov: 'Zminec 113', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Zasebni vrtec Tinka Binka d.o.o.', naslov: 'Zminec 113', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Osnovna šola Bovec', naslov: 'Trg golobarskih žrtev 17', posta: '5230', kraj: 'Bovec' },
    { naziv: 'OTROŠKI VRTEC METLIKA, enota', naslov: 'Župančičeva cesta 1', posta: '8330', kraj: 'Metlika' },
    { naziv: 'Zasebni vrtec Dobra teta d.o.o., enota Pri dobri Ajdi', naslov: 'Verovškova ulica 55 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Emil Adamič, Zasebni zavod za glasbeno vzgojo in izobraževanje, Dobrova', naslov: 'Gabrje 50', posta: '1356', kraj: 'Dobrova' },
    { naziv: 'Zasebni vrtec Vilinski gaj, zasebni vzgojno-izobraževalni zavod, enota MEZINČICA', naslov: 'Volkmerjeva cesta 49', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'ZASEBNI VRTEC - HIŠA OTROK MONTESSORI SVET, zasebni zavod', naslov: 'Ivenca 3 B', posta: '3212', kraj: 'Vojnik' },
    { naziv: 'ZASEBNI VRTEC - HIŠA OTROK MONTESSORI SVET, zasebni zavod, Poslovna enota vrtca', naslov: 'Medlog 8', posta: '3000', kraj: 'Celje' },
    { naziv: 'Vrtec Zagorje ob Savi, enota Smrkci', naslov: 'Cesta 9. avgusta 111', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Osnovna šola Blanca, Enota Vrtec', naslov: 'Blanca 13', posta: '8283', kraj: 'Blanca' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Videk', naslov: 'Slakova ulica 5', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Univerza v Mariboru', naslov: 'Koroška cesta 160', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Univerza v Mariboru pedagoška fakulteta', naslov: 'Koroška cesta 160', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Univerza v Mariboru, Pedagoška fakulteta', naslov: 'Koroška cesta 160', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Modrinček, zasebni vrtec, d.o.o.', naslov: 'Žabja vas 14 A', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola Tišina, Enota Vrtec Tišina, enota', naslov: 'Tišina 4 B', posta: '9251', kraj: 'Tišina' },
    { naziv: 'Barbara IP Izobraževalni program d. o. o.', naslov: 'Robbova ulica 25', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič, enota vrtec Sonček, enota', naslov: 'Šolska ulica 2', posta: '8333', kraj: 'Semič' },
    { naziv: 'Vrtec Morje Lucija, enota', naslov: 'Fazanska ulica 3', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Glasbena šola Rogaška Slatina podružnica Šmarje pri Jelšah', naslov: 'Vegova ulica 26', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'Much Akadem, Zavod za izobraževanje in svetovanje, Ljubljana', naslov: 'Koprska ulica 94 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Velenje enota Enci Benci', naslov: 'Kardeljev trg 2', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Vrtec Šoštanj, enota Biba', naslov: 'Trg Jožeta Lampreta 3', posta: '3325', kraj: 'Šoštanj' },
    { naziv: 'Zavod Za življenje Maribor, PE Zasebni vrtec Hiša otrok montessori', naslov: 'Tomšičeva ulica 38', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Krško, enota Ciciban', naslov: 'Prešernova cesta 13', posta: '8270', kraj: 'Krško' },
    { naziv: 'DOBA VIR ZNANJA, Izobraževanje in usposabljanje d.o.o.', naslov: 'Prešernova ulica 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Mehurčki, dislocirana enota Moste', naslov: 'Moste 40', posta: '1218', kraj: 'Komenda' },
    { naziv: 'G-Rega, zavod za rekreacijo, šport in prosti čas', naslov: 'Hladilniška pot 36', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Gorenjska, Enota osnovna šola', naslov: 'Kranjska cesta 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Vrtec Škofljica, Enota Citronček', naslov: 'Mijavčeva ulica 18', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Glasbena šola Grosuplje, podružnica Ivančna Gorica', naslov: 'Cesta občine Hirschaid 3', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Osnovna šola Šentjernej Podružnica Orehovica', naslov: 'Orehovica 16', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Osnovna šola Gustava Šiliha Laporje, Vrtec Laporje, enota', naslov: 'Laporje 31', posta: '2318', kraj: 'Laporje' },
    { naziv: 'Osnovna šola Srečka Kosovela Sežana', naslov: 'Kosovelova ulica 6', posta: '6210', kraj: 'Sežana' },
    { naziv: 'Moja zgodba, vzgoja in varstvo predšolskih otrok, d.o.o., PE Zasebni vrtec Fridolin', naslov: 'Poklukarjeva ulica 78', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Glasbena šola Ribnica', naslov: 'Kolodvorska ulica 10', posta: '1310', kraj: 'Ribnica' },
    { naziv: 'Vrtec Urša, Enota Urša', naslov: 'Slamnikarska cesta 26', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Zasebni vrtec Čarobni gozd', naslov: 'Dobovec 50', posta: '1423', kraj: 'Dobovec' },
    { naziv: 'Zavod sv. Viktorina Ptuj, PE zasebni vrtec Montessori Ptuj', naslov: 'Slovenski trg 11', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Modra pikica, Zavod za izobraževanje, PE Hiša otrok Bežigrad, zasebni montessori vrtec, enota', naslov: 'Tolstojeva ulica 9 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni zavod vrtec za kreativno varstvo otrok Pika Poka', naslov: 'Terčeva ulica 21', posta: '2000', kraj: 'Maribor' },
    { naziv: 'VVZ VRTEC IVANČNA GORICA, enota KRKA', naslov: 'Cesta občine Hirschaid 6', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Vrtec Murska Sobota, enota ROMANO', naslov: 'Glavna ulica 2', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Škofja Loka, enota Bukovica', naslov: 'Bukovica 43', posta: '4227', kraj: 'Selca' },
    { naziv: 'INVEL, Inkubator za razvoj podjetništva, d.o.o. Velenje', naslov: 'Trg mladosti 6', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Zasebni vrtec Vesela hiša, predšolska vzgoja in izobraževanje, d.o.o.', naslov: 'Jamova ulica 6', posta: '3000', kraj: 'Celje' },
    { naziv: 'PRAH, Izobraževalni center, izobraževanje in usposabljanje d.o.o., Srednja strokovna in poklicna šola Rogaška Slatina-zasebna šola', naslov: 'Kidričeva ulica 28', posta: '3250', kraj: 'Rogaška Slatina' },
    { naziv: 'Vrtec Pod Gradom, oddelek Zemljemerska', naslov: 'Zemljemerska ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Pod Gradom, oddelek Poljanska', naslov: 'Poljanska cesta 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Hrastnik, enota na OŠ n. h. Rajka Hrastnik', naslov: 'Log 19', posta: '1430', kraj: 'Hrastnik' },
    { naziv: 'Glasbena šola Lartko, zavod za glasbeno vzgojo', naslov: 'Šenturška Gora 25', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Smrkolin d.o.o.', naslov: 'Reber pri Škofljici 7', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'VRTEC DRAVOGRAD, oddelek OJSTRICA', naslov: 'Ojstrica 27', posta: '2370', kraj: 'Dravograd' },
    { naziv: 'Viški vrtci, enota Hiša pri ladji, lokacija Študentski dom', naslov: 'Svetčeva ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Šolski center Slovenj Gradec, Srednja šola Slovenj Gradec in Muta', naslov: 'Koroška ulica 11', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Gimnazija in srednja šola Kočevje', naslov: 'Ljubljanska cesta 12', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'Vrtec Delfino Blu Koper, enota Hrvatini', naslov: 'Hrvatini 79', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Vrtec Delfino Blu Koper, enota Koper', naslov: 'Kolarska ulica 8', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Petrov vrtec Šentpeter, enota Petrov dom', naslov: 'Šentpeter 26', posta: '8222', kraj: 'Otočec' },
    { naziv: 'Osnovna šola Razkrižje, Enota Vrtec', naslov: 'Šafarsko 24 C', posta: '9246', kraj: 'Razkrižje' },
    { naziv: 'Zavod Eneja so.p.,', naslov: 'Cesta Dolomitskega odreda 12 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Zagorje ob Savi, Enota Center', naslov: 'Cesta zmage 7 A', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Vrtec Jadvige Golež, Ob gozdu, Ertlova', naslov: 'Ertlova ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'TRAPGAL inženiring d.o.o., PE Zasebni vrtec Mali medvedki', naslov: 'Ulica prvoborcev 11', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Dobra teta d.o.o., enota Pri dobri Evi', naslov: 'Ulica Lojzeta Hrovata 4 B', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Otroška igralnica Malina d.o.o., PE Zasebni vrtec Malina', naslov: 'Gabrščkova ulica 73', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Olge Meglič', naslov: 'Prešernova ulica 31', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Osnovna šola Bičevje', naslov: 'Splitska ulica 13', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Franja Goloba Prevalje, Vrtec Krojaček Hlaček', naslov: 'Polje 4 A', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor, enota Sovice', naslov: 'Majcigerjeva ulica 37', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Štore, Enota Vrtec Lipa', naslov: 'Ulica Cvetke Jerin 7', posta: '3220', kraj: 'Štore' },
    { naziv: 'Srednja tehniška šola Koper, Medpodjetniški izobraževalni center', naslov: 'Izolska vrata 2', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'DUDE, družba za izobraževanje in poslovne storitve, d.o.o.', naslov: 'Breznikova ulica 15', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Pirniče', naslov: 'Zgornje Pirniče 37 B', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Razvojna agencija Kozjansko, OE Ljudska univerza Šentjur', naslov: 'Ulica skladateljev Ipavcev 17', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Vrtec Delfino Blu Koper, enota Ankaran', naslov: 'Bevkova ulica 2', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Osnovna šola Gabrovka - Dole, oddelek Vrtca Čebelica, Dole pri Litiji', naslov: 'Dole pri Litiji 28', posta: '1273', kraj: 'Dole pri Litiji' },
    { naziv: 'Vrtec Martin Krpan Cerknica', naslov: 'Cesta na Jezero 17', posta: '1380', kraj: 'Cerknica' },
    { naziv: 'Srednja ekonomska šola in gimnazija Maribor', naslov: 'Trg Borisa Kidriča 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Montessori razvojni center Obala, Zasebni vrtec Hiša otrok na Obali, Koper', naslov: 'Kraljeva ulica 5', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Glasbena šola Vinka Vodopivca Ajdovščina', naslov: 'Štrancarjeva ulica 8', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Izobraževalni zavod HERA, Ljubljana  V STEČAJU', naslov: 'Kržičeva ulica 7', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Vrtec Polhek, enota Stari trg pri Ložu in enota Iga vas', naslov: 'Cesta Notranjskega odreda 32', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota LOKA, oddelek OŠ LOKA', naslov: 'Kidričeva ulica 18 A', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'OŠ Kanal, podružnica Kal', naslov: 'Kal nad Kanalom 119', posta: '5214', kraj: 'Kal nad Kanalom' },
    { naziv: 'Osnovna šola Cirkovce, Enota Vrtec Cirkovce', naslov: 'Cirkovce 47', posta: '2326', kraj: 'Cirkovce' },
    { naziv: 'Vrtec Mehurčki', naslov: 'Glavarjeva cesta 35', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Zavod Na kmetiji je lepo, zasebni vrtec', naslov: 'Gorenje Vrhpolje 55', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Čebelica', naslov: 'Trubarjeva cesta 8', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Petelinček', naslov: 'Prvomajska cesta 9', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtec Čebelica Šentjernej, enota Muca Copatarica', naslov: 'Trubarjeva cesta 5', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Osnovna šola Rodica', naslov: 'Kettejeva ulica 13', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Antona Tomaža Linharta Radovljica', naslov: 'Kranjska cesta 27', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Srednja zdravstvena šola Ljubljana', naslov: 'Poljanska cesta 61', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Modra pikica, Zavod za izobraževanje, PE Hiša otrok Bežigrad 2, zasebni montessori vrtec, enota', naslov: 'Mašera-Spasićeva ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Srednja poklicna in tehniška šola Murska Sobota , Medpodjetniški izobraževalni center', naslov: 'Šolsko naselje 12', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Pedenjped Novo mesto, SAPRAMIŠKA', naslov: 'Ulica Slavka Gruma 63', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Pedenjped Novo mesto, ŠKRATEK', naslov: 'Šegova ulica 114', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Mravljice', naslov: 'Cesta na grad 25', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Vrtec Pedenjped, enota Pedenjškrat', naslov: 'Mali Lipoglav 8', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'Vrtec Pedenjped, enota Zalog, oddelek v OŠ Nove Fužine', naslov: 'Preglov trg 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Pedenjped, enota Kašelj, oddelek v OŠ Polje', naslov: 'Zadobrovška cesta 1', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Vrtec Črnuče, ENOTA OSTRŽEK, oddelek ŠKRATI', naslov: 'Dunajska cesta 390', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Akademija montessori, OE zasebni vrtec Hiša otrok ABC', naslov: 'Tesovnikova ulica 27 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Gustava Šiliha Maribor, enota vrtca Veveriček', naslov: 'Focheva ulica 51', posta: '2000', kraj: 'Maribor' },
    { naziv: 'SONCE, Zavod za vzgojo in izobraževanje, enota Waldorfski vrtec Hišica sonca', naslov: 'Dergančeva ulica 15', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Sonček, enota Trobentica', naslov: 'Fužine 5', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'DOBA EPIS, d.o.o., Višja strokovna šola', naslov: 'Prešernova ulica 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Ivana Skvarče', naslov: 'Cesta 9. avgusta 44', posta: '1410', kraj: 'Zagorje ob Savi' },
    { naziv: 'Osnovna šola Mirana Jarca Črnomelj', naslov: 'Ulica Otona Župančiča 8', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, oddelek DIJAŠKI DOM', naslov: 'Ulica Mirana Jarca 7', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota NOVI VRTEC', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota LOKA', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Izobraževalni center Geoss d.o.o.', naslov: 'Trg na Stavbah 8 A', posta: '1270', kraj: 'Litija' },
    { naziv: 'VVZ Ilke DevetakBignami Tolmin, enota v OŠ Franceta Bevka Tolmin', naslov: 'Dijaška ulica 12 B', posta: '5220', kraj: 'Tolmin' },
    { naziv: 'ERUDIO zasebna gimnazija', naslov: 'Litostrojska cesta 40', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Gorje, Enota Vrtec Zgornje Gorje', naslov: 'Zgornje Gorje 44 A', posta: '4247', kraj: 'Zgornje Gorje' },
    { naziv: 'Osnovna šola Naklo, Podružnična šola Duplje, Enota Vrtec', naslov: 'Spodnje Duplje 2', posta: '4203', kraj: 'Duplje' },
    { naziv: 'VVZ Slovenj Gradec, ENOTA PODGORJE', naslov: 'Podgorje 173', posta: '2381', kraj: 'Podgorje pri Slovenj Gradcu' },
    { naziv: 'Šolski center Ljubljana, Evropska šola Ljubljana', naslov: 'Aškerčeva cesta 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Zgornji kraj Prevalje,', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Murska Sobota, enota VEVERIČKA', naslov: 'Lendavska ulica 8', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota LOKA', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota ČARDAK', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota MAJER', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota DIJAŠKI DOM', naslov: 'Kidričeva ulica 18 B', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota LOKA, oddelek OŠ LOKA', naslov: 'Kidričeva ulica 18 A', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Čebelica Šentjernej, družinsko varstvo Antončič', naslov: 'Gorenje Gradišče pri Šentj. 25', posta: '8310', kraj: 'Šentjernej' },
    { naziv: 'Vrtci občine Moravske Toplice', naslov: 'Dolga ulica 29 A', posta: '9226', kraj: 'Moravske Toplice' },
    { naziv: 'Vrtec Vrhovci, enota Tehnološki park', naslov: 'Vrhovci, cesta XIX 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Delfino Blu Koper, enota Bertoki', naslov: 'Cesta borcev 13', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'JANA, predšolska vzgoja, svetovanje, storitve, d.o.o., Zasebni vrtec', naslov: 'Pod Trško goro 13', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'JVIZ VRTCI OBČINE ŽALEC, ENOTA NOVO CELJE', naslov: 'Novo Celje 4', posta: '3301', kraj: 'Petrovče' },
    { naziv: 'Zasebni zavod vrtec Želvica', naslov: 'Strossmayerjeva ulica 34 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Vrtec Polhek, enota Iga vas', naslov: 'Cesta Notranjskega odreda 32', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: '2TM, svetovanje in izobraževanje d. o. o.', naslov: 'Dunajska cesta 106', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'SONCE, Zavod za vzgojo in izobraževanje, enota Waldorfski vrtec Slavček', naslov: 'Matenja vas 24', posta: '6258', kraj: 'Prestranek' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, enota ČARDAK', naslov: 'Čardak 2', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Vrtec Pod Gradom, enota Poljane', naslov: 'Strossmayerjeva ulica 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Pod Gradom, oddelek Stara Ljubljana', naslov: 'Ulica na Grad 2 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Rudolfa Maistra Šentilj, Enota Vrtca Ceršak', naslov: 'Delavska ulica 8', posta: '2215', kraj: 'Ceršak' },
    { naziv: 'Osnovna šola Alojza Gradnika Dobrovo, enota vrtca Kojsko', naslov: 'Kojsko 69', posta: '5211', kraj: 'Kojsko' },
    { naziv: 'Osnovna šola Alojza Gradnika Dobrovo, enota vrtca Dobrovo', naslov: 'Trg 25. maja 11', posta: '5212', kraj: 'Dobrovo v Brdih' },
    { naziv: 'Osnovna šola Tišina, Enota Vrtec Tišina', naslov: 'Tišina 4 B', posta: '9251', kraj: 'Tišina' },
    { naziv: 'Trinity turistična agencija in storitve d.o.o.', naslov: 'Kocenova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PARATUS izobraževanje in svetovanje d.o.o.', naslov: 'Prešernova cesta 5', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Višja strokovna šola za kozmetiko in velnes Ljubljana', naslov: 'Ulica Carla Benza 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Vrhovci, enota Vrhovci', naslov: 'Vrhovci, cesta XIX 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Vrhovci, dislokacija Iga Grudna', naslov: 'Ulica Iga Grudna 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Stara Cerkev', naslov: 'Stara Cerkev 21', posta: '1332', kraj: 'Stara Cerkev' },
    { naziv: 'Osnovna šola Partizanska bolnišnica Jesen Tinje', naslov: 'Veliko Tinje 29', posta: '2316', kraj: 'Zgornja Ložnica' },
    { naziv: 'Osnovna šola Horjul', naslov: 'Šolska ulica 44', posta: '1354', kraj: 'Horjul' },
    { naziv: 'Vrtec Mavrica Brežice, Vrtec Zvezdica', naslov: 'Bizeljska cesta 45', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Sežana, enota Hrpelje', naslov: 'Ul. Dragomirja Benčiča-Brkina 4', posta: '6240', kraj: 'Kozina' },
    { naziv: 'Vrtec Škratek Svit Vodice, enota Utik', naslov: 'Utik 1', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Zasebni vrtec Račka, d.o.o.', naslov: 'Krpanova ulica 9', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Nova Gorica, enota Ciciban, Cankarjeva 1', naslov: 'Cankarjeva ulica 1', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Vrtec Nova Gorica, enota', naslov: 'Kidričeva ulica 34 C', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'Zavod SU, PE Zasebni vrtec Volkec', naslov: 'Kajuhova ulica 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Škofljica, enota Bisernik', naslov: 'Kamnikarjeva ulica 8', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Vrtec Škofljica, Enota Škratec', naslov: 'Ob potoku 32 A', posta: '1291', kraj: 'Škofljica' },
    { naziv: 'Miklavžev zavod Murska Sobota - Vrtec Lavra', naslov: 'Gregorčičeva ulica 2 A', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Osnovna šola Gorje', naslov: 'Zgornje Gorje 44 A', posta: '4247', kraj: 'Zgornje Gorje' },
    { naziv: 'Vrtec Otona Župančiča Slovenska Bistrica, Enota Šmartno na Pohorju', naslov: 'Šmartno na Pohorju 24 A', posta: '2315', kraj: 'Šmartno na Pohorju' },
    { naziv: 'Vrtec Jadvige Golež, enota Betnavska', naslov: 'Betnavska cesta 100', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Venclja Perka', naslov: 'Ljubljanska cesta 58 A', posta: '1230', kraj: 'Domžale' },
    { naziv: 'Osnovna šola Miška Kranjca', naslov: 'Kamnogoriška cesta 35', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Stopiče, Vrtec Stopiče', naslov: 'Stopiče 37', posta: '8322', kraj: 'Stopiče' },
    { naziv: 'VVE pri OŠ Boštanj', naslov: 'Boštanj 54 A', posta: '8294', kraj: 'Boštanj' },
    { naziv: 'DE Srednje šole Jesenice Srednja šola Črnomelj', naslov: '', posta: '', kraj: '' },
    { naziv: 'VVZ Cerkno', naslov: '', posta: '', kraj: '' },
    { naziv: 'Vrtec Škofja Loka, enota Biba', naslov: 'Podlubnik 1 E', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'VVE pri OŠ Rudolf Maister Šentilj, enota Ceršak', naslov: 'Delavska ulica 8', posta: '2215', kraj: 'Ceršak' },
    { naziv: 'Montessori inštitut, Zavod za pomoč staršem pri razvoju otrok', naslov: 'Ulica Ferda Kozaka 49', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Sovice', naslov: 'Primskovo 1', posta: '1276', kraj: 'Primskovo' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Škratki', naslov: 'Cerkovnik 2 A', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Mravljivce', naslov: 'Pungrt 9', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Petelinčki', naslov: 'Ustje 18', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Viški vrtci, enota Jamova', naslov: 'Jamova cesta 23', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Viški vrtci, enota Bičevje', naslov: 'Vidmarjeva ulica 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Krkine lučke pri OŠ Vavta vas, dislocirana oddelka', naslov: 'Stara cesta 15', posta: '8351', kraj: 'Straža pri Novem mestu' },
    { naziv: 'Društvo za izkustveno učenje Ustvarjalnica', naslov: 'Prožinska vas 38 A', posta: '3220', kraj: 'Štore' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor', naslov: 'Šolska ulica 9', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'Vrtec Anice Černejeve Celje', naslov: 'Kajuhova ulica 5', posta: '3000', kraj: 'Celje' },
    { naziv: 'Osnovna šola Dušana Muniha Most na Soči', naslov: 'Most na Soči 18 A', posta: '5216', kraj: 'Most na Soči' },
    { naziv: 'Osnovna šola Raka', naslov: 'Raka 36', posta: '8274', kraj: 'Raka' },
    { naziv: 'Osnovna šola Radlje ob Dravi, Enota Vrtca Remšnik', naslov: 'Remšnik 5', posta: '2363', kraj: 'Podvelka' },
    { naziv: 'Osnovna šola Velika Nedelja, Enota vrtca v Podgorcih', naslov: 'Podgorci 81 A', posta: '2273', kraj: 'Podgorci' },
    { naziv: 'Zasebni vrtec Duhec, vzgoja in varstvo predšolskih otrok d.o.o.', naslov: 'Sv. Duh 188 A', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Zasebni vrtec Duhec, Enota Kranj', naslov: 'Župančičeva ulica 22', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Zasebni vrtec Maja, hiša otrok Trebnje', naslov: 'Slakova ulica 39', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Ostržek', naslov: 'Ustje 23', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA ŠENTRUPERT', naslov: 'Šentrupert 89', posta: '3271', kraj: 'Šentrupert' },
    { naziv: 'Zasebni vrtec Metulj d.o.o.', naslov: 'Podutiška cesta 76', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Sonček', naslov: 'Trg padlih borcev 2', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Idrija', naslov: 'Lapajnetova ulica 50', posta: '5280', kraj: 'Idrija' },
    { naziv: 'Kreativnost, zasebna glasbena šola Arsem, d.o.o.', naslov: 'Slovenska cesta 19', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola in vrtec Ankaran, Enota Vrtec Ankaran', naslov: 'Regentova ulica 4 C', posta: '6280', kraj: 'Ankaran - Ancarano' },
    { naziv: 'Vrtec Ciciban Sevnica, Centralni vrtec', naslov: 'Naselje heroja Maroka 22', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'Vrtec Mavrica Brežice, Sonček I', naslov: 'Kregarjeva ulica 7', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Mavrica Brežice, Sonček II', naslov: 'Kregarjeva ulica 11', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Kolezija, enota Kolezija, dislokacija Koseski', naslov: 'Koseskega ulica 17', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ciciban, enota Žabice', naslov: 'Vojkova cesta 73', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Župnijski zavod sv. Jurija Škofja Loka, enota Mala cvetka', naslov: 'Kresnice 76', posta: '1281', kraj: 'Kresnice' },
    { naziv: 'Danijelov levček, enota VOJNIK', naslov: 'Celjska cesta 24 B', posta: '3212', kraj: 'Vojnik' },
    { naziv: 'VRTEC PRI OŠ PIVKA, enota Vetrnica', naslov: 'Pot na Orlek 2', posta: '6257', kraj: 'Pivka' },
    { naziv: 'MOJ MOJ, d.o.o., PE Zasebni vrtec MOJ MOJ VRTEC', naslov: 'Peruzzijeva ulica 103', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Gornja Radgona', naslov: 'Prežihova ulica 1', posta: '9250', kraj: 'Gornja Radgona' },
    { naziv: 'Osnovna šola Radlje ob Dravi', naslov: 'Koroška cesta 17', posta: '2360', kraj: 'Radlje ob Dravi' },
    { naziv: 'Osnovna šola Stična', naslov: 'Cesta občine Hirschaid 1', posta: '1295', kraj: 'Ivančna Gorica' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Šentlovrenc', naslov: 'Šentlovrenc 17', posta: '8212', kraj: 'Velika Loka' },
    { naziv: 'BUAN d.o.o., PE Zasebni vrtec Pod mavrico', naslov: 'Cesta Staneta Žagarja 30 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Zavod RS za šolstvo', naslov: 'Poljanska cesta 28', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Montessori sanje d.o.o.', naslov: 'Juvanova ulica 27', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Kamenček', naslov: 'Tomšičeva ulica 9', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Morje Lucija, enota', naslov: 'Fazanska ulica 3', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Dijaški dom Črnomelj Testna enota', naslov: 'Cankarjeva ulica 1', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Šola za ravnatelje', naslov: 'Dunajska cesta 104', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center RS za mobilnost in evropske programe izobraževanja in usposabljanja', naslov: 'Ob železnici 30 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Center RS za poklicno izobraževanje', naslov: 'Kajuhova ulica 32 U', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Andragoški center RS', naslov: 'Šmartinska cesta 134 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Borisa Pečeta, enota Kamnica II.', naslov: 'Vrbanska cesta 93', posta: '2351', kraj: 'Kamnica' },
    { naziv: 'Vrtec pri OŠ Puconci, enota Mačkovci', naslov: 'Mačkovci 51 E', posta: '9202', kraj: 'Mačkovci' },
    { naziv: 'Vrtec Morje Lucija, Enota Ježek', naslov: 'Liminjanska cesta 91', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'JZ Otroški vrtec Šmarje pri Jelšah, disloc. odd. na OŠ Šmarje pri Jelšah', naslov: 'Vegova ulica 26', posta: '3240', kraj: 'Šmarje pri Jelšah' },
    { naziv: 'VVE pri OŠ Davorina Jenka Cerklje na Gorenjskem, enota Cerklje', naslov: 'Krvavška cesta 2', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'VVE pri OŠ Davorina Jenka Cerklje na Gorenjskem, enota Zalog', naslov: 'Zalog pri Cerkljah 29', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Osnovna šola Orehek Kranj, enota vrtec Mavčiče', naslov: 'Mavčiče 102', posta: '4211', kraj: 'Mavčiče' },
    { naziv: 'RACIO, družba za razvoj človeškega kapitala, d.o.o.', naslov: 'Kersnikova ulica 19', posta: '3000', kraj: 'Celje' },
    { naziv: 'IPIS, računalniška usposabljanja, d.o.o.', naslov: 'Bobovek 7 A', posta: '4000', kraj: 'Kranj' },
    { naziv: 'PIRR inženiring, nepremičnine in trgovina d.o.o.', naslov: 'Petronijeva ulica 7', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'CANDOR-DOMINKO, delavnice tujih jezikov, k.d., Vrhnika', naslov: 'Turnovše 19', posta: '1360', kraj: 'Vrhnika' },
    { naziv: 'BOB, zavod za izobraževanje in kulturne dejavnosti', naslov: 'Kvedrova cesta 3', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'ISA.IT izobraževanje, svetovanje, aplikacije, informacijske tehnologije, d.o.o., Ljubljana', naslov: 'Železna cesta 14', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'POMELAJ, zadruga za razvoj podeželja, z.o.o.', naslov: 'Mala Polana 103', posta: '9225', kraj: 'Velika Polana' },
    { naziv: 'RAZVOJNA AGENCIJA SINERGIJA d.o.o., pospeševanje razvoja podjetništva ter gospodarskega in duhovnega razvoja podeželja', naslov: 'Kranjčeva ulica 3', posta: '9226', kraj: 'Moravske Toplice' },
    { naziv: 'KOPO Izobraževanje in računalništvo d.o.o.', naslov: 'Trg Edvarda Kardelja 3', posta: '5000', kraj: 'Nova Gorica' },
    { naziv: 'SPIN Informacijski inženiring d.o.o.', naslov: 'Cesta Goriške fronte 11', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'GEKO, zasebni vrtec d.o.o., enota', naslov: 'Motnica 11', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Osnovna šola Gorica Velenje', naslov: 'Goriška cesta 48', posta: '3320', kraj: 'Velenje' },
    { naziv: 'ZASEBNI VRTEC PINGVIN', naslov: 'Popovičeva ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Konservatorij za glasbo in balet Maribor, Glasbena in baletna šola Center', naslov: 'Mladinska ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Konservatorij za glasbo in balet Maribor, enota v podr. šoli Tabor', naslov: 'Metelkova ulica 59', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Konservatorij za glasbo in balet Maribor, enota v podr. šoli Lenart', naslov: 'Trg osvoboditve 7', posta: '2230', kraj: 'Lenart v Slov. goricah' },
    { naziv: 'Kulturno umetniški zavod za kreativno spodbujanje otrok CutePlay', naslov: 'Kidričeva ulica 12', posta: '1236', kraj: 'Trzin' },
    { naziv: 'Zasebni vrtec Kobacaj', naslov: 'Velika Loka 47', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Boštanj', naslov: 'Boštanj 52', posta: '8294', kraj: 'Boštanj' },
    { naziv: 'Zavod Antona Martina Slomška, Hiša otrok-vrtec montessori, podenota Tezno', naslov: 'Vrablova ulica 21', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Trnovo, enota Karunova', naslov: 'Karunova ulica 16 A', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Šentvid, Enota Mišmaš', naslov: 'Kosijeva ulica 1', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Vrtec Jurček, varstvo otrok, d.o.o., enota', naslov: 'Mala vas pri Grosupljem 1 B', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Osnovna šola Grm Novo mesto', naslov: 'Trdinova ulica 7', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Osnovna šola in vrtec Apače, Enota vrtca Apače-Stogovci', naslov: 'Apače 157', posta: '9253', kraj: 'Apače' },
    { naziv: 'VIZ Vrtci Brezovica, enota Preserje', naslov: 'Preserje 2', posta: '1352', kraj: 'Preserje' },
    { naziv: 'Osnovna šola Ivana Roba Šempeter pri Gorici', naslov: 'Ulica Andreja Gabrščka 1', posta: '5290', kraj: 'Šempeter pri Gorici' },
    { naziv: 'Osnovna šola Louisa Adamiča Grosuplje', naslov: 'Tovarniška cesta 14', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Dvojezična osnovna šola Prosenjakovci', naslov: 'Prosenjakovci 97 C', posta: '9207', kraj: 'Prosenjakovci - Pártosfalva' },
    { naziv: 'Osnovna šola Belokranjskega odreda Semič, enota vrtec Sonček', naslov: 'Šolska ulica 2', posta: '8333', kraj: 'Semič' },
    { naziv: 'Osnovna šola Duplek, Enota Vrtec, enota Zg. Duplek', naslov: 'Zgornji Duplek 8 D', posta: '2241', kraj: 'Spodnji Duplek' },
    { naziv: 'Šolski center Šentjur', naslov: 'Cesta na kmetijsko šolo 9', posta: '3230', kraj: 'Šentjur' },
    { naziv: 'Gimnazija Brežice', naslov: 'Trg izgnancev 14', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Vrtec Pedenjped, enota PEDENJCARSTVO', naslov: 'Kašeljska cesta 125', posta: '1260', kraj: 'Ljubljana - Polje' },
    { naziv: 'Osnovna šola Poljane, Vrtec Agata, enota Poljane 59', naslov: 'Poljane nad Škofjo Loko 100', posta: '4223', kraj: 'Poljane nad Škofjo Loko' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Mavrica', naslov: 'Režunova ulica 8', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Modra pikica, Zavod za izobraževanje', naslov: 'Hribovska pot 17', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'Vrtec  Antona Medveda Kamnik, enota Rožle', naslov: 'Novi trg 26 B', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'JVVZ Vrtec Laško, enota DRUŽINSKO VARSTVO GRADIŠNIK', naslov: 'Cesta na Svetino 2 A', posta: '3270', kraj: 'Laško' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor, Enota Vrtec', naslov: 'Šolska ulica 9', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'VVE pri OŠ Polzela, oddelek', naslov: 'Andraž nad Polzelo 64', posta: '3313', kraj: 'Polzela' },
    { naziv: 'VVE pri OŠ Komenda - Moste, enota vrtca Mehurčki', naslov: 'Glavarjeva cesta 35', posta: '1218', kraj: 'Komenda' },
    { naziv: 'Glasbena šola Frana Gerbiča Cerknica', naslov: 'Partizanska cesta 9', posta: '1380', kraj: 'Cerknica' },
    { naziv: 'Elektrotehniško-računalniška strokovna šola in gimnazija Ljubljana, Medpodjetniški izobraževalni center', naslov: 'Vegova ulica 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec La Coccinella Piran, enota Strunjan', naslov: 'Strunjan 32', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Vrtec La Coccinella Piran, oddelek Ježek Lucija', naslov: 'Liminjanska cesta 91', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'JVVZ Vrtec Laško, ENOTA REČICA', naslov: 'Zgornja Rečica 26', posta: '3270', kraj: 'Laško' },
    { naziv: 'Vrtec Trnovo, enota Trnovski pristan', naslov: 'Trnovski pristan 12', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zasebni vrtec Biba d.o.o.', naslov: 'Ljubljanska cesta 54 A', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'Vrtec Škratek Svit Vodice, enota vrtca Zapoge', naslov: 'Zapoge 36', posta: '1217', kraj: 'Vodice' },
    { naziv: 'Zasebni vrtec Sonček, enota Marjetica', naslov: 'Medvedova ulica 3', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Zasebni vrtec Sonček, enota Zvonček', naslov: 'Vrhpolje pri Kamniku 116', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Osnovna šola Šmartno v Tuhinju', naslov: 'Šmartno v Tuhinju 27 A', posta: '1219', kraj: 'Laze v Tuhinju' },
    { naziv: 'Šolski center Slovenske Konjice - Zreče, SIC - izobraževanje odraslih in svetovanje', naslov: 'Tattenbachova ulica 2 A', posta: '3210', kraj: 'Slovenske Konjice' },
    { naziv: 'Vrtec Urša, Enota Pikapolonica', naslov: 'Ulica 7. avgusta 16', posta: '1233', kraj: 'Dob' },
    { naziv: 'OSNOVNA ŠOLA FRANJA GOLOBA PREVALJE VRTEC PREVALJE', naslov: 'Polje 4 A', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'GLOTTA NOVA center za novo znanje, d.o.o., Ljubljana, Višja strokovna šola za kozmetiko in velnes', naslov: 'Ulica Carla Benza 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Zavod Sv. Stanislava, Vrtec Dobrega pastirja', naslov: 'Štula 23', posta: '1210', kraj: 'Ljubljana - Šentvid' },
    { naziv: 'Ljudska univerza Ajdovščina', naslov: 'Stritarjeva ulica 1 A', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Ljudska univerza Slovenska Bistrica', naslov: 'Partizanska ulica 22', posta: '2310', kraj: 'Slovenska Bistrica' },
    { naziv: 'Osnovna šola Koroški jeklarji Ravne na Koroškem', naslov: 'Javornik 35', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Zasebni zavod Mali mehurčki Koper', naslov: 'Krpanova ulica 13', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'POŠ, Osnovna šola Franja Goloba Prevalje, Vrtec Leše', naslov: 'Polje 4', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'ACADEMIA družba za storitve d.o.o.', naslov: 'Glavni trg 17 B', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Lepa zgodba', naslov: 'Malenškova ulica 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Morje Lucija, Enota Vrtec Strunjan', naslov: 'Strunjan 37', posta: '6320', kraj: 'Portorož - Portorose' },
    { naziv: 'Center za poslovno usposabljanje, Višja strokovna šola Ljubljana, Enota Kranj', naslov: 'Cesta Staneta Žagarja 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Vrtec Medvode, enota Sora', naslov: 'Sora 1 B', posta: '1215', kraj: 'Medvode' },
    { naziv: 'Osnovna šola Stari trg ob Kolpi, Enota Vrtec, enota', naslov: 'Stari trg ob Kolpi 7', posta: '8342', kraj: 'Stari trg ob Kolpi' },
    { naziv: 'Glasbena šola Vinka Vodopivca Ajdovščina, enota Vipava', naslov: 'Vinarska cesta 4', posta: '5271', kraj: 'Vipava' },
    { naziv: 'Glasbena šola Črnomelj, podružnica Metlika', naslov: 'Mestni trg 24', posta: '8330', kraj: 'Metlika' },
    { naziv: 'Glasbena šola Črnomelj, podružnica Semič', naslov: 'Štefanov trg 9', posta: '8333', kraj: 'Semič' },
    { naziv: 'Vrtec Martin Krpan Cerknica, enota Rakek - šola', naslov: 'Partizanska cesta 28', posta: '1381', kraj: 'Rakek' },
    { naziv: 'Vrtec Delfino Blu Koper, enota Semedela', naslov: 'Semedela 28', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vrtec Postojna, Enota Zmajček', naslov: 'Gregorčičev drevored 8', posta: '6230', kraj: 'Postojna' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Adamičeva 20', naslov: 'Adamičeva cesta 20', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Zasebni vrtec Kobacaj, enota Brezje', naslov: 'Brezje pri Grosupljem 91', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'VVZ Kekec Grosuplje, enota Trobentica', naslov: 'Velika Račna 43', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Vzgojno - varstvena družina Pikapolonica', naslov: 'Levja ulica 10', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'Vzgojno izobraževalni zavod Vrtec Ormož', naslov: 'Dobravska ulica 13 A', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Waldorfska šola Maribor', naslov: 'Valvasorjeva ulica 75', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Osnovna šola Veliki Gaber, enota Vrtec, oddelek Sela pri Šumberku', naslov: 'Sela pri Šumberku 3', posta: '8360', kraj: 'Žužemberk' },
    { naziv: 'Zasebni zavod otroško veselje, Vrtec Copatki Maribor', naslov: 'Lackova cesta 39 A', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Zasebni vrtec Zarja d.o.o.', naslov: 'Perovo 28', posta: '1241', kraj: 'Kamnik' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, oddelek SŠ Črnomelj', naslov: 'Kidričeva ulica 1', posta: '8340', kraj: 'Črnomelj' },
    { naziv: 'Osnovna šola Leskovec pri Krškem, Enota vrtec', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Vrtec Tončke Čečeve, ENOTA HUDINJA', naslov: 'Ulica Frankolovskih žrtev 38', posta: '3000', kraj: 'Celje' },
    { naziv: 'Šolski center za pošto, ekonomijo in telekomunikacije Ljubljana', naslov: 'Celjska ulica 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Zvezdica', naslov: 'Za povrtmi 6', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Osnovna šola Šmartno, enota vrtca Ciciban, enota Lunica', naslov: 'Za povrtmi 6', posta: '1275', kraj: 'Šmartno pri Litiji' },
    { naziv: 'Vrtec Pedenjped Novo mesto, CEPETAVČEK', naslov: 'Ob potoku 12', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Vrtec Krško, enota Pravljica', naslov: 'Bohoričeva ulica 1', posta: '8270', kraj: 'Krško' },
    { naziv: 'RIS, Raziskovalni izobraževalno središče Dvorec Rakičan', naslov: 'Lendavska ulica 28', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'KARNIKA, zavod za osebno pomoč, svetovanje in razvoj znanja', naslov: 'Vrtna ulica 24', posta: '4294', kraj: 'Križe' },
    { naziv: 'Familija - izobraževalni in terapevtski center', naslov: 'Mestni trg 39', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'DART RAZVOJ - Zavod za razvoj, izobraževanje in produkcijo Lendava', naslov: 'Mlinska ulica 6 F', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'PAPILOT Zavod za vzpodbujanje in razvijanje kvalitete življenja, Ljubljana', naslov: 'Zasavska cesta 42', posta: '1231', kraj: 'Ljubljana - Črnuče' },
    { naziv: 'ŠOLT Zavod za izobraževanje', naslov: 'Svetčeva ulica 9', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MINT INTERNATIONAL HOUSE, jezikovni center, d.o.o.', naslov: 'Vilharjeva cesta 21', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'PRO-ZIR, poslovni sistemi d.o.o.', naslov: 'Rimska cesta 98 A', posta: '3311', kraj: 'Šempeter v Savinjski dolini' },
    { naziv: 'SAB, varovanje in storitve, d.o.o.', naslov: 'Gorkijeva ulica 14', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'NaPoK organiziranje in svetovanje d.o.o.', naslov: 'Lava 5', posta: '3000', kraj: 'Celje' },
    { naziv: 'VARNOST MARIBOR varovanje premoženja, trgovina, storitve in proizvodnja d.d.', naslov: 'Kraljeviča Marka ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'INTER-KULTURO mednarodni kulturno izobraževalni center d.o.o.', naslov: 'Fluksova ulica 3', posta: '2000', kraj: 'Maribor' },
    { naziv: 'JEZIKOVNA ŠOLA EUROCENTER, družba za usposabljanje, izobraževanje, trgovino in storitve d.o.o.', naslov: 'Kidričeva ulica 46', posta: '6000', kraj: 'Koper - Capodistria' },
    { naziv: 'MIŠKA, izobraževanje in organizacija d.o.o.', naslov: 'Letališka cesta 32', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Frana Kranjca', naslov: 'Hrašovčeva ulica 1', posta: '3000', kraj: 'Celje' },
    { naziv: 'Ljudska univerza Kočevje', naslov: 'Trg zbora odposlancev 30', posta: '1330', kraj: 'Kočevje' },
    { naziv: 'OŠ Leskovec pri Krškem, Enota vrtec - ENOTA PIKA NOGAVIČKA', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Osnovna šola Leskovec, enota Vrtec', naslov: 'Pionirska cesta 4 A', posta: '8273', kraj: 'Leskovec pri Krškem' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Pomurje, Enota Vrtec', naslov: 'Tomšičeva ulica 15', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Zasebni zavod glasbena šola Avsenik', naslov: 'Begunje na Gorenjskem 9', posta: '4275', kraj: 'Begunje na Gorenjskem' },
    { naziv: 'Osnovna šola Antona Ingoliča Spodnja Polskava, Podružnična šola Zgornja Polskava', naslov: 'Ingoličeva ulica 6', posta: '2314', kraj: 'Zgornja Polskava' },
    { naziv: 'Vrtec Mavrica Brežice', naslov: 'Šolska ulica 5', posta: '8250', kraj: 'Brežice' },
    { naziv: 'Glasbena šola Ormož', naslov: 'Grajski trg 1', posta: '2270', kraj: 'Ormož' },
    { naziv: 'Waldorfska šola Ljubljana, OE Waldorfski vrtec in šola Primorska', naslov: 'Cesta 5. maja 6', posta: '5270', kraj: 'Ajdovščina' },
    { naziv: 'Srednja trgovska šola Maribor', naslov: 'Mladinska ulica 14', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Mengeš, enota Gobica', naslov: 'Šolska ulica 12', posta: '1234', kraj: 'Mengeš' },
    { naziv: 'Inštitut montessori, Zasebni vrtec zelena hiša otrok Podutik', naslov: 'Ulica Ferda Kozaka 49', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'VIZ VRTEC MAVRICA IZOLA, ENOTA LIVADE II., oddelek', naslov: 'Livade 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Vrtec Otona Župančiča Črnomelj, oddelek Adlešiči', naslov: 'Adlešiči 1', posta: '8341', kraj: 'Adlešiči' },
    { naziv: 'Osnovna šola heroja Janeza Hribarja Stari trg pri Ložu, Podružnica Iga vas, enota Vrtec', naslov: 'Iga vas 1', posta: '1386', kraj: 'Stari trg pri Ložu' },
    { naziv: 'Vrtec Krško, enota Kekec', naslov: 'Delavska ulica 10', posta: '8270', kraj: 'Krško' },
    { naziv: 'Osnovna šola Selnica ob Dravi, enota Vrtec Gradišče na Kozjaku', naslov: 'Gradišče na Kozjaku 39', posta: '2361', kraj: 'Ožbalt' },
    { naziv: 'Inštitut montessori, PE Zasebni vrtec oranžna hiša Dravlje', naslov: 'Bravničarjeva ulica 8', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'MOJ MOJ, izobraževanje in usposabljanje, d.o.o.', naslov: 'Peruzzijeva ulica 103', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Matija Valjavec Preddvor, enota Jezersko', naslov: 'Zgornje Jezersko 62', posta: '4206', kraj: 'Zgornje Jezersko' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Romano', naslov: 'Vejar 121', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Waldorfska šola Ljubljana (zasebni vrtec), oddelek', naslov: 'Kranjska cesta 4', posta: '4240', kraj: 'Radovljica' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Bibarija', naslov: 'Loka pri Zidanem Mostu 72', posta: '1434', kraj: 'Loka pri Zidanem Mostu' },
    { naziv: 'Vrtec Jurček, Vzgoja in varstvo otrok, d.o.o., enota', naslov: 'Mala vas pri Grosupljem 1 B', posta: '1290', kraj: 'Grosuplje' },
    { naziv: 'Zavod Antona Martina Slomška Maribor, Osnovna šola Montessori', naslov: 'Vrbanska cesta 30', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Zasebni vrtec Kepica Mojca d.o.o.', naslov: 'Pot na Vovke 15', posta: '4207', kraj: 'Cerklje na Gorenjskem' },
    { naziv: 'Vrtec Krško, enota Sonček', naslov: 'Dolenja vas pri Krškem 53', posta: '8270', kraj: 'Krško' },
    { naziv: 'VVE pri OŠ Korena, enota', naslov: 'Zgornja Korena 32', posta: '2242', kraj: 'Zgornja Korena' },
    { naziv: 'Zavod za kreativno varstvo Ela - Ela, zasebni vrtec', naslov: 'Gasilska cesta 9', posta: '4208', kraj: 'Šenčur' },
    { naziv: 'Osnovna šola bratov Letonja Šmartno ob Paki, enota vrtec Sonček', naslov: 'Šmartno ob Paki 117', posta: '3327', kraj: 'Šmartno ob Paki' },
    { naziv: 'VRTEC KURIRČEK LOGATEC, ENOTA POD KOŠEM', naslov: 'Pavšičeva ulica 30', posta: '1370', kraj: 'Logatec' },
    { naziv: 'Višja strokovna šola za gostinstvo in turizem Bled, Medpodjetniški izobraževalni center', naslov: 'Prešernova cesta 32', posta: '4260', kraj: 'Bled' },
    { naziv: 'Osnovna šola Koroška Bela Jesenice Podružnica Blejska Dobrava', naslov: 'Blejska Dobrava 44', posta: '4273', kraj: 'Blejska Dobrava' },
    { naziv: 'EDUKA zavod za izobraževanje in svetovanje, Slovenj Gradec, PE Zasebni montessori vrtec, Hiša otrok', naslov: 'Podgorska cesta 2', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Zavod za razvoj upravne znanosti', naslov: 'Abramova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Ravne na Koroškem, oddelek na OŠ Prežihov Voranc', naslov: 'Gozdarska pot 11', posta: '2390', kraj: 'Ravne na Koroškem' },
    { naziv: 'Osnovna šola Šmarje - Sap', naslov: 'Ljubljanska cesta 49', posta: '1293', kraj: 'Šmarje - Sap' },
    { naziv: 'VVZ SLOVENJ GRADEC, enota', naslov: 'Maistrova ulica 2 A', posta: '2380', kraj: 'Slovenj Gradec' },
    { naziv: 'Osnovna šola Krmelj, enota Vrtec Storžek', naslov: 'Krmelj 104', posta: '8296', kraj: 'Krmelj' },
    { naziv: 'Osnovna šola Matije Valjavca Preddvor, Podružnica Kokra', naslov: 'Zgornja Bela 67', posta: '4205', kraj: 'Preddvor' },
    { naziv: 'Lesarska šola Maribor', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Tehniški šolski center Maribor', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Lesarska šola Maribor, Srednja šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Tehniški šolski center Maribor, Srednja strojna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Tehniški šolski center Maribor, Medpodjetniški izobraževalni center', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Lesarska šola Maribor, Višja strokovna šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Ciciban Sevnica, enota Studenec', naslov: 'Trg svobode 10', posta: '8290', kraj: 'Sevnica' },
    { naziv: 'VIZ Vrtci Brezovica, Enota pri OŠ Brezovica pri Ljubljani', naslov: 'Šolska ulica 15', posta: '1351', kraj: 'Brezovica pri Ljubljani' },
    { naziv: 'Osnovna šola Podčetrtek, Oddelki vrtca v Pristavi pri Mestinju', naslov: 'Pristava pri Mestinju 27', posta: '3253', kraj: 'Pristava pri Mestinju' },
    { naziv: 'Osnovna šola Podčetrtek, Oddelki vrtca v Podčetrtku', naslov: 'Trška cesta 67', posta: '3254', kraj: 'Podčetrtek' },
    { naziv: 'Zasebni družinski vrtec Ringa raja', naslov: 'Ragovo 2', posta: '8000', kraj: 'Novo mesto' },
    { naziv: 'Zavod zasebni vrtec Bosopet', naslov: 'Litijska cesta 319', posta: '1261', kraj: 'Ljubljana - Dobrunje' },
    { naziv: 'Srednja elektro-računalniška šola Maribor', naslov: 'Smetanova ulica 6', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Glasbena šola "Risto Savin" Žalec', naslov: 'Aškerčeva ulica 9', posta: '3310', kraj: 'Žalec' },
    { naziv: 'Glasbena šola Laško - Radeče', naslov: 'Šolska pot 5', posta: '1433', kraj: 'Radeče' },
    { naziv: 'Čarobni svet, zasebni vrtec d.o.o., PE Mikujčki', naslov: 'Ljubljanska cesta 1', posta: '4000', kraj: 'Kranj' },
    { naziv: 'AKTIVA VAROVANJE, varovanje premoženja, trgovina, storitve in proizvodnja d. d.', naslov: 'Kraljeviča Marka ulica 5', posta: '2000', kraj: 'Maribor' },
    { naziv: 'OŠ dr. Jožeta Pučnika Črešnjevec, Vrtec Črešnjevec - Leskovec, Enota Leskovec', naslov: 'Leskovec 67', posta: '2331', kraj: 'Pragersko' },
    { naziv: 'Vrtec pri OŠ Frana Metelka Škocjan, Bučka', naslov: 'Škocjan 51', posta: '8275', kraj: 'Škocjan' },
    { naziv: 'VIZ VRTEC MAVRICA IZOLA, ENOTA LIVADE, DISLOCIRANI ODDELEK LIVADE II.', naslov: 'Livade 7', posta: '6310', kraj: 'Izola - Isola' },
    { naziv: 'Zasebni vrtec Dobra teta d.o.o., enota Pri dobri Lučki', naslov: 'Ljubljanska cesta 24 D', posta: '4000', kraj: 'Kranj' },
    { naziv: 'Vrtec Trbovlje, Enota Barbara', naslov: 'Vreskovo 6 A', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Gimnazija in ekonomska srednja šola Trbovlje, Srednja ekonomska šola', naslov: 'Gimnazijska cesta 10', posta: '1420', kraj: 'Trbovlje' },
    { naziv: 'Osnovna šola Polhov Gradec, Enota Vrtec pri PŠ Črni Vrh', naslov: 'Polhov Gradec 95', posta: '1355', kraj: 'Polhov Gradec' },
    { naziv: 'Vrtec Postojna, Enota Pudgurček', naslov: 'Studeno 68', posta: '6230', kraj: 'Postojna' },
    { naziv: 'DOBA EPIS, d.o.o., Srednja šola', naslov: 'Prešernova ulica 1', posta: '2000', kraj: 'Maribor' },
    { naziv: 'B&B izobraževanje in usposabljanje d. o. o., Organizacijska enota Srednja strokovna in poklicna šola Kranj', naslov: '', posta: '', kraj: '' },
    { naziv: 'B&B izobraževanje in usposabljanje d. o. o., Organizacijska enota Srednja strokovna in poklicna šola Ljubljana', naslov: 'Letališka cesta 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'B&B izobraževanje in usposabljanje d. o. o., Organizacijska enota Srednja strokovna in poklicna šola Ljubljana', naslov: 'Letališka cesta 16', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Tehniški šolski center Maribor, Višja strokovna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Lesarska šola Maribor, Višja strokovna šola', naslov: 'Lesarska ulica 2', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Tehniški šolski center Maribor, Višja strokovna šola', naslov: 'Zolajeva ulica 12', posta: '2000', kraj: 'Maribor' },
    { naziv: 'Vrtec Galjevica, Enota Jurček', naslov: 'Galjevica 52', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Galjevica, Enota Jurček', naslov: 'Galjevica 52', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Osnovna šola Ivana Cankarja Ljutomer', naslov: 'Cankarjeva cesta 10', posta: '9240', kraj: 'Ljutomer' },
    { naziv: 'Vrtec Najdihojca pri OŠ dr. Jožeta Toporišiča Dobova, Enota Sonček, Kapele', naslov: 'Kapele 4 A', posta: '8258', kraj: 'Kapele' },
    { naziv: 'OSNOVNA ŠOLA FRANJA GOLOBA PREVALJE VRTEC PREVALJE, enota', naslov: 'Polje 4', posta: '2391', kraj: 'Prevalje' },
    { naziv: 'Zavod Mali svet, PE Mali svet', naslov: 'Pod topoli 47', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Waldorfski zasebni vrtec Lira', naslov: 'Stožice 20', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Vrtec Lendava Lendvai Ovoda', naslov: 'Župančičeva ulica 5', posta: '9220', kraj: 'Lendava - Lendva' },
    { naziv: 'Osnovna šola Mala Nedelja', naslov: 'Mala Nedelja 37', posta: '9243', kraj: 'Mala Nedelja' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Kekec', naslov: 'Kidričeva ulica 2', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Mojca', naslov: 'Goliev trg 4', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'Vrtec Mavrica Trebnje, enota Gubčeva', naslov: 'Gubčeva cesta 21', posta: '8210', kraj: 'Trebnje' },
    { naziv: 'MIT, Zavod za širšo družbeno sprejemljivost in sprejetost', naslov: 'Cesta talcev 22', posta: '4220', kraj: 'Škofja Loka' },
    { naziv: 'Zavod Center Nova, Zavod za razvoj, svetovanje in izobraževanje', naslov: 'Osojnikova cesta 3', posta: '2250', kraj: 'Ptuj' },
    { naziv: 'Zavod za izobraževanje in usposabljanje USPEŠEN.SI', naslov: 'Trg mladosti 6', posta: '3320', kraj: 'Velenje' },
    { naziv: 'Bartus, drugo izobraževanje in posredovanje, d.o.o.', naslov: 'Pod kostanji 4', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Ars Linguae jezikovno-kulturni center d.o.o.', naslov: 'Dunajska cesta 10', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Mednarodni center za prenos znanja d.o.o.', naslov: 'Kardeljeva ploščad 1', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Izobraževalni center Kadis 2002, zavod', naslov: 'Koprska ulica 72', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Mestna knjižnica Ljubljana', naslov: 'Kersnikova ulica 2', posta: '1000', kraj: 'Ljubljana' },
    { naziv: 'Papilot, Center za usposabljanje, rehabilitacijo in zaposlovanje, OE Murska Sobota', naslov: 'Plese 9', posta: '9000', kraj: 'Murska Sobota' },
    { naziv: 'Vrtec pri OŠ Grad', naslov: 'Grad 172e', posta: '9264', kraj: 'GRAD' },
    { naziv: 'Vrtec pri OŠ dr. Antona Trstenjaka', naslov: 'Negova 20', posta: '9245', kraj: 'Spodnji Ivanjci' },
    { naziv: 'UNIVERZA V MARIBORU, FILOZOFSKA FAKULTETA ', naslov: 'Koroška vrata 160', posta: '2000', kraj: 'Maribor' }
];

